import { createContext, useContext } from "react";
import UserStore from "./userStore";
import CommonStore from "./commonStore";
import DashboardStore from "./dashboardStore";
import ChargerStore from "./chargerStore";
import SessionStore from "./sessionStore";
import DemandShiftingStore from "./demandShiftingStore";
import ConnectorStore from "./connectorStore";
import SignalRStore from "./signalRStore";
import ConnectorTypeStore from "./connectorTypeStore";

interface Store {
  userStore: UserStore;
  commonStore: CommonStore;
  dashboardStore: DashboardStore;
  chargerStore: ChargerStore;
  sessionStore: SessionStore;
  demandShiftingStore: DemandShiftingStore,
  connectorStore: ConnectorStore,
  signalRStore: SignalRStore,
  connectorTypeStore: ConnectorTypeStore,
}

export const store: Store = {
  userStore: new UserStore(),
  commonStore: new CommonStore(),
  dashboardStore: new DashboardStore(),
  chargerStore: new ChargerStore(),
  sessionStore: new SessionStore(),
  demandShiftingStore: new DemandShiftingStore(),
  connectorStore: new ConnectorStore(),
  signalRStore: new SignalRStore(),
  connectorTypeStore: new ConnectorTypeStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
