import { makeAutoObservable, runInAction } from "mobx";
import { ConnectorType } from "../models/connectorType";
import agent from "../api/agent";


export default class ConnectorTypeStore {
    connectorTypes: ConnectorType[] | undefined

    constructor() {
        makeAutoObservable(this);
    }

    getConnectorTypesForDropdown = async () => {
        try {
            const result = await agent.ConnectorType.connectorTypesDropdown();
            runInAction(() => this.connectorTypes = result);
        } catch (err) {
            console.log("Error while trying to fetch connector types: ", err);
        }
    }
}