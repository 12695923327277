import axios, { AxiosResponse } from "axios";
import { store } from "../../app/stores/store";
import {
  ChangeAdminPassword,
  SetAdminPassword,
  UserLoginValues,
} from "../models/user";
import {
  ChargerByOnboardingStatusResponse,
  ChargerLogRequestDto,
  ChargerResponse,
  UpdateChargerDto,
} from "../models/charger";
import { AddOrUpdateDemandShiftingDto } from "../models/demandShifting";
import {
  ActiveSessionsResponse,
  SessionHistoryResponse,
} from "../models/session";
import { OnboardingConnector } from "../models/connector";
import { request } from "http";

const baseURL = process.env.REACT_APP_BASE_URL + "/api/v1/B2B";

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token && config.headers) config.headers.Authorization = `bearer ${token}`;
  return config;
});

const requests = {
  get: <T>(url: string) => axios.get<T>(`${baseURL}${url}`).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(`${baseURL}${url}`, body).then(responseBody),
  put: <T>(url: string, body: {}) =>
    axios.put<T>(`${baseURL}${url}`, body).then(responseBody),
  delete: <T>(url: string) =>
    axios.delete<T>(`${baseURL}${url}`).then(responseBody),
};

const Account = {
  login: (user: UserLoginValues) => requests.post("/Auth/LoginUser", user),
  forgotPassword: (body: { email: string }) =>
    requests.post("/Auth/ForgotPassword", body),
  setAdminPassword: (body: SetAdminPassword) =>
    requests.post("/Auth/SetAdminPassword", body),
  changeAdminPassword: (body: ChangeAdminPassword) =>
    requests.post("/Users/ChangeAdminPassword", body),
};

const Chart = {
  energyComparison: (from: string, to: string) =>
    requests.get<void>(
      `/Dashboards/GetDashboardComparison${from ? "?from=" + from : ""}${to ? "&to=" + to : ""
      }`
    ),
  energyConsumption: (from: string, to: string) =>
    requests.get<void>(
      `/Dashboards/GetDashboardEnergyConsumption${from ? "?from=" + from : ""}${to ? "&to=" + to : ""
      }`
    ),

  demandShiftingUsage: (from: string, to: string) =>
    requests.get<void>(
      `/Dashboards/GetDashboardDemandShiftingUsage${from ? "?from=" + from : ""
      }${to ? "&to=" + to : ""}`
    ),
};

const Chargers = {
  chargers: (
    pageIndex: number,
    pageSize: number,
    sortingField: string,
    descending: boolean
  ) => {
    const sortOrder = descending ? "true" : "false";

    let url = `/Chargers/GetChargers?PageIndex=${pageIndex}&PageSize=${pageSize}`;

    if (sortingField !== "") {
      url += `&SortingCriteria.Field=${sortingField}&SortingCriteria.Descending=${sortOrder}`;
    }

    return requests.get<ChargerResponse>(url);
  },
  mapChargers: () => requests.get("/Chargers/GetChargersMap"),
  createCharger: (payload: any) =>
    requests.post("/Chargers/CreateCharger", payload),

  updateCharger: (payload: UpdateChargerDto) =>
    requests.post("/Chargers/UpdateCharger", payload),

  chargersOnboardingStatusCount: () =>
    requests.get("/Chargers/GetChargersOnboardingStatusCount"),

  chargersByOnboardingStatus: (
    onboardingStatus: string,
    pageIdx: number,
    pageSize: number,
    sortingField: string,
    sortingDescending: boolean
  ) =>
    requests.get<ChargerByOnboardingStatusResponse>(
      `/Chargers/GetChargersByOnboardingStatus?OnboardingStatusEnum=${onboardingStatus}&PageIndex=${pageIdx}&PageSize=${pageSize}${sortingField
        ? `&SortingCriteria.Field=${sortingField}&SortingCriteria.Descending=${sortingDescending}`
        : ""
      }`
    ),

  finishOnboardingCharger: (chargerId: number) =>
    requests.post(
      `/Chargers/FinishOnboardingCharger?chargerId=${chargerId}`,
      {}
    ),

  getChargerLog: (requestData: ChargerLogRequestDto) =>
    requests.post("/Chargers/GetChargerLog", requestData),

  chargerInstructions: (ocppChargerId: string) =>
    requests.get(`/Chargers/GetChargerInstructions?ocppChargerId=${ocppChargerId}`),
};

const Admin = {
  userInfo: () => requests.get("/Users/GetUserInfo"),
  companyInfo: () => requests.get("/Companies/GetCompany"),
  updateCompanyInfo: (payload: any) =>
    requests.post("/Companies/UpdateCompany", payload),
};

const Common = {
  countriesDropdown: () => requests.get("/Countries/GetCountriesForDropdown"),
};

const ConnectorType = {
  connectorTypesDropdown: () =>
    requests.get("/ConnectorTypes/GetConnectorTypesForDropdown"),
};

const Session = {
  create: (connectorId: number, paymentMethodId: number, email: string) =>
    requests.post("/Sessions/CreateSession", {
      connectorId: connectorId,
      paymentMethodId: paymentMethodId,
      email: email,
    }),
  stop: (id: number) =>
    requests.post<void>(`/Sessions/StopSessionById?sessionId=${id}`, {}),

  getSession: () => requests.get("/Sessions/GetSession"),

  getSessionHistory: (
    pageIdx: number,
    pageSize: number,
    startDate: string,
    endDate: string,
    keyword: string,
    sortingField: string,
    sortingDescending: boolean
  ) =>
    requests.get<SessionHistoryResponse>(
      `/Sessions/GetHistorySessionsPaginated?PageIndex=${pageIdx}&PageSize=${pageSize}${startDate ? "&DateStartFrom=" + startDate : ""
      }${endDate ? "&DateStartTo=" + endDate : ""}${keyword ? "&Keyword=" + keyword : ""
      }${sortingField
        ? `&SortingCriteria.Field=${sortingField}&SortingCriteria.Descending=${sortingDescending}`
        : ""
      }`
    ),

  getActiveSessions: (
    pageIdx: number,
    pageSize: number,
    startDate: string,
    endDate: string,
    keyword: string,
    sortingField: string,
    sortingDescending: boolean
  ) =>
    requests.get<ActiveSessionsResponse>(
      `/Sessions/GetActiveSessionsPaginated?PageIndex=${pageIdx}&PageSize=${pageSize}${keyword ? "&Keyword=" + keyword : ""
      }${startDate ? "&DateStartFrom=" + startDate : ""}${endDate ? "&DateStartTo=" + endDate : ""
      }${sortingField
        ? `&SortingCriteria.Field=${sortingField}&SortingCriteria.Descending=${sortingDescending}`
        : ""
      }`
    ),

  getMeterValues: (sessionId: number) =>
    requests.get(`/Sessions/GetMeterValues?sesisonId=${sessionId}`)
};

const DemandShifting = {
  changeState: (sessionId: number, isEnabled: boolean) =>
    requests.post("/DemandShifting/ChangeStateOfDemandShifting", {
      sessionId: sessionId,
      isEnabled: isEnabled,
    }),

  getDemandShiftingHistory: (startDate: string, endDate: string) =>
    requests.post("/DemandShifting/GetDemandShiftingHistory", {
      startDate: startDate,
      endDate: endDate,
    }),

  addOrUpdateDemandShiftingSchedule: (
    updateData: AddOrUpdateDemandShiftingDto[]
  ) =>
    requests.post(
      "/DemandShifting/AddOrUpdateDemandShiftingSchedule",
      updateData
    ),
};

const Connector = {
  getConnectorsByChargerId: (chargerId: number) =>
    requests.get(`/Connectors/GetConnectorsByChargerId/${chargerId}`),

  getOnboardingConnectorsByChargerId: (chargerId: number) =>
    requests.get(
      `/Connectors/GetOnboardingConnectorsByChargerId?id=${chargerId}`
    ),

  updateConnectorOnboarding: (connector: OnboardingConnector) =>
    requests.post("/Connectors/UpdateConnectorOnboarding", connector),
};

const agent = {
  Account,
  Chart,
  Chargers,
  Admin,
  Session,
  DemandShifting,
  Connector,
  Common,
  ConnectorType,
};

export default agent;
