import { makeAutoObservable, reaction, runInAction } from "mobx";
import { getJwtFromStorage } from "../../utils/jwtStorage/jwtStorage";
import { ICountry } from "../models/user";
import agent from "../api/agent";

export default class CommonStore {
  token: string | null = getJwtFromStorage();
  countriesForDropdown: ICountry[] | undefined;
  appLoaded = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.token,
      (token) => {
        if (token) {
          sessionStorage.setItem("jwt", token);
        } else {
          sessionStorage.removeItem("jwt");
        }
      }
    );
  }

  getCountriesForDropdown = async () => {
    try {
      const res = await agent.Common.countriesDropdown();
      runInAction(() => (this.countriesForDropdown = res));
    } catch (error) {
      console.log("Error while fetching countries for dropdown: ", error);
    }
  };

  setToken = (token: string | null) => {
    this.token = token;
  };

  setRememberMeJwt = (token: string | null) => {
    localStorage.setItem(
      "jwt",
      JSON.stringify({ id: token, expiryDate: new Date().getTime() })
    );
  };

  setToDefault = () => {
    this.setToken(null);
    sessionStorage.removeItem("jwt");
    localStorage.removeItem("jwt");
  };

  setAppLoaded = () => {
    this.appLoaded = true;
  };

  isLoggedIn = () => {
    return !!this.token;
  };
}
