import React from "react";

interface Props {
  group: string;
  subgroup: string;
  children?: React.ReactNode;
}

const ContentHeader: React.FC<Props> = ({ children, group, subgroup }) => {
  return (
    <div className="bg-white rounded-[10px] p-[18px] flex flex-row justify-between items-center header flex-wrap">
      <div>
        <span className="text-[24px] font-semibold text-darkGreen">
          {group}/
        </span>
        <span className="text-[24px] font-semibold text-light-green">
          {subgroup}
        </span>
      </div>
      {children}
    </div>
  );
};

export default ContentHeader;
