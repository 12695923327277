import { useState } from "react";

interface Props {
  value: string;
  data: any[];
  readonly?: boolean;
  placeholder?: string;
  handleSelect: (value: any) => void;
}

const MySelect = ({ value, data, handleSelect, readonly = false, placeholder = "" }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      onClick={() => !readonly && setOpen((prev) => !prev)}
      className={`relative flex rounded-[10px] bg-white text-[14px] justify-between items-center border-[2px] border-solid font-medium focus:outline-none py-[14px] px-[10px] 
        ${open ? "border-light-green" : "border-[#E6E6E6]"} ${!value && "text-[#ADADAD]"}`}
    >
      {value || placeholder}
      {!readonly && (
        <img
          src={
            open
              ? "/assets/svg/arrow/arrowConnectorUp.svg"
              : "/assets/svg/arrow/arrowConnectorDown.svg"
          }
          alt=""
        />
      )}
      {open && data.length > 0 && (
        <ul
          style={{
            boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.05)",
          }}
          className="absolute w-[215px] z-10 overflow-auto right-[-2px] bg-white shadow rounded-10 p-3 top-[54px]"
        >
          {data.map((option) => (
            <li
              className="py-3 px-4 rounded-10 cursor-pointer hover:bg-[#E6F6F4] text-[12px] text-darkGreen font-medium"
              key={option.value}
              onClick={() => {
                handleSelect(option);
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MySelect;
