import { ChangeEventHandler } from "react";

interface Props {
  placeholder: string;
  name: string;
  value?: any;
  readonly?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  regex?: string
}

const TextInput = ({
  placeholder,
  value,
  name,
  onChange,
  readonly = false,
  regex = "",
}: Props) => {
  return (
    <input
      className={`rounded-[10px] bg-white text-[14px] font-medium focus:outline-none border-[2px] border-solid border-[#E6E6E6]  py-[14px] px-[10px] ${!readonly
          ? "focus:border-light-green bg-white"
          : "bg-[#f0f0f0] text-[#515151]"
        }`}
      placeholder={placeholder}
      name={name}
      value={value}
      pattern={regex}
      readOnly={readonly}
      onChange={onChange}
      type="text"
    />
  );
};

export default TextInput;
