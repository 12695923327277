import { useState } from "react";
import { useStore } from "../../../app/stores/store";
import TextInput from "../../../components/TextInput/TextInput";
import ChangePassword from "./ChangePassword";

const ProfileInfo = () => {
  const { userStore } = useStore();
  const { user } = userStore;
  const [changePasswordMode, setChangePasswordMode] = useState(false);


  return (
    <div className="flex flex-col justify-between pt-[45px] pl-[52px] pb-[50px] pr-[42px] h-[100%] ">
      <div className="w-[100%] flex justify-between items-center">
        <div className="text-[20px] font-semibold text-darkGreen">
          Account Information
        </div>
      </div>

      <div>
        <div className="flex flex-col w-[100%] gap-5">
          <div className="flex gap-[66px]">
            <div className="flex flex-col gap-2 flex-1">
              <label className="text-[14px] font-bold text-darkGreen">
                Name Surname
              </label>
              <TextInput
                placeholder="Name Surname"
                name="fullName"
                value={user?.firstName + " " + user?.lastName}
                readonly={true}
              />
            </div>
            <div className="flex flex-col gap-2 flex-1">
              <label className="text-[14px] font-bold text-darkGreen">
                E-mail
              </label>
              <TextInput
                placeholder="Email"
                name="email"
                value={user?.email}
                readonly={true}
              />
            </div>
          </div>
          <div className="flex gap-[66px] items-end ">
            <div className="flex flex-col gap-2 flex-1">
              <label className="text-[14px] font-bold text-darkGreen">
                Password
              </label>
              <input
                className="rounded-[10px] text-lightGray bg-[#F7F7F7] text-[14px] font-medium focus:outline-none focus:border-light-green border-[2px] border-solid border-[input-border py-[14px] px-[10px]"
                placeholder="Password"
                disabled
                name="password"
                value={"•".repeat(10)}
                type="password"
              />
            </div>
            <div className="flex flex-col gap-2 flex-1">
              <button
                className="rounded-[10px] text-[14px] text-lightGray font-medium hover:text-[#FDFDFD] hover:bg-darkGreen border-[2px] border-solid border-input-border bg-white py-[14px] px-[10px]"
                onClick={() => setChangePasswordMode(true)}
              >
                Change Password
              </button>
            </div>
          </div>
        </div>
      </div>
      <ChangePassword
        modalOpen={changePasswordMode}
        setModalOpen={setChangePasswordMode}
      />
    </div>
  );
};

export default ProfileInfo;
