import * as signalR from '@microsoft/signalr';
import { makeAutoObservable } from "mobx";
import { store } from "./store";

const BASE_URL = process.env.REACT_APP_BASE_URL_CLEAR;


export default class SignalRStore {
    connection: signalR.HubConnection | undefined;

    constructor() {
        makeAutoObservable(this);
    };

    startConnection = async (url: string) => {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(BASE_URL + url,
                {
                    withCredentials: false,
                    transport: signalR.HttpTransportType.WebSockets,
                    accessTokenFactory: () => store.commonStore.token || ""
                }
            )
            .configureLogging(signalR.LogLevel.Information)
            .build();
        await this.connection.start().catch((err) => console.error('Error while establishing connection', err));
    };

    closeConnection = async () => {
        if (this.connection) {
            try {
                await this.connection.stop();
                console.log('Connection successfully closed.');
                this.connection = undefined;
            } catch (err) {
                console.error('Error while closing the connection', err);
            }
        }
    };


    // #REGION remote actions on charger (reset, emergency reset, change availability, unlock) and stop session

    subscribeOnRemoteAction = async (actionName: string,
        actionParameters: any,
        handleSuccessfulSubscription: () => void,
        handleFailedSubscription: (err: any) => void
    ) => {
        await this.connection?.invoke(actionName, actionParameters)
            .then(handleSuccessfulSubscription)
            .catch((err: any) => handleFailedSubscription(err));
    };

    listenRemoteAction = async (actionName: string,
        handleAction: (res: any) => void
    ) => {
        this.connection?.on(actionName, handleAction);
    };

    // #END REGION


    //Subscribing to SubscribeActiveSessions
    subscribeOnAddingAndRemovingSessions = async (actionName: string,
        handleFailedSubscription: (err: any) => void
    ) => {
        await this.connection?.invoke(actionName)
            .catch((err: any) => handleFailedSubscription(err));
    };


}