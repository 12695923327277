import React, { useEffect } from "react";
import { useStore } from "../../../../../app/stores/store";
import { observer } from "mobx-react-lite";

interface Props {
  activeButton: string;
  handleButtonClick: (value: string) => void;
}

const ButtonGroup = observer(({ activeButton, handleButtonClick }: Props) => {
  const { chargerStore } = useStore();
  const { chargersOnboardingStatusCount } = chargerStore;

  useEffect(() => {
    chargerStore.getChargersOnboardingStatusCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargerStore]);

  return (
    <div className="flex border-b-[3px] border-[#BBBBBB]">
      <button
        className={`flex gap-2.5 items-center relative w-[190px] text-left text-[14px] font-semibold whitespace-nowrap py-2 px-2 ${
          activeButton === "pending"
            ? "text-darkGreen border-b-[3px] border-light-green -mb-[3px]"
            : "text-[#B8B8B8]"
        }`}
        onClick={() => handleButtonClick("pending")}
      >
        Pending connection
        <div
          className={`relative w-[23px] h-[23px] rounded-full ${
            activeButton === "pending"
              ? "bg-light-green text-[#F5FBFA]"
              : "bg-[#B8B8B8] text-[#FDFDFD]"
          }`}
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[13px] font-semibold">
            {chargersOnboardingStatusCount?.pendingCount}
          </div>
        </div>
      </button>
      <button
        className={`flex gap-2.5 items-center relative w-[190px] text-left text-[14px] font-semibold whitespace-nowrap py-2 px-2 ${
          activeButton === "connected"
            ? "text-darkGreen border-b-[3px] border-light-green -mb-[3px]"
            : "text-[#B8B8B8]"
        }`}
        onClick={() => handleButtonClick("connected")}
      >
        Finish onboarding
        <div
          className={`relative w-[23px] h-[23px] rounded-full ${
            activeButton === "connected"
              ? "bg-light-green text-[#F5FBFA]"
              : "bg-[#B8B8B8] text-[#FDFDFD]"
          }`}
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[13px] font-semibold">
            {chargersOnboardingStatusCount?.connectedCount}
          </div>
        </div>
      </button>
    </div>
  );
});

export default ButtonGroup;
