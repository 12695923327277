import { useEffect, useRef, useState } from "react";
import { fetchCoordinates, MAPBOX_ACCESS_TOKEN } from "../../utils/map/map";
import { fetchAddressSuggestions } from "../../utils/address/address";

interface Props {
  value: string;
  setValue: (value: string) => void;
  setCoordinates?: (coordinates: { lat: number; lon: number } | null) => void;
  filledInputValue?: string;
  placeholder?: string;
}

interface SelectOption {
  value: string;
  label: string;
}

const AddressInput = ({
  value,
  setValue,
  filledInputValue,
  setCoordinates,
  placeholder,
}: Props) => {
  const [addressOptions, setAddressOptions] = useState<SelectOption[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleSelectAddressChange = async (selectedOption: SelectOption) => {
    if (selectedOption) {
      setValue(selectedOption.label);

      if (setCoordinates) {
        const coordinates = await fetchCoordinates(selectedOption.label);
        setCoordinates(coordinates);
      }
    }
    setAddressOptions([]);
    setIsFocused(false);
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      const options = await fetchAddressSuggestions(value, MAPBOX_ACCESS_TOKEN);

      if (options.some((option: any) => option.label === value)) {
        setAddressOptions([]);
      } else {
        setAddressOptions(options);
      }
    };

    fetchSuggestions();
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsFocused(false);
      }
    };

    if (isFocused) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFocused]);

  return (
    <>
      <div
        ref={containerRef}
        className={`flex justify-between items-center gap-4 py-[6px] px-4 rounded-10 border-[2px] border-solid ${isFocused ? "border-light-green relative" : "border-[#E6E6E6]"
          }`}
      >
        <input
          className="w-full text-[14px] py-2 font-medium focus:outline-none"
          type="text"
          id="companyAddress"
          placeholder={placeholder || "Company Address"}
          autoComplete="off"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onFocus={() => setIsFocused(true)}
          ref={inputRef}
        />
        <img
          className="w-3 h-3 cursor-pointer"
          src={value.length > 0 ? "/assets/svg/cross/cross.svg" : ""}
          alt=""
          onClick={() => {
            setValue("");
            setAddressOptions([]);
          }}
        />
        {isFocused && addressOptions.length > 0 && (
          <ul
            style={{
              width: containerRef.current
                ? `${containerRef.current.clientWidth}px`
                : "330px",
              top: "52px",
            }}
            className="absolute z-10 overflow-auto right-[-2px] bg-white rounded-10 p-3 shadow-md"
          >
            {addressOptions.map((option) => (
              <li
                className="py-3 px-4 rounded-10 cursor-pointer hover:bg-[#E6F6F4] text-[12px] text-darkGreen font-medium"
                key={option.value}
                onMouseDown={() => {
                  console.log("Option clicked:", option);
                  handleSelectAddressChange(option);
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default AddressInput;
