interface Props {
  isActive: boolean;
  content: string;
  textSize: string;
  weight: string;
  unactiveBg?: string;
}

const ConfirmButton = ({
  content,
  isActive,
  weight,
  textSize,
  unactiveBg,
}: Props) => {
  return (
    <button
      className={`flex w-full h-full whitespace-nowrap px-[10px] py-[15px] justify-center text-[${textSize}px] font-${weight} items-center gap-[10px] rounded-[10px] ${
        isActive
          ? "bg-light-green text-white"
          : "bg-[var(--Foundation-White-Normal)] text-[#515151]"
      }`}
      style={{
        background: isActive
          ? undefined
          : unactiveBg
          ? `[${unactiveBg}]`
          : "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #E6E6E6",
      }}
    >
      {content}
    </button>
  );
};

export default ConfirmButton;
