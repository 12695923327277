import Chart, { CategoryScale, ChartEvent } from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";
import "chartjs-plugin-dragdata"; // Import the plugin

interface Props {
  xValuesFiltered?: string[];
  xValues: string[];
  yValues: number[];
  onEdit: (xValue: string, yValue: number) => void;
}

const EnergyPercentageChart = ({
  xValuesFiltered,
  xValues,
  yValues: initialYValues,
  onEdit,
}: Props) => {
  const [yValues, setYValues] = useState(initialYValues);
  const [hasEdited, setHasEdited] = useState(false); // Track if editing has happened

  useEffect(() => {
    // Only reset yValues to initialYValues if there hasn't been an edit
    if (!hasEdited) {
      setYValues(initialYValues);
    }
  }, [initialYValues, hasEdited]);

  Chart.register(CategoryScale);
  Chart.defaults.color = "#ADADAD";
  Chart.defaults.layout.padding = 0;

  const backgroundColors = xValues.map((xValue) => {
    const dateTime = new Date(xValue + "Z");
    const now = new Date();

    if (dateTime.getTime() <= now.getTime()) {
      return "rgba(184, 184, 184, 1)";
    } else if (
      dateTime.getTime() > now.getTime() &&
      dateTime.getTime() < now.getTime() + 24 * 60 * 60 * 1000
    ) {
      return "rgba(242, 138, 5, 1)";
    } else {
      return "rgba(0, 169, 145, 1)";
    }
  });

  const transformYValue = (value: number) => {
    if (value <= 2) return 0; // Map 1% to 0
    if (value >= 101) return 100; // Cap at 101% to 100
    return value - 1; // Shift everything by 1
  };

  const transformedYValues = initialYValues.map(transformYValue);

  const data = {
    labels: xValuesFiltered,
    datasets: [
      {
        data: yValues,
        backgroundColor: backgroundColors,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    dragData: true,
    scales: {
      x: {
        dragData: false,
        grid: {
          display: false,
        },
      },
      y: {
        max: 100,
        min: 0,
        grid: {
          display: true,
          color: "#E6E6E6",
          lineWidth: 1,
        },
        ticks: {
          stepSize: 20,
          callback: function (tickValue: string | number) {
            const value =
              typeof tickValue === "number" ? tickValue : parseFloat(tickValue);

            return `${value}%`;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        onHover: function (e: any) {
          e.target.style.cursor = "pointer";
        },
      },
      tooltip: {
        displayColors: false,
        padding: {
          top: 6,
          bottom: 6,
          left: 8,
          right: 8,
        },
        callbacks: {
          title: () => "",
          label: function (tooltipItem: any) {
            const rawValue = tooltipItem.raw;

            if (rawValue === 0 || rawValue === 1 || rawValue === 2) {
              return `0%`;
            }
            return `${rawValue}%`;
          },
        },
        backgroundColor: function (context: any) {
          if (context && context.tooltipItems && context.tooltipItems[0]) {
            const con = context.tooltipItems[0];
            const index = con.dataIndex;
            const backgroundColor = con.dataset.backgroundColor[index];

            return backgroundColor === "rgba(0, 169, 145, 1)"
              ? "#003B33"
              : "#00A991";
          }

          // Fallback color
          return "#00A991";
        },
      },
      dragData: {
        magnet: {
          to: (value: number | [number, number] | null): number => {
            if (value === null || value === 0 || value === 1) return 2;
            return Math.max(Math.round(value as number), 2);
          },
        },
        round: 0,
        showTooltip: true,
        dragX: false,
        dragY: true,
        onDragStart: function (
          e: any,
          datasetIndex: number,
          index: number,
          value: number | [number, number] | null
        ) {
          const isDraggable =
            backgroundColors[index] === "rgba(0, 169, 145, 1)" ||
            backgroundColors[index] === "rgba(242, 138, 5, 1)";
          return isDraggable;
        },
        onDrag: function (
          e: any,
          datasetIndex: number,
          index: number,
          value: number | [number, number] | null
        ) {
          if (typeof value === "number") {
            const newValue = Math.max(Math.round(value), 100);
            data.datasets[datasetIndex].data[index] = newValue;
          }
        },
        onDragEnd: function (
          e: any,
          datasetIndex: number,
          index: number,
          value: number | [number, number] | null
        ) {
          if (typeof value === "number") {
            setYValues((prevYValues) => {
              const updatedYValues = [...prevYValues];
              updatedYValues[index] = value;
              return updatedYValues;
            });
            onEdit(xValues[index], value <= 2 ? 0 : value);
          }
        },
      },
    },
    onHover: (event: any, activeElements: any) => {
      const cursorStyle =
        activeElements.length > 0 &&
          (backgroundColors[activeElements[0].index] === "rgba(0, 169, 145, 1)" ||
            backgroundColors[activeElements[0].index] === "rgba(242, 138, 5, 1)")
          ? "grab"
          : "pointer";
      (event?.native?.target as HTMLElement).style.cursor = cursorStyle;
    },
    onmouseenter: (e: any, event: ChartEvent) => {
      console.log("Mouse: ", event);
    },
    onMouseDown: (e: any, event: ChartEvent) => {
      console.log("Mouse: ", event);
      if (event.type === "mousedown") {
        console.log("nuce");
        const activeElements = (
          event.native?.target as any
        ).chart.getElementsAtEvent(event);
        const isGreenBar =
          activeElements.length > 0 &&
          backgroundColors[activeElements[0].index] === "rgba(0, 169, 145, 1)";
        if (e.native) {
          (e.native.target as HTMLElement).style.cursor = isGreenBar
            ? "grabbing"
            : "pointer";
        }
      }
    },
  };

  return (
    <div className="w-full">
      <Bar data={data} options={options} className="h-[210px] w-full" />
    </div>
  );
};

export default EnergyPercentageChart;
