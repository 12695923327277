import { RouteObject, createBrowserRouter } from "react-router-dom";
import Login from "../../pages/Login/Login";
import DashboardLayout from "../layout/DashboardLayout";
import ForgotPassword from "../../pages/Login/ForgotPassword/ForgotPassword";
import SetPassword from "../../pages/Login/SetPassword/SetPassword";
import DashboardOverview from "../../pages/Dashboard/Overview/DashboardOverview";
import SessionActive from "../../pages/Dashboard/Sessions/SessionActive";
import SessionHistory from "../../pages/Dashboard/Sessions/SessionHistory";
import NetworkOnboarding from "../../pages/Dashboard/Network/NetworkOverview/Onboarding/NetworkOnboarding";
import NetworkOverview from "../../pages/Dashboard/Network/NetworkOverview/NetworkOverview";
import DemandShifting from "../../pages/Dashboard/Operations/DemandShifting/DemandShifting";
import OperationsRemote from "../../pages/Dashboard/Operations/Remote/OperationsRemote";
import Profile from "../../pages/Dashboard/Profile/Profile";
import FinishOnboarding from "../../pages/Dashboard/Network/NetworkOverview/Onboarding/FinishOnboarding";

export const routes: RouteObject[] = [
  { path: "auth/login", element: <Login /> },
  { path: "auth/forgotPassword", element: <ForgotPassword /> },
  { path: "auth/setPassword", element: <SetPassword /> },

  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      { path: "dashboard/overview", element: <DashboardOverview /> },
      { path: "dashboard/sessions/active", element: <SessionActive /> },
      { path: "dashboard/sessions/history", element: <SessionHistory /> },
      { path: "dashboard/network/onboarding", element: <NetworkOnboarding /> },
      { path: "dashboard/network/onboarding/finishOnboarding/:chargerId", element: <FinishOnboarding /> },
      { path: "dashboard/network/overview", element: <NetworkOverview /> },
      { path: "dashboard/operations/shifting", element: <DemandShifting /> },
      { path: "dashboard/operations/remote", element: <OperationsRemote /> },
      { path: "dashboard/profile", element: <Profile /> },
    ],
  },
];

export const router = createBrowserRouter(routes);
