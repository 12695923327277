import { useEffect, useRef, useState } from "react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";

interface Props {
  value: string;
  handleSelect: (id: number, value: string) => void;
}
const CountrySelect = observer(({ value, handleSelect }: Props) => {
  const { commonStore } = useStore();
  const { countriesForDropdown } = commonStore;
  const inputRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleSelectCountry = async (id: number, name: string) => {
    if (id) {
      handleSelect(id, name);
    }
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <>
      <div
        onClick={() => setOpen((prev) => !prev)}
        ref={containerRef}
        className={`w-full flex justify-between items-center gap-4 py-[12px] px-4 rounded-10 border-[2px] border-solid cursor-pointer ${
          open ? "border-light-green relative" : "border-[#E6E6E6]"
        }`}
      >
        {value}
        <img
          src={
            open
              ? "/assets/svg/arrow/arrowUp.svg"
              : "/assets/svg/arrow/arrowDown.svg"
          }
          alt="country"
        />
        {open && countriesForDropdown!.length > 0 && (
          <ul
            style={{
              width: containerRef.current
                ? `${containerRef.current.clientWidth}px`
                : "330px",
              top: "52px",
            }}
            className="absolute z-10 overflow-auto right-[-2px] bg-white rounded-10 p-3 shadow-md"
          >
            {countriesForDropdown &&
              countriesForDropdown.map((option) => (
                <li
                  className="py-3 px-4 rounded-10 cursor-pointer hover:bg-[#E6F6F4] text-[12px] text-darkGreen font-medium"
                  key={option.id}
                  onMouseDown={() =>
                    handleSelectCountry(option.id, option.internationalName)
                  }
                >
                  {option.internationalName}
                </li>
              ))}
          </ul>
        )}
      </div>
    </>
  );
});

export default CountrySelect;
