// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 10px;
  position: relative;
}
.leaflet-top.leaflet-right .leaflet-control-zoom {
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
`, "",{"version":3,"sources":["webpack://./src/components/Map/Map.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;EACV,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,6BAA6B;EAC7B,aAAa;EACb,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,kBAAkB;EAClB,wCAAwC;AAC1C","sourcesContent":[".leaflet-container {\n  width: 100%;\n  height: 100%;\n  z-index: 0;\n  border-radius: 10px;\n  position: relative;\n}\n.leaflet-top.leaflet-right .leaflet-control-zoom {\n  background-color: transparent;\n  outline: none;\n  border: none;\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n  border-radius: 5px;\n  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
