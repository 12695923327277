// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/assets/images/checkbox.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Login.css */
input[type="checkbox"]::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Replace with your checkmark image */
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  
  input[type="checkbox"]:checked::after {
    opacity: 1; /* Show the checkmark image when checked */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,WAAW;IACX,cAAc;IACd,WAAW;IACX,YAAY;IACZ,yDAAmE,EAAE,sCAAsC;IAC3G,wBAAwB;IACxB,2BAA2B;IAC3B,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,UAAU;IACV,oCAAoC;EACtC;;EAEA;IACE,UAAU,EAAE,0CAA0C;EACxD","sourcesContent":["/* Login.css */\ninput[type=\"checkbox\"]::after {\n    content: '';\n    display: block;\n    width: 100%;\n    height: 100%;\n    background-image: url('../../../public/assets/images/checkbox.png'); /* Replace with your checkmark image */\n    background-size: contain;\n    background-position: center;\n    position: absolute;\n    top: 0;\n    left: 0;\n    opacity: 0;\n    transition: opacity 0.2s ease-in-out;\n  }\n  \n  input[type=\"checkbox\"]:checked::after {\n    opacity: 1; /* Show the checkmark image when checked */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
