import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../stores/store";
import { observer } from "mobx-react-lite";

const Header = observer(() => {
  const { userStore } = useStore();
  const { user, getAdminInfo } = userStore;
  const navigate = useNavigate();

  return (
    <header className=" h-[80px] bg-white shadow-[0px_1px_15px_0px_rgba(0,0,0,0.05)] flex-shrink-0">
      <div className="flex flex-row justify-between items-center h-full pl-[3rem] pr-[3rem]">
        <div />
        <div
          onClick={() => {
            navigate("/dashboard/profile");
          }}
          className="flex flex-row gap-5 items-center cursor-pointer"
        >
          <div className="text-left text-[18px] font-medium relative text-darkGreen">
            {user?.firstName} {user?.lastName}
          </div>
          <img src="/assets/images/avatar.png" alt="" className="w-[44px]" />
        </div>
      </div>
    </header>
  );
});

export default Header;
