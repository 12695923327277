import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { DemandShiftingHistory, AddOrUpdateDemandShiftingDto } from "../models/demandShifting";

export default class DemandShiftingStore {
    demandShiftingHistory: DemandShiftingHistory[] | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    getDemandShiftingHistory = async (
        startDate: string = "",
        endDate: string = ""
    ) => {
        try {
            const result = await agent.DemandShifting.getDemandShiftingHistory(
                startDate,
                endDate
            );
            runInAction(() => {
                this.demandShiftingHistory = result;
            });
        } catch (error) {
            console.log("Failed to fetch demand shifting history data: ", error);
        }
    };

    addOrUpdateDemandShiftingSchedule = async (updateData: AddOrUpdateDemandShiftingDto[]) => {
        try {
            await agent.DemandShifting.addOrUpdateDemandShiftingSchedule(updateData);
        } catch (error) {
            console.log("Error while adding or updating demand shifting schedule: ", error);
        }
    };

    changeDemandShiftingState = async (sessionId: number, isEnabled: boolean) => {
        try {
            await agent.DemandShifting.changeState(sessionId, isEnabled);
        } catch (error) {
            console.log("Error while changing demand shifting state: ", error);
        }
    }


}