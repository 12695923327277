import L from "leaflet";
import { useEffect, useRef, useState } from "react";
import { useMap } from "react-leaflet";
import { CustomMarker } from "../../../../../utils/map/customMarker";
import { NewMarkerIcon } from "../../../../../utils/map/map";
import { ChargerDataDto } from "../../../../../app/models/charger";

interface Props {
  newLocation: ChargerDataDto | null;
  setNewLocation?: (location: any | null) => void;
}

const TempMarker = ({ newLocation, setNewLocation }: Props) => {
  const map = useMap();
  const draggableMarkerRef = useRef<L.Marker | null>(null);
  const [zoomLevel, setZoomLevel] = useState(map.getZoom());
  const [prevLocation, setPrevLocation] = useState<ChargerDataDto | null>(null);

  useEffect(() => {
    const handleZoom = () => {
      setZoomLevel(map.getZoom());
    };

    map.on("zoom", handleZoom);

    return () => {
      map.off("zoom", handleZoom);
    };
  }, [map]);

  useEffect(() => {
    map.invalidateSize(false);

    if (newLocation?.longitude_latitude === "") {
      if (draggableMarkerRef.current) {
        map.removeLayer(draggableMarkerRef.current);
        draggableMarkerRef.current = null;
      }
      map.setView([47.498, 19.0881], 10);
      return;
    }

    if (newLocation && newLocation.longitude_latitude) {
      const [longitude, latitude] = newLocation.longitude_latitude
        .split(",")
        .map((coord) => parseFloat(coord.trim()));

      if (!isNaN(latitude) && !isNaN(longitude)) {
        if (
          prevLocation?.longitude_latitude !== newLocation.longitude_latitude ||
          prevLocation?.street !== newLocation.street
        ) {
          if (draggableMarkerRef.current) {
            map.removeLayer(draggableMarkerRef.current);
            draggableMarkerRef.current = null;
          }

          const tempMarker = new CustomMarker(
            new L.LatLng(latitude, longitude),
            {
              icon: NewMarkerIcon(0, zoomLevel),
              numberOfConnectors: 0,
              draggable: true,
            }
          );

          draggableMarkerRef.current = tempMarker;
          map.addLayer(tempMarker);

          map.setView([latitude, longitude], 18);
          setPrevLocation(newLocation);

          tempMarker.on("dragend", function () {
            const newLatLng = tempMarker.getLatLng();
            const updatedLocation = {
              ...newLocation,
              longitude_latitude: `${newLatLng.lng.toFixed(
                5
              )},${newLatLng.lat.toFixed(5)}`,
            };
            if (setNewLocation) {
              setNewLocation(updatedLocation);
            }
          });
        }
      }
    }
  }, [map, zoomLevel, newLocation, prevLocation]);

  return null;
};

export default TempMarker;
