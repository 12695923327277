import { makeAutoObservable, runInAction } from "mobx";
import {
  ChargerByOnboardingStatusResponse,
  ChargerDataDto,
  ChargerDataDtoRemoteMngm,
  ChargerInstructions,
  ChargerLog,
  ChargerLogRequestDto,
  ChargerResponse,
  ChargersByOnboardingStatus,
  ChargersOnboardingStatusCount,
  IMapChargers,
  UpdateChargerDto,
} from "../models/charger";
import agent from "../api/agent";
import { roundOnFixedDecimals } from "../../utils/numbers/numbersHelper";

export default class ChargerStore {
  chargers: ChargerDataDto[] | undefined;
  mapChargers: IMapChargers[] | undefined;
  chargersCount: number = 0;

  chargersRemote: ChargerDataDtoRemoteMngm[] | undefined;
  chargersRemoteCount: number = 0;

  chargersByOnboardingStatus: ChargersByOnboardingStatus[] | undefined;
  chargersByOnboardingStatusCount: number = 0;

  chargersOnboardingStatusCount: ChargersOnboardingStatusCount | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  getMapChargers = async () => {
    try {
      const result = await agent.Chargers.mapChargers();
      runInAction(() => {
        this.mapChargers = result;
      });
    } catch (error) {
      console.error("Failed to fetch map chargers", error);
    }
  };

  createCharger = async (charger: any) => {
    try {
      await agent.Chargers.createCharger(charger);
      return true;
    } catch (error) {
      console.log("Error creating new charger");
      return false;
    }
  };

  updateCharger = async (charger: UpdateChargerDto) => {
    try {
      await agent.Chargers.updateCharger(charger);
      return true;
    } catch (error) {
      console.log("Error creating new charger");
      return false;
    }
  };

  getChargers = async (
    pageIdx: number = 1,
    pageSize: number = 10,
    sortingField: string = "",
    sortingDescending: boolean = true
  ) => {
    try {
      const result: ChargerResponse = await agent.Chargers.chargers(
        pageIdx,
        pageSize,
        sortingField,
        sortingDescending
      );
      var chargersDto: ChargerDataDto[] = result.data.map((el) => ({
        id: el.id,
        ocppChargerId: el.ocppChargerId,
        numberOfConnectors: el.numberOfConnectors,
        chargerInformation: el.chargerInformation,
        street: el.street,
        longitude_latitude: `{${roundOnFixedDecimals(
          el.latitude,
          5
        )}, ${roundOnFixedDecimals(el.longitude, 5)}}`,
        maxPower: el.maxPower,
        maxCurrent: el.maxCurrent,
        numberOfPhases: el.numberOfPhases,
      }));
      runInAction(() => {
        this.chargers = chargersDto;
        this.chargersCount = result.totalCount;
      });
    } catch (error) {
      console.log("Error fetching chargers: ", error);
    }
  };

  getChargersRemoteManagement = async (
    pageIdx: number = 1,
    pageSize: number = 10,
    sortingField: string = "",
    sortingDescending: boolean = true
  ) => {
    try {
      const result: ChargerResponse = await agent.Chargers.chargers(
        pageIdx,
        pageSize,
        sortingField,
        sortingDescending
      );
      var chargersDto: ChargerDataDtoRemoteMngm[] = result.data.map((el) => ({
        id: el.id,
        ocppChargerId: el.ocppChargerId,
        numberOfConnectors: el.numberOfConnectors,
        chargerInformation: el.chargerInformation,
        street: el.street,
        longitude: roundOnFixedDecimals(el.longitude, 3),
        latitude: roundOnFixedDecimals(el.latitude, 3),
      }));
      runInAction(() => {
        this.chargersRemote = chargersDto;
        this.chargersRemoteCount = result.totalCount;
      });
    } catch (error) {
      console.log("Error fetching remote mangement chargers: ", error);
    }
  };

  getChargersOnboardingStatusCount = async () => {
    try {
      const result = await agent.Chargers.chargersOnboardingStatusCount();
      runInAction(() => (this.chargersOnboardingStatusCount = result));
    } catch (error) {
      console.log("Error fetching chargers onboarding status count: ", error);
    }
  };

  getChargersByOnboardingStatus = async (
    onboardingStatus: string,
    page: number = 1,
    perPage: number = 10,
    sortingField: string = "",
    sortingDescending: boolean = true
  ) => {
    try {
      const result: ChargerByOnboardingStatusResponse =
        await agent.Chargers.chargersByOnboardingStatus(
          onboardingStatus,
          page,
          perPage,
          sortingField,
          sortingDescending
        );
      // MAPPING
      const mappedData: ChargersByOnboardingStatus[] = result.data.map(
        (el: any) => ({
          ...el,
          latitude: roundOnFixedDecimals(el.latitude, 3),
          longitude: roundOnFixedDecimals(el.longitude, 3),
          instructions: "i",
        })
      );
      // END MAPPING
      runInAction(() => {
        this.chargersByOnboardingStatus = mappedData;
        this.chargersByOnboardingStatusCount = result.totalCount;
      });
    } catch (error) {
      console.log("Error fetching chargers by onboarding status: ", error);
    }
  };

  finishOnboardingCharger = async (chargerId: number) => {
    try {
      await agent.Chargers.finishOnboardingCharger(chargerId);
    } catch (err) {
      console.log("Error while finishing onboarding charger: ", err);
    }
  };

  getChargerLog = async (ocppChargerId: ChargerLogRequestDto) => {
    try {
      const res: ChargerLog[] = await agent.Chargers.getChargerLog(ocppChargerId);
      return res;
    } catch (err) {
      console.log("Error while fetching charger log: ", err);
      return undefined;
    }
  };

  getChargerInstructions = async (ocppChargerId: string) => {
    try {
      const res: ChargerInstructions = await agent.Chargers.chargerInstructions(ocppChargerId);
      return res;
    } catch (err) {
      console.log("Error while fetching charger instructions: ", err);
      return undefined;
    }
  }
}
