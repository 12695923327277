import Modal from "../../../../components/Modal/Modal";
import { useEffect, useState } from "react";
import TextInput from "../../../../components/TextInput/TextInput";
import Table from "../../../../components/Table/Table";
import { conenctorColumnOrder, connectorColumns } from "../networkData";
import AddressInput from "../../../../components/AddressInput/AddressInput";
import { ChargerDataDto } from "../../../../app/models/charger";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import Toast from "../../../../components/Toast/Toast";
import { Slider } from "@nextui-org/slider";

export interface FormData {
  id: number;
  ocppChargerId: string;
  longitude_latitude: string;
  chargerInformation: string;
  street: string;
  maxCurrent?: number;
  numberOfPhases?: number;
}

interface Props {
  selectedCharger: ChargerDataDto;
  open?: boolean;
  setOpenToast: (value: boolean) => void;
  setOpen: (value: boolean) => void;
}

const ChargerModal = observer(
  ({ selectedCharger, open, setOpen, setOpenToast }: Props) => {
    const { connectorStore, chargerStore } = useStore();
    const [isActive, setIsActive] = useState<boolean>(false);

    const [defaultChargerInfo, setDefaultChargerInfo] = useState<FormData>({ ...selectedCharger });

    const [editedChargerInfo, setEditedChargerInfo] = useState<FormData>({ ...selectedCharger });

    useEffect(() => {
      if (selectedCharger) {
        connectorStore.getConnectorByChargerId(selectedCharger.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectorStore]);

    useEffect(() => {
      if (
        JSON.stringify(defaultChargerInfo) !== JSON.stringify(editedChargerInfo) &&
        (editedChargerInfo.maxCurrent && editedChargerInfo.ocppChargerId && editedChargerInfo.numberOfPhases)
      )
        setIsActive(true);
      else setIsActive(false);
    }, [defaultChargerInfo, editedChargerInfo]);

    const toggleDrawer = () => {
      setOpen(false);
    };
    const handleConfirm = async () => {
      console.log(editedChargerInfo)
      await chargerStore.updateCharger(
        {
          chargerIdentificator: editedChargerInfo.ocppChargerId,
          maxCurrent: editedChargerInfo.maxCurrent,
          numberOfPhases: editedChargerInfo.numberOfPhases
        });
      await chargerStore.getChargers();
      await chargerStore.getMapChargers();
      setOpenToast(true);
      setOpen(false);
    };

    return (
      <Modal isVisible={open!} onClose={toggleDrawer} modalWidth="w-[40%]">
        <div className="flex flex-col gap-4 px-8 py-10">
          <p className="text-[20px] font-semibold text-darkGreen mb-4">
            Detailed charger information
          </p>
          <div className="flex flex-col gap-4">
            <div className="flex gap-5">
              <div className="flex flex-col gap-2 flex-1">
                <label className="text-[14px] font-bold text-darkGreen">
                  Charger ID
                </label>
                <TextInput
                  placeholder="ocppChargerId"
                  value={selectedCharger.ocppChargerId}
                  name="chargerId"
                  readonly={true}
                />
              </div>
              <div className="flex flex-col gap-2 flex-1">
                <label className="text-[14px] font-bold text-darkGreen">
                  Address
                </label>
                <AddressInput
                  value={selectedCharger.street || ""}
                  setValue={(value) => undefined}
                />
              </div>
            </div>
            <div className="flex gap-5">
              <div className="flex flex-col gap-2 flex-1">
                <label className="text-[14px] font-bold text-darkGreen">
                  Longitude & Latitude
                </label>
                <TextInput
                  placeholder="{x.xxx, x.xxx}"
                  value={selectedCharger.longitude_latitude}
                  name="longitude_latitude"
                  readonly={true}
                />
              </div>
              <div className="flex flex-col gap-2 flex-1">
                <label className="text-[14px] font-bold text-darkGreen">
                  Charger Model
                </label>
                <TextInput
                  placeholder="Charger Model"
                  value={selectedCharger.chargerInformation}
                  name="chargerInformation"
                  readonly={true}
                />
              </div>
            </div>
            <div className="flex gap-5 ">
              <div className="flex w-7/12">
                <Slider
                  step={1}
                  color="success"
                  maxValue={32}
                  minValue={6}
                  value={editedChargerInfo.maxCurrent}
                  defaultValue={selectedCharger.maxCurrent}
                  aria-label="Max Current"
                  label="Charger Max Current (A)"
                  onChange={(value) => setEditedChargerInfo((prevInfo: any) => ({
                    ...prevInfo,
                    maxCurrent: value
                  }))}
                  classNames={{
                    base: "max-w-md",
                    filler: "bg-gradient-to-r from-green-500 to-light-green",
                    labelWrapper: "mb-2",
                    label: "text-[14px] font-bold text-darkGreen mb-2",
                    thumb: [
                      "transition-size",
                      "bg-gradient-to-r from-green-500 to-light-green",
                      "data-[dragging=true]:shadow-lg data-[dragging=true]:shadow-black/20",
                      "data-[dragging=true]:w-7 data-[dragging=true]:h-7 data-[dragging=true]:after:h-6 data-[dragging=true]:after:w-6"
                    ],
                    step: "data-[in-range=true]:bg-black/30 dark:data-[in-range=true]:bg-white/50",
                  }}
                />
              </div>
              <div className="w-1/12"></div>
              <div className="w-4/12">

                <Slider
                  color="success"
                  step={1}
                  maxValue={3}
                  minValue={1}
                  value={editedChargerInfo.numberOfPhases}
                  defaultValue={selectedCharger.numberOfPhases}
                  label="Number of Phases"
                  showSteps={true}
                  classNames={{
                    base: "max-w-md",
                    filler: "bg-gradient-to-r from-green-500 to-light-green",
                    labelWrapper: "mb-2",
                    label: "text-[14px] font-bold text-darkGreen mb-2",
                    thumb: [
                      "transition-size",
                      "bg-gradient-to-r from-green-500 to-light-green",
                      "data-[dragging=true]:shadow-lg data-[dragging=true]:shadow-black/20",
                      "data-[dragging=true]:w-7 data-[dragging=true]:h-7 data-[dragging=true]:after:h-6 data-[dragging=true]:after:w-6"
                    ],
                    step: "data-[in-range=true]:bg-black/30 dark:data-[in-range=true]:bg-white/50",
                  }}
                  onChange={(value) => setEditedChargerInfo((prevInfo: any) => ({
                    ...prevInfo,
                    numberOfPhases: value
                  }))}
                />
              </div>

            </div>
            <div className="flex gap-5 items-end justify center">
              <div className="flex flex-1"></div>
              <div className="flex flex-1">
                <button
                  onClick={handleConfirm}
                  disabled={!isActive}
                  className={`flex flex-1 h-[51px] py-[15px] justify-center text-[16px] font-semibold items-center gap-[10px] rounded-[10px] ${isActive
                    ? "bg-light-green text-white"
                    : "bg-[var(--Foundation-White-Normal)] text-[#515151]"
                    }`}
                  style={{
                    background: isActive
                      ? undefined
                      : "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #E6E6E6",
                  }}
                >
                  Confirm
                </button>
              </div>
              <div className="flex flex-1"></div>
            </div>
            <div className="mt-5">
              <Table
                data={connectorStore.connectorsInCharger || []}
                columns={connectorColumns}
                columnOrder={conenctorColumnOrder}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);

export default ChargerModal;
