import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import CompanyInfo from "./CompanyInfo";
import ProfileInfo from "./ProfileInfo";
import { observer } from "mobx-react-lite";

const Profile = observer(() => {
  const { userStore } = useStore();
  const { user } = userStore;

  return (
    <div className="grid gap-4 grid-rows-[330px_auto] h-[100%]">
      <div className="grid grid-cols-[42%_auto] h-[330px] gap-4">
        <div className="bg-white flex items-center justify-center gap-10 rounded-[10px]">
          <img
            src="/assets/images/profileIcon.png"
            alt=""
            className="size-[110px]"
          />

          <div>
            <div className="text-[32px] font-semibold text-darkGreen">
              {user?.firstName} {user?.lastName}
            </div>

            <div className="text-[20px] font-normal text-lightGray">
              {user?.roleName}
            </div>
          </div>
        </div>
        <div className="bg-white rounded-[10px]">
          <ProfileInfo />
        </div>
      </div>

      <div className="bg-white flex-grow rounded-[10px]">
        <CompanyInfo />
      </div>
    </div>
  );
});

export default Profile;
