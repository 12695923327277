import React, { ReactNode, useEffect, useState } from "react";
import { useStore } from "../stores/store";
import { useNavigate } from "react-router-dom";
import MySpinner from "../../components/Spinner/MySpinner";

type Props = {
  children: ReactNode;
};

const LoginLayout = ({ children }: Props) => {
  const { commonStore } = useStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (commonStore.token) {
      navigate("/dashboard/overview");
    }
    setLoading(false);
  }, [commonStore.token, navigate]);

  return (
    <>
      {loading ? (
        <MySpinner />
      ) : (
        <div className="flex h-screen w-screen">
          <div className="w-[45%] h-full bg-darkGreen flex items-center justify-center">
            <div className="flex gap-3 items-center ">
              <img
                width={100}
                height={100}
                src="//images.squarespace-cdn.com/content/v1/65def2dbfe0b3f13ad579ae1/3941bdf0-980a-42d2-9d60-f77e335462a0/EnergAI+logo+with+border.png?format=1500w"
                alt="logo"
              />
              <p className="audiowide-regular text-[92px] text-[#E6F6F4]">
                EnergAI
              </p>
            </div>
          </div>

          <main className="w-[55%] flex bg-white">{children}</main>
        </div>
      )}
    </>
  );
};

export default LoginLayout;
