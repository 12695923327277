import React, { useState } from "react";
import Instruction from "../OnboardingInstruction/Instruction";

interface Props {
  columns: any[];
  columnOrder: string[];
  selected?: number | number[];
  handleSelection?: (id: number) => void;
  data: any[];
  toggleStates?: boolean[];
  isSessionHistory?: boolean;
  handleToggleAttempt?: (id: number, newState: boolean) => void;
  expandable?: boolean;
  handleExpand?: (id: number) => void;
  children?: React.ReactNode;
  sortConfig?: { key: string; direction: "ascending" | "descending" } | null;
  setSortConfig?: (
    config: { key: string; direction: "ascending" | "descending" } | null
  ) => void;
}

const Table = ({
  columns,
  columnOrder,
  data,
  selected,
  isSessionHistory,
  toggleStates,
  handleSelection,
  handleToggleAttempt,
  expandable,
  sortConfig,
  setSortConfig,
  handleExpand,
  children,
}: Props) => {
  const [sort, setSort] = useState({ sortBy: -1, sortMethod: "descending" });
  const [hoveredInstructionRow, setHoveredInstructionRow] = useState<
    number | null
  >(null);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const orderedColumns = columnOrder.map((accessor) =>
    columns.find((col) => col.accessor === accessor)
  );

  const handleSort = (columnKey: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === columnKey &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig?.({ key: columnKey, direction });
  };

  const getStatusStyles = (status: string) => {
    switch (status) {
      case "Available":
        return {
          container:
            "bg-[#CDE6C4] text-[#5FAD41] rounded-10 py-[4px] px-[10px] inline-block",
          text: "Available",
        };
      case "Preparing":
        return {
          container:
            "bg-[#FDEDDA] text-[#F28A05] rounded-10 py-[4px] px-[10px] inline-block",
          text: "Preparing",
        };
      case "SuspendedEVSE":
        return {
          container:
            "bg-[#FDEDDA] text-[#F28A05] rounded-10 py-[4px] px-[10px] inline-block",
          text: "SuspendedEVSE",
        };
      case "SuspendedEV":
        return {
          container:
            "bg-[#FDEDDA] text-[#F28A05] rounded-10 py-[4px] px-[10px] inline-block",
          text: "SuspendedEV",
        };
      case "Finishing":
        return {
          container:
            "bg-[#FDEDDA] text-[#F28A05] rounded-10 py-[4px] px-[10px] inline-block",
          text: "Finishing",
        };
      case "Reserved":
        return {
          container:
            "bg-[#FDEDDA] text-[#F28A05] rounded-10 py-[4px] px-[10px] inline-block",
          text: "Reserved",
        };
      case "Charging":
        return {
          container:
            "bg-[#FDEDDA] text-[#F28A05] rounded-10 py-[4px] px-[10px] inline-block",
          text: "Charging",
        };
      case "Faulted":
        return {
          container:
            "bg-[#F9E0E0] text-[#DA3333] rounded-10 py-[4px] px-[10px] inline-block",
          text: "Faulted",
        };

      case "Unavailable":
        return {
          container:
            "bg-[#F9E0E0] text-[#DA3333] rounded-10 py-[4px] px-[10px] inline-block",
          text: "Unavailable",
        };

      default:
        return {
          container: "",
          text: status,
        };
    }
  };

  const getDemandShiftingStyles = (isOn: boolean) => {
    return isOn
      ? "w-[54px] bg-[#ECF6E3] text-[#669A35] rounded-10 py-[8px] px-[10px] inline-block text-[14px] font-medium"
      : "w-[54px] bg-[#FBEBEB] text-[#DA3333] rounded-10 py-[8px] px-[10px] inline-block text-[14px] font-medium";
  };

  const getInstructionStyle = () => {
    return (
      <div className="relative w-[24px] h-[24px] rounded-full bg-transparent border-2 border-darkGreen hover:border-light-green text-darkGreen hover:text-light-green text-[14px] font-bold flex items-center justify-center">
        i
      </div>
    );
  };

  const handleExpandToggle = (rowId: number) => {
    expandedRow === rowId
      ? handleExpand && handleExpand(0)
      : handleExpand && handleExpand(rowId);
    setExpandedRow((prevExpandedRow) =>
      prevExpandedRow === rowId ? null : rowId
    );
  };

  const getExpandIcon = (id: number) => {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          handleExpandToggle(id);
        }}
        className="w-[20px] h-[20px] rounded-[4px] bg-light-green flex items-center justify-center"
      >
        <img
          src={
            expandedRow === id
              ? "/assets/svg/table/minus.svg"
              : "/assets/svg/table/plus.svg"
          }
          alt=""
        />
      </div>
    );
  };

  return (
    <table className="w-full table-fixed">
      <thead>
        <tr>
          {orderedColumns.map((col, index) => (
            <th
              key={index}
              className={`text-[14px] font-bold text-darkGreen text-left py-3 px-2.5 ${index === orderedColumns.length - 1
                  ? ""
                  : "border-r-[1.4px] border-r-[rgba(0,59,51,0.10)]"
                } bg-[rgba(0,59,51,0.05)]`}
              style={{
                width:
                  col.accessor === "demandShifting" ||
                    col.accessor === "instructions"
                    ? "150px"
                    : "auto",
              }}
              onClick={
                col.accessor === "timeStart"
                  ? () => handleSort(col.accessor)
                  : undefined
              }
            >
              <div className="flex flex justify-between items-center truncate cursor-pointer">
                {col.header}
                {col.accessor === "timeStart" && (
                  <img
                    src={
                      sortConfig?.key === col.accessor
                        ? sortConfig?.direction === "ascending"
                          ? "/assets/svg/arrow/sortUp.svg"
                          : "/assets/svg/arrow/sortDown.svg"
                        : "/assets/svg/arrow/sort.svg"
                    }
                    alt="Sort Icon"
                    className="ml-1"
                  />
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <React.Fragment key={row.id}>
            <tr
              onClick={() => handleSelection && handleSelection(row.id)}
              className={`${row.id === selected
                  ? "bg-[#E6F6F4]"
                  : "bg-[#FDFDFD] hover:bg-[#FBFBFB]"
                } ${handleSelection ? "cursor-pointer" : ""}`}
            >
              {orderedColumns.map((col, colIndex) => (
                <td
                  className={`py-2 px-2.5 text-[14px] font-medium text-darkGreen items-center ${colIndex === orderedColumns.length - 1
                      ? ""
                      : "border-r-[1.4px] border-r-[rgba(0,59,51,0.10)]"
                    } ${col.accessor === "instructions" ? "flex justify-center" : ""
                    } ${col.accessor === "demandShifting" && isSessionHistory
                      ? "text-center"
                      : ""
                    }`}
                  key={colIndex}
                  style={{
                    width:
                      col.accessor === "demandShifting" ||
                        col.accessor === "instructions"
                        ? "150px"
                        : "auto",
                  }}
                >
                  {col.accessor === "ocppChargerId" && expandable ? (
                    <div className="flex gap-3 items-center">
                      {getExpandIcon(row.id)} {row[col?.accessor]}
                    </div>
                  ) : col?.accessor === "connectorStatus" ? (
                    <div
                      className={`rounded p-1 ${getStatusStyles(row[col.accessor]).container
                        }`}
                    >
                      {getStatusStyles(row[col.accessor]).text}
                    </div>
                  ) : col?.accessor === "demandShifting" ? (
                    isSessionHistory ? (
                      <div
                        className={`${getDemandShiftingStyles(
                          row.demandShifting
                        )}`}
                      >
                        {row.demandShifting ? "ON" : "OFF"}
                      </div>
                    ) : (
                      <label
                        className="relative inline-flex items-center cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleToggleAttempt?.(row.id, !row.demandShifting);
                        }}
                      >
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          disabled={true}
                          checked={row.demandShifting}
                        />
                        <div className="w-[60px] h-[30px] bg-input-border peer-focus:outline-none rounded-full peer peer-checked:bg-[#80C042] after:content-[''] after:absolute after:top-[3px] after:left-[4.4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[24px] after:w-[24px] after:transition-all after:duration-500 peer-checked:after:translate-x-[28px]"></div>
                      </label>
                    )
                  ) : col?.accessor === "instructions" ? (
                    <div
                      className="relative"
                      onMouseEnter={() => setHoveredInstructionRow(rowIndex)}
                      onMouseLeave={() => setHoveredInstructionRow(null)}
                    >
                      {getInstructionStyle()}
                      {hoveredInstructionRow === rowIndex && <Instruction ocppChargerId={row?.ocppChargerId} />}
                    </div>
                  ) : (
                    <div className="truncate">{row[col?.accessor]}</div>
                  )}
                </td>
              ))}
            </tr>

            {expandedRow === row.id && (
              <tr>
                <td colSpan={orderedColumns.length}>
                  <div className="bg-[#f5f5f5]"> {children}</div>
                </td>
              </tr>
            )}

            {rowIndex < data.length - 1 && (
              <tr key={`spacer-${rowIndex}`} className="h-[4px]">
                <td
                  colSpan={orderedColumns.length}
                  className="bg-transparent"
                ></td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
