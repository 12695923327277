import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import MySpinner from "../../components/Spinner/MySpinner";
import { sidebarImages } from "../../utils/preload/imagePreload";
import { useStore } from "../stores/store";
import { observer } from "mobx-react-lite";

const DashboardLayout = observer(() => {
  const { commonStore, userStore } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const preloadImages = (imageUrls: string[]) => {
    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };

  useEffect(() => {
    if (commonStore.token) {
      userStore.getAdminInfo();
      userStore.getCompanyInfo();
      commonStore.getCountriesForDropdown();
      preloadImages(Object.values(sidebarImages));
      pathname === "/" && navigate("/dashboard/overview");
    } else navigate("/auth/login");
  }, [commonStore, commonStore.token, navigate, pathname, userStore]);

  if (!commonStore.token) {
    return <MySpinner />;
  }

  return (
    <div className="flex h-screen bg-[#F7F7F7]">
      <div className="w-[275px] h-full">
        <Sidebar />
      </div>
      <div className="flex-1 flex flex-col flex-grow">
        <Header />
        <div className="flex-1 overflow-y-auto pt-3 px-4 ">
          <Outlet />
        </div>
      </div>
    </div>
  );
});

export default DashboardLayout;
