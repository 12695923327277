export function formatTime(time: string, secundesIncluded: boolean) {
  let splited = time.split(":", secundesIncluded ? 3 : 2);
  for (let i = 0; i < splited.length; i++) {
    splited[i] = splited[i].split(".")[0];
  }
  return splited.join(":");
}

export function formatDemandShiftingTime(isoString: string) {
  let date: Date;
  isoString.slice(-1) === "Z"
    ? (date = new Date(isoString))
    : (date = new Date(isoString + "Z"));

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

export const formatDateLocale = (
  dateString: string,
  locale = navigator.language
) => {
  let formattedDate = dateString.replaceAll("-", "/").split("T")[0];
  const date = new Date(formattedDate);

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  };
  let localeDateString = new Intl.DateTimeFormat(locale, options).format(date);

  localeDateString = localeDateString.replaceAll(".", "/").replaceAll("-", "/");
  localeDateString = localeDateString
    .replaceAll(" /", "/")
    .replaceAll("/ ", "/");

  if (localeDateString.endsWith("/")) {
    localeDateString = localeDateString.slice(0, -1);
  }

  return localeDateString;
};

export const formatDemandDateLocale = (
  dateInput: string | Date,
  locale = navigator.language
) => {
  let date: Date;
  if (typeof dateInput === "string") {
    let formattedDate = dateInput.replaceAll("-", "/").split("T")[0];
    date = new Date(formattedDate);
  } else {
    date = dateInput;
  }

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  let localeDateString = new Intl.DateTimeFormat(locale, options).format(date);

  localeDateString = localeDateString.replaceAll(".", "/").replaceAll("-", "/");
  localeDateString = localeDateString
    .replaceAll(" /", "/")
    .replaceAll("/ ", "/");

  if (localeDateString.endsWith("/")) {
    localeDateString = localeDateString.slice(0, -1);
  }

  return localeDateString;
};

export function formatDateStringBasedOnDateRange(
  dateStart: string,
  dateStop: string,
  dateString: string,
  dayRangeLimit: number
) {
  let dateStartDate = new Date(dateStart);
  let dateStopDate = new Date(dateStop);
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in days between dateStart and dateStop
  const diffDays = Math.floor(
    (dateStopDate.getTime() - dateStartDate.getTime()) / _MS_PER_DAY
  );

  if (diffDays >= dayRangeLimit) {
    // Format date only using locale
    return formatDateLocale(dateString);
  } else {
    // Format date and time using locale
    const datePart = formatDateLocale(dateString);
    const timePart = formatTime(dateString.split("T")[1], false);
    return `${datePart} ${timePart}`;
  }
}
// dateUtils.ts

export const adjustDate = (dateString: string, days: number): string => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + days);
  return date.toISOString();
};

export function formatDateToHourMinute(dateString: string) {
  const date = new Date(dateString); // Parse the date string

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid date:", dateString);
    return ""; // Return an empty string or handle the error accordingly
  }

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`; // Return formatted hour and minute string
}

export function formatDateForChart(
  dateStart: string,
  dateStop: string,
  dateString: string,
  dayRangeLimit: number
) {
  let dateStartDate = new Date(dateStart);
  let dateStopDate = new Date(dateStop);
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in days between dateStart and dateStop
  const diffDays = Math.floor(
    (dateStopDate.getTime() - dateStartDate.getTime()) / _MS_PER_DAY
  );

  const date = new Date(dateString + "Z");
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const formattedDate = `${day}/${month}/${year}`;
  const formattedTime = `${hours}:${minutes}`;

  if (diffDays >= dayRangeLimit) {
    return formattedDate;
  } else {
    return `${formattedDate} ${formattedTime}`;
  }
};

export const convertUTCDateTimeToLocale = (utcTimeString: string) => {
  let date: Date;
  utcTimeString.slice(-1) === 'Z' ? date = new Date(utcTimeString) : date = new Date(utcTimeString + 'Z');
  const time = date.getTime();
  const utcDate = new Date(time);
  const localTime = time - utcDate.getTimezoneOffset() * 60 * 1000
  const localDate = new Date(localTime)
  const localIso = localDate.toISOString();
  return localIso;
};
