export const sessionActiveColumns = [
  { header: "Start Time", accessor: "timeStart" },
  { header: "Charging Session ID", accessor: "id" },
  { header: "Duration", accessor: "duration" },
  { header: "Demand Shifting", accessor: "demandShifting" },
  { header: "Electricity Consumed", accessor: "electricityConsumed" },
  { header: "Current Power", accessor: "currentPower" },
  { header: "Car Battery", accessor: "carBattery" },
  { header: "Connector ID", accessor: "connectorId" },
];

export const sessionActiveColumnOrder = [
  "timeStart",
  "id",
  "duration",
  "demandShifting",
  "electricityConsumed",
  "currentPower",
  "carBattery",
  "connectorId",
];

export const sessionHistoryColumns = [
  { header: "Start Time", accessor: "timeStart" },
  { header: "Stop Time", accessor: "timeStop" },
  { header: "Charging Session ID", accessor: "id" },
  { header: "Duration", accessor: "duration" },
  { header: "Demand Shifting", accessor: "demandShifting" },
  { header: "Electricity Consumed", accessor: "electricityConsumed" },
  { header: "Connector ID", accessor: "connectorId" },

];

export const sessionHistoryColumnOrder = [
  "timeStart",
  "timeStop",
  "duration",
  "demandShifting",
  "id",
  "electricityConsumed",
  "connectorId",
];


export const dummyDataSession = [
  {
    timeStart: "2024-09-22T08:30:00",
    timeStop: "2024-09-22T09:30:00",
    id: 12,
    duration: "1h 30m",
    demandShifting: true,
    electricityConsumed: "25.6 kWh",
    carBattery: "80%",
    connectorId: "C1",
  },
  {
    timeStart: "2024-09-22T10:15:00",
    timeStop: "2024-09-22T09:30:00",
    id: 11,
    duration: "45m",
    demandShifting: false,
    electricityConsumed: "15.2 kWh",
    carBattery: "60%",
    connectorId: "C2",
  },
  {
    timeStart: "2024-09-22T12:00:00",
    timeStop: "2024-09-22T09:30:00",
    id: 1,
    duration: "2h 10m",
    demandShifting: true,
    electricityConsumed: "32.8 kWh",
    carBattery: "90%",
    connectorId: "C3",
  },
  {
    timeStart: "2024-09-22T14:30:00",
    timeStop: "2024-09-22T09:30:00",
    id: 3,
    duration: "1h",
    demandShifting: false,
    electricityConsumed: "18.9 kWh",
    carBattery: "50%",
    connectorId: "C1",
  },
  {
    timeStart: "2024-09-22T16:45:00",
    timeStop: "2024-09-22T09:30:00",
    id: 5,
    duration: "1h 20m",
    demandShifting: true,
    electricityConsumed: "28.4 kWh",
    carBattery: "70%",
    connectorId: "C4",
  },
  {
    timeStart: "2024-09-22T18:20:00",
    timeStop: "2024-09-22T09:30:00",
    id: 4,
    duration: "50m",
    demandShifting: false,
    electricityConsumed: "22.1 kWh",
    carBattery: "85%",
    connectorId: "C2",
  },
  {
    timeStart: "2024-09-22T14:30:00",
    timeStop: "2024-09-22T09:30:00",
    id: 55,
    duration: "1h",
    demandShifting: false,
    electricityConsumed: "18.9 kWh",
    carBattery: "50%",
    connectorId: "C1",
  },
  {
    timeStart: "2024-09-22T16:45:00",
    timeStop: "2024-09-22T09:30:00",
    id: 77,
    duration: "1h 20m",
    demandShifting: true,
    electricityConsumed: "28.4 kWh",
    carBattery: "70%",
    connectorId: "C4",
  },
  {
    timeStart: "2024-09-22T18:20:00",
    timeStop: "2024-09-22T09:30:00",
    id: 67,
    duration: "50m",
    demandShifting: false,
    electricityConsumed: "22.1 kWh",
    carBattery: "85%",
    connectorId: "C2",
  },
  {
    timeStart: "2024-09-22T14:30:00",
    timeStop: "2024-09-22T09:30:00",
    id: 56,
    duration: "1h",
    demandShifting: false,
    electricityConsumed: "18.9 kWh",
    carBattery: "50%",
    connectorId: "C1",
  },
  {
    timeStart: "2024-09-22T16:45:00",
    timeStop: "2024-09-22T09:30:00",
    id: 88,
    duration: "1h 20m",
    demandShifting: true,
    electricityConsumed: "28.4 kWh",
    carBattery: "70%",
    connectorId: "C4",
  },
  {
    timeStart: "2024-09-22T18:20:00",
    timeStop: "2024-09-22T09:30:00",
    id: 87,
    duration: "50m",
    demandShifting: false,
    electricityConsumed: "22.1 kWh",
    carBattery: "85%",
    connectorId: "C2",
  },
  {
    timeStart: "2024-09-22T14:30:00",
    timeStop: "2024-09-22T09:30:00",
    id: 8,
    duration: "1h",
    demandShifting: false,
    electricityConsumed: "18.9 kWh",
    carBattery: "50%",
    connectorId: "C1",
  },
  {
    timeStart: "2024-09-22T16:45:00",
    timeStop: "2024-09-22T09:30:00",
    id: 9,
    duration: "1h 20m",
    demandShifting: true,
    electricityConsumed: "28.4 kWh",
    carBattery: "70%",
    connectorId: "C4",
  },
  {
    timeStart: "2024-09-22T18:20:00",
    timeStop: "2024-09-22T09:30:00",
    id: 99,
    duration: "50m",
    demandShifting: false,
    electricityConsumed: "22.1 kWh",
    carBattery: "85%",
    connectorId: "C2",
  },
];

