// utils/imageUrls.ts

export const sidebarImages = {
  dashboard: "/assets/svg/sidebar/dashboard.svg",
  dashboardActive: "/assets/svg/sidebar/dashboardActive.svg",
  sessions: "/assets/svg/sidebar/sessions.svg",
  sessionsActive: "/assets/svg/sidebar/sessionsActive.svg",
  network: "/assets/svg/sidebar/network.svg",
  networkActive: "/assets/svg/sidebar/networkActive.svg",
  operations: "/assets/svg/sidebar/operations.svg",
  operationsActive: "/assets/svg/sidebar/operationsActive.svg",
  profile: "/assets/svg/sidebar/profile.svg",
  profileActive: "/assets/svg/sidebar/profileActive.svg",
  signout: "/assets/svg/sidebar/signout.svg",
  arrowUp: "/assets/svg/arrow/arrowUp.svg",
  arrowDown: "/assets/svg/arrow/arrowDown.svg",
  dotActive: "/assets/svg/dot/active.svg",
  dotInactive: "/assets/svg/dot/inactive.svg",
  logo: "/assets/svg/logo/logo.svg",
  hrBorder: "/assets/svg/line.svg",
};
