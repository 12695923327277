import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  DemandShiftingData,
  EnergyConsumptionResponse,
  EnergyData,
} from "../models/chart";

export default class DashboardStore {
  energyConsumption: EnergyData = {
    pointDtos: [],
  };

  demandShiftingUsage: DemandShiftingData = {
    pointDtos: [],
  };

  energyComparison: EnergyConsumptionResponse = {
    realEnergyConsumed: [],
    estimatedEnergyConsumed: [],
    price: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  getEnergyComparison = async (
    startDate: string = "",
    endDate: string = ""
  ) => {
    try {
      const data = await agent.Chart.energyComparison(startDate, endDate);
      runInAction(() => {
        this.energyComparison = {
          realEnergyConsumed: data.realEnergyConsumed,
          estimatedEnergyConsumed: data.estimatedEnergyConsumed,
          price: data.price,
        };
      });
    } catch (error) {
      console.log("Failed to fetch energy comparison data: ", error);
    }
  };

  getEnergyConsumption = async (
    startDate: string = "",
    endDate: string = ""
  ) => {
    try {
      const data: EnergyData = await agent.Chart.energyConsumption(
        startDate,
        endDate
      );
      runInAction(() => {
        this.energyConsumption = data;
      });
    } catch (error) {
      console.log("Failed to fetch energy consumption data: ", error);
    }
  };

  getDemandShiftingUsage = async (
    startDate: string = "",
    endDate: string = ""
  ) => {
    try {
      const data: DemandShiftingData = await agent.Chart.demandShiftingUsage(
        startDate,
        endDate
      );
      runInAction(() => {
        this.demandShiftingUsage = data;
      });
    } catch (error) {
      console.log("Failed to fetch demand shifting usage data: ", error);
    }
  };
}
