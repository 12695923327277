import { ReactNode, useEffect } from "react";

type Props = {
  children: ReactNode;
  onClose: () => void;
  modalWidth: string;
  isVisible: boolean;
};

const Modal = ({ children, onClose, modalWidth, isVisible }: Props) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [onClose]);

  return (
    <>
      {isVisible && (
        <div
          className="fixed inset-0 z-900"
          style={{
            background: "rgba(0, 0, 0, 0.60)",
            opacity: 0.3,
          }}
          onClick={onClose}
          aria-hidden="true"
        />
      )}

      <aside
        className={`fixed top-0 right-0 h-full ${modalWidth} bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-1200 ${
          isVisible ? "translate-x-0" : "translate-x-full"
        }`}
        role="dialog"
        aria-modal="true"
        style={{ pointerEvents: isVisible ? "auto" : "none" }}
      >
        <div className="flex h-[10%] justify-end items-center border-b-2 border-[#E6E6E6]-500 px-8">
          <button onClick={onClose}>
            <img src="/assets/svg/cross/cross.svg" alt="Close" />
          </button>
        </div>
        {children}
      </aside>
    </>
  );
};

export default Modal;
