import React, { useEffect } from "react";

interface Props {
  text: string;
  visible: boolean;
  onClose: () => void;
  positive: boolean;
}
export const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));

const Toast = ({ text, visible, onClose, positive }: Props) => {
  useEffect(() => {
    if (visible) delay(5000).then(() => onClose());
  }, [onClose, visible]);

  if (!visible) return null;

  return (
    <div
      className={`flex items-center text-[14px] font-medium justify-center gap-4 absolute top-0 rounded-b-[10px] left-1/2 transform -translate-x-1/2  text-white p-4 shadow-lg ${
        positive
          ? "bg-light-green text-[#FBFBFB]"
          : "bg-[#DA3333] text-[#FDFDFD]"
      } `}
    >
      <img
        src={
          positive
            ? "/assets/svg/toast/positive.svg"
            : "/assets/svg/toast/negative.svg"
        }
        alt="Toast Icon"
      />
      {text}
    </div>
  );
};

export default Toast;
