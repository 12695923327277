interface SubtableProps {
  columns: any[];
  columnOrder: string[];
  data: any[];
}

const RemoteSubtable = ({ columns, columnOrder, data }: SubtableProps) => {
  const orderedColumns = columnOrder.map((accessor) =>
    columns.find((col) => col.accessor === accessor)
  );

  const getStatusStyles = (status: string) => {
    switch (status) {
      case "Available":
        return {
          container:
            "bg-[#CDE6C4] text-[#5FAD41] rounded-10 py-[4px] px-[10px] inline-block",
          text: "Available",
        };
      case "Preparing":
        return {
          container:
            "bg-[#FDEDDA] text-[#F28A05] rounded-10 py-[4px] px-[10px] inline-block",
          text: "Preparing",
        };
      case "SuspendedEVSE":
        return {
          container:
            "bg-[#FDEDDA] text-[#F28A05] rounded-10 py-[4px] px-[10px] inline-block",
          text: "SuspendedEVSE",
        };
      case "SuspendedEV":
        return {
          container:
            "bg-[#FDEDDA] text-[#F28A05] rounded-10 py-[4px] px-[10px] inline-block",
          text: "SuspendedEV",
        };
      case "Finishing":
        return {
          container:
            "bg-[#FDEDDA] text-[#F28A05] rounded-10 py-[4px] px-[10px] inline-block",
          text: "Finishing",
        };
      case "Reserved":
        return {
          container:
            "bg-[#FDEDDA] text-[#F28A05] rounded-10 py-[4px] px-[10px] inline-block",
          text: "Reserved",
        };
      case "Charging":
        return {
          container:
            "bg-[#FDEDDA] text-[#F28A05] rounded-10 py-[4px] px-[10px] inline-block",
          text: "Charging",
        };
      case "Faulted":
        return {
          container:
            "bg-[#F9E0E0] text-[#DA3333] rounded-10 py-[4px] px-[10px] inline-block",
          text: "Faulted",
        };

      case "Unavailable":
        return {
          container:
            "bg-[#F9E0E0] text-[#DA3333] rounded-10 py-[4px] px-[10px] inline-block",
          text: "Unavailable",
        };

      default:
        return {
          container: "",
          text: status,
        };
    }
  };

  return (
    <table className="w-full table-fixed">
      <thead>
        <tr>
          <th className="w-[50px] bg-[rgba(0,59,51,0.05)] border-r-[1.5px] border-r-[rgba(0,59,51,0.10)]"></th>
          {orderedColumns.map((col, index) => (
            <th
              key={index}
              className={`text-[14px] font-bold text-darkGreen text-left py-3 px-2.5 border-r-[1.5px] border-r-[rgba(0,59,51,0.10)] bg-[rgba(0,59,51,0.05)]`}
              style={{
                width: "190px",
              }}
            >
              <div className="truncate">{col?.header}</div>
            </th>
          ))}
          <th className="w-[100%] py-3 px-2.5 border-r-[1.5px] border-r-[rgba(0,59,51,0.10)] bg-[rgba(0,59,51,0.05)]"></th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr className="bg-[#F7F7F7]" key={row.id}>
            <td className="w-[50px] border-r-[1.5px] border-r-[rgba(0,59,51,0.10)] border-b-[2px] border-b-[#E6E6E6]"></td>
            {orderedColumns.map((col, colIndex) => (
              <td
                className="py-2 px-2.5 text-[14px] font-medium text-darkGreen items-center border-r-[1.5px] border-r-[rgba(0,59,51,0.10)] border-b-[2px] border-b-[#E6E6E6]"
                key={colIndex}
                style={{
                  width: "190px",
                }}
              >
                {col?.accessor === "connectorStatus" ? (
                  <div
                    className={`rounded p-1 ${
                      getStatusStyles(row[col.accessor]).container
                    }`}
                  >
                    {getStatusStyles(row[col.accessor]).text}
                  </div>
                ) : (
                  <div className="truncate">{row[col?.accessor]}</div>
                )}
              </td>
            ))}
            <td className="w-[100%] py-2 px-2.5 border-r-[1.5px] border-r-[rgba(0,59,51,0.10)] border-b-[2px] border-b-[#E6E6E6]"></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RemoteSubtable;
