import { useEffect, useRef } from "react";

interface Props {
  handleDownloadCSV: () => void;
  closeDownload?: () => void;
}

export const CSVdownload = ({ handleDownloadCSV, closeDownload }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        closeDownload &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        closeDownload();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeDownload]);

  return (
    <div
      ref={ref}
      className="whitespace-nowrap flex flex-row rounded-[5px] py-3 px-2 justify-between items-center bg-white"
      onClick={handleDownloadCSV}
    >
      <div className="py-1 px-2 rounded-[5px] bg-[#F7F7F7] hover:bg-[#D9F2EF]">
        <p className="text-[10px] font-medium text-darkGreen">
          Download data in .csv
        </p>
        <img src="/icons/download/download-blue.svg" alt="" />
      </div>
    </div>
  );
};
