import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { useState, useEffect, useMemo } from "react";
import { CSVdownload } from "../../../../components/CSVdownload/CSVdownload";
import { downloadCSV } from "../../../../utils/csv/downloadCsv";

interface Props {
  xValuesFiltered?: string[];
  xValues: string[];
  yLeft1: number[]; // demandShift
  yLeft2: number[]; // expDemandShift
  yRight: number[]; // price
}

const Energy = ({
  xValues,
  xValuesFiltered,
  yLeft1,
  yLeft2,
  yRight,
}: Props) => {
  const [stepSizeY, setStepSizeY] = useState<number>(0);
  const [stepSizeY1, setStepSizeY1] = useState<number>(0);
  const [optionsHovered, setOptionsHovered] = useState(false);

  Chart.defaults.color = "#ADADAD";

  const calculateMinMax = (data: any) => {
    const min = Math.min(...data);
    const max = Math.max(...data);
    return { min, max };
  };
  const roundToNearest = (value: number, increment: number) => {
    return Math.round(value / increment) * increment;
  };
  const { min: leftMin, max: leftMax } = calculateMinMax([
    ...yLeft1,
    ...yLeft2,
  ]);
  const { min: rightMin, max: rightMax } = calculateMinMax(yRight);

  const adjustedLeftMax = roundToNearest(leftMax + 2, 4);
  const adjustedRightMax = roundToNearest(rightMax + 2, 4);
  const data = useMemo(
    () => ({
      labels: xValues,
      datasets: [
        {
          data: yLeft1,
          borderColor: "#009883",
          fill: false,
          yAxisID: "y",
          tension: 0.4,
        },
        {
          data: yLeft2,
          borderColor: "rgba(0, 152, 131, 0.5)",
          fill: false,
          yAxisID: "y",
          tension: 0.4,
          borderDash: [10, 8],
        },
        {
          data: yRight,
          borderColor: "#DA3333",
          fill: false,
          yAxisID: "y1", // Right y-axis
          tension: 0.4,
          borderDash: [3, 1.5],
        },
      ],
    }),
    [xValues, yLeft1, yLeft2, yRight]
  );

  const options = useMemo(
    () => ({
      maintainAspectRatio: false,
      responsive: true,
      dragData: false,
      stacked: false,
      scales: {
        y: {
          dragData: false,
          type: "linear" as const,
          display: true,
          position: "left" as const,
          min: 0,
          max: adjustedLeftMax,
          grid: {
            drawOnChartArea: false,
          },
          title: {
            display: true,
            text: "kWh",
            color: "#009883",
            font: {
              weight: 500,
            },
          },
        },
        y1: {
          dragData: false,
          type: "linear" as const,
          display: true,
          position: "right" as const,
          min: 0,
          max: adjustedRightMax,
          grid: {
            drawOnChartArea: false,
          },
          title: {
            display: true,
            text: "EUR/kWh",
            color: "#DA3333",
            font: {
              weight: 500,
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: "#004C41",
          displayColors: false,
          padding: {
            top: 6,
            bottom: 6,
            left: 8,
            right: 8,
          },
          callbacks: {
            title: () => "",
            label: function (tooltipItem: any) {
              return `${tooltipItem.raw.toFixed(2)}`;
            },
          },
        },
      },
    }),
    [adjustedLeftMax, adjustedRightMax]
  );

  const handleGetEnergyConsumptionCSV = () => {
    try {
      const zip = (
        yLeft1: number[],
        yLeft2: number[],
        yRight: number[],
        x: string[]
      ) =>
        yLeft1.map((yVal, idx) => ({
          demandShift: yVal,
          expDemandShift: yLeft2[idx],
          price: yRight[idx],
          date: x[idx],
        }));
      downloadCSV(zip(yLeft1, yLeft2, yRight, xValues), "EnergyDispersed");
    } catch (err) {
      console.log("Error while mapping data for download csv: ", err);
    }
  };

  return (
    <div className="flex flex-grow flex-col gap-6 w-full">
      <div className="flex justify-end items-center w-[100%]">
        <div className="flex items-center gap-5 relative">
          <div className="flex gap-5">
            <div className="flex items-center gap-2 text-[12px] text-darkGreen">
              <div className="border-b-2 border-[#009883] w-6"></div>
              Energy Consumption
            </div>
            <div className="flex items-center gap-2 text-[12px] text-[#515151]">
              <div className="border-b-2 border-dashed border-[#009883] w-6"></div>
              Estimated energy consumption without demand shifting
            </div>
            <div className="flex items-center gap-2 text-[12px] text-[#515151]">
              <div className="border-b-2 border-dotted border-[#DA3333] w-6"></div>
              Electricity Price
            </div>
          </div>
          <div className="relative cursor-pointer">
            <img
              src={
                optionsHovered
                  ? "/assets/svg/dots/dotsActive.svg"
                  : "/assets/svg/dots/dots.svg"
              }
              alt=""
              className="size-7"
              onClick={() => setOptionsHovered((prev) => !prev)}
            />
            {optionsHovered && (
              <div
                style={{ boxShadow: "0px 14px 29.6px 0px rgba(0, 0, 0, 0.07)" }}
                className="absolute top-7 right-0"
              >
                <CSVdownload
                  handleDownloadCSV={handleGetEnergyConsumptionCSV}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <Line data={data} options={options} className="h-[330px] w-full" />
      </div>
    </div>
  );
};

export default Energy;
