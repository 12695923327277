import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./Map.css";
import { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import FitBounds from "./FitBounds";
import { MAP_URL } from "../../utils/map/map";
import MarkerCluster from "./MarkerCluster";
import { ChargerDataDto } from "../../app/models/charger";
import TempMarker from "../../pages/Dashboard/Network/NetworkOverview/Onboarding/TempMarker";

interface Props {
  newLocation?: any | null;
  setNewLocation?: (location: any | null) => void;
  locations?: any;
  addModal: boolean;
  setSelectedCharger?: (charger: ChargerDataDto | null) => void;
}

const Map = ({
  newLocation,
  setNewLocation,
  locations,
  setSelectedCharger,
  addModal,
}: Props) => {
  const defaultMapCenter: [number, number] = [47.498, 19.0881];

  const {} = useStore();

  useEffect(() => {
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 10);
  }, []);

  return (
    <MapContainer
      attributionControl={false}
      zoomControl={false}
      center={defaultMapCenter}
      zoom={11}
      markerZoomAnimation={true}
      dragging={true}
    >
      <TileLayer
        updateWhenZooming={false}
        url={MAP_URL}
        maxZoom={20}
        keepBuffer={3}
        maxNativeZoom={19}
      />
      <MarkerCluster
        locations={locations}
        setSelectedCharger={setSelectedCharger!}
      />
      <FitBounds locations={locations} />
      {addModal && newLocation && (
        <TempMarker newLocation={newLocation} setNewLocation={setNewLocation} />
      )}
    </MapContainer>
  );
};

export default Map;
