import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useEffect, useState } from "react";
import { ChargerInstructions } from "../../app/models/charger";

interface Props {
  ocppChargerId: string
}

const Instruction = ({ ocppChargerId }: Props) => {
  const { chargerStore } = useStore();
  const [chargerInstructions, setChargerInstructions] = useState<ChargerInstructions | undefined>(undefined);

  useEffect(() => {
    const getData = async () => {
      setChargerInstructions(await chargerStore.getChargerInstructions(ocppChargerId));
    };
    getData();
  }, [chargerStore, ocppChargerId]);

  return (
    <div
      className="w-[360px] absolute top-7 z-10 right-0 bg-white rounded-[5px] flex flex-col items-center justify-center p-5"
      style={{ boxShadow: "0px 14px 29.6px 0px rgba(0, 0, 0, 0.05)" }}
    >
      <p className="text-[14px] font-medium text-darkGreen mb-4">
        Onboard physical charger to Power Up backend using below instructions
        and parameters
      </p>
      <div className="flex flex-col gap-2 whitespace-nowrap w-full">
        <div className="flex items-center justify-between border-b-[1.5px] border-input-border py-2">
          <p className="text-[12px] text-darkGreem">OCPP proxy url</p>
          <p className="text-[12px] text-lightGray">
            {chargerInstructions?.ocppProxyUrl}
          </p>
        </div>
        <div className="flex items-center justify-between border-b-[1.5px] border-input-border py-2">
          <p className="text-[12px] text-darkGreem">WSS/WS</p>
          <p className="text-[12px] text-lightGray">{chargerInstructions?.encryption}</p>
        </div>
        <div className="flex items-center justify-between border-b-[1.5px] border-input-border py-2">
          <p className="text-[12px] text-darkGreem">Port</p>
          <p className="text-[12px] text-lightGray">{chargerInstructions?.port}</p>
        </div>
        <div className="flex items-center justify-between border-b-[1.5px] border-input-border py-2">
          <p className="text-[12px] text-darkGreem">Available protocols</p>
          <p className="text-[12px] text-lightGray">{chargerInstructions?.availableProtocols}</p>
        </div>
        <div className="flex items-center justify-between border-b-[1.5px] border-input-border py-2">
          <p className="text-[12px] text-darkGreem">Password</p>
          <p className="text-[12px] text-lightGray">{chargerInstructions?.password}</p>
        </div>
      </div>
    </div>
  );
};
export default Instruction;
