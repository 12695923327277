import { MapContainer, TileLayer } from "react-leaflet";
import FitBounds from "./FitBounds";
import { MAP_URL } from "../../utils/map/map";
import { useStore } from "../../app/stores/store";
import { useEffect } from "react";

const PreloadMap = () => {
  const mapCenter: [number, number] = [45.798, 15.9881];
  const { chargerStore } = useStore();

  useEffect(() => {
    chargerStore.getMapChargers();
  }, [chargerStore]);

  return (
    <MapContainer
      style={{ height: "0", width: "0", visibility: "hidden" }}
      center={mapCenter}
      attributionControl={false}
      zoomControl={false}
    >
      <TileLayer url={MAP_URL} maxZoom={20} />
      <FitBounds locations={chargerStore.mapChargers} />
    </MapContainer>
  );
};

export default PreloadMap;
