import React from "react";
import {
  formatDateLocale,
  formatDemandDateLocale,
} from "../../utils/time/formatDateLocale";
interface DateButtonProps {
  showDateModal: boolean;
  setShowDateModal: (show: boolean) => void;
  startDate: string | null;
  endDate: string | null;
  demandShift: boolean;
  handleMinusDay?: () => void;
  handlePlusDay?: () => void;
  dashboard?: true;
}

const DateButton: React.FC<DateButtonProps> = ({
  showDateModal,
  setShowDateModal,
  startDate,
  endDate,
  demandShift,
  dashboard,
  handleMinusDay,
  handlePlusDay,
}) => {
  const handleClick = () => {
    setShowDateModal(!showDateModal);
  };

  const isYesterday = (): boolean => {
    if (!startDate) return false;

    const date = new Date(startDate);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate());
    yesterday.setHours(0, 0, 0, 0);

    return date.setHours(0, 0, 0, 0) === yesterday.getTime();
  };

  const arrowRightDisabled = isYesterday();
  return (
    <button
      onClick={!demandShift ? handleClick : undefined}
      className={`relative rounded-[10px] border-2 h-[40px] p-[8px] w-[100%] text-[14px] font-medium ${showDateModal || (startDate && endDate)
          ? "border-darkGreen text-darkGreen"
          : "border-[#E6E6E6] text-[#ADADAD]"
        } flex flex-row gap-3 items-center`}
    >
      {startDate && endDate ? (
        <div className="flex justify-between items-baseline w-full">
          <div
            onClick={demandShift ? handleClick : undefined}
            className="flex items-center gap-1"
          >
            <img alt="" src="/assets/svg/calendar/calendarActive.svg" />
            {demandShift ? (
              <p className="text-[14px] font-medium text-darkGreen">
                {formatDemandDateLocale(startDate)}
              </p>
            ) : (
              <p className="text-[14px] font-medium text-darkGreen">
                {formatDateLocale(startDate)}-{formatDateLocale(endDate)}
              </p>
            )}
          </div>
          {demandShift ? (
            <div className="flex items-center">
              <img
                onClick={handleMinusDay}
                className="hover:bg-gray-100"
                src="/assets/svg/arrow/activeLeft.svg"
                alt=""
              />
              <img
                onClick={
                  dashboard
                    ? arrowRightDisabled
                      ? undefined
                      : handlePlusDay
                    : handlePlusDay
                }
                className={` ${dashboard
                    ? arrowRightDisabled
                      ? "opacity-50 cursor-default"
                      : "hover:bg-gray-100"
                    : "hover:bg-gray-100"
                  }`}
                src="/assets/svg/arrow/activeRight.svg"
                alt=""
              />
            </div>
          ) : (
            <img
              alt=""
              src={
                showDateModal
                  ? "/assets/svg/arrow/arrowCalendarUp.svg"
                  : "/assets/svg/arrow/arrowDown.svg"
              }
            />
          )}
        </div>
      ) : (
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center gap-2">
            <img
              src={
                showDateModal
                  ? "/assets/svg/calendar/calendarActive.svg"
                  : "/assets/svg/calendar/calendar.svg"
              }
              alt=""
            />
            Time Period
          </div>
          <img
            src={
              showDateModal
                ? "/assets/svg/arrow/arrowCalendarUp.svg"
                : "/assets/svg/arrow/arrowCalendarDown.svg"
            }
            alt=""
          />
        </div>
      )}
    </button>
  );
};

export default DateButton;
