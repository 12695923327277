import { useEffect, useState } from "react";
import ContentHeader from "../../../components/ContentHeader/ContentHeader";
import {
  adjustDate,
  convertUTCDateTimeToLocale,
  formatDemandShiftingTime,
} from "../../../utils/time/formatDateLocale";
import { useStore } from "../../../app/stores/store";
import PreloadMap from "../../../components/Map/PreloadMap";
import DateButton from "../../../components/DateButton/DateButton";
import { observer } from "mobx-react-lite";
import DashboardDemandChart from "./Charts/DashboardDemandChart";
import DashboardEnergy from "./Charts/DashboardEnergy";
import DemandDateSelect from "../../../components/Calendar/DemandDateSelect";
import { toJS } from "mobx";
import { roundOnFixedDecimals } from "../../../utils/numbers/numbersHelper";

type Calendar = {
  startDate: string | null;
  endDate: string | null;
};

const DashboardOverview = observer(() => {
  const { dashboardStore, chargerStore } = useStore();
  const { getEnergyComparison, energyComparison, demandShiftingUsage } =
    dashboardStore;
  const [showDateModal, setShowDateModal] = useState(false);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);
  const yesterdayLocal = yesterday
    .toLocaleString("sv-SE", { timeZoneName: "short" })
    .split(" ")[0]; // "sv-SE" gives YYYY-MM-DD format

  const [calendar, setCalendar] = useState<Calendar>({
    startDate: yesterdayLocal,
    endDate: yesterdayLocal,
  });

  const handleSelectedDate = (value: any) => {
    const start = new Date(value);
    start.setHours(0, 0, 0, 0);
    setCalendar({ startDate: start.toISOString(), endDate: start.toISOString() });
  };

  const formatPriceData = (value: any) => {
    return value.map(
      (item: { dateHour: string | number | Date; value: any }) => {
        const utcDate = new Date(item.dateHour);
        const localTimeString = utcDate.toLocaleString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
        return {
          dateHour: localTimeString,
          value: item.value,
        };
      }
    );
  };

  const formatDemandData = (demandArray: any) => {
    // Create an array to hold the formatted data
    const formattedData: Array<{
      date: string;
      totalDemandShiftingTime: number;
      totalSessionTime: number;
    }> = [];

    // Create a map for easier access to demand data by hour
    const demandMap = new Map<
      string,
      { totalDemandShiftingTime: number; totalSessionTime: number }
    >();

    // Populate the demandMap with existing data
    demandArray.forEach(
      (item: {
        date: string;
        totalDemandShiftingTime: number;
        totalSessionTime: number;
      }) => {
        const utcDate = new Date(item.date);
        const hour = utcDate.getHours(); // Get the hour
        const hourString = hour.toString().padStart(2, "0") + ":00"; // Format hour to "HH:00"

        // Store totalDemandShiftingTime and totalSessionTime in the map
        demandMap.set(hourString, {
          totalDemandShiftingTime: item.totalDemandShiftingTime,
          totalSessionTime: item.totalSessionTime,
        });
      }
    );

    // Iterate through each hour of the day from 00:00 to 23:00
    for (let h = 0; h < 24; h++) {
      const hourString = h.toString().padStart(2, "0") + ":00"; // Format hour to "HH:00"

      // Check if data exists for this hour
      const demandData = demandMap.get(hourString);
      if (demandData) {
        formattedData.push({
          date: hourString,
          totalDemandShiftingTime: demandData.totalDemandShiftingTime,
          totalSessionTime: demandData.totalSessionTime,
        });
      } else {
        // If no data exists, push zeros
        formattedData.push({
          date: hourString,
          totalDemandShiftingTime: 0,
          totalSessionTime: 0,
        });
      }
    }

    return formattedData;
  };

  const formattedDemandShiftingChart = formatDemandData(
    demandShiftingUsage.pointDtos
  );
  const formattedPriceData = formatPriceData(energyComparison.price);

  useEffect(() => {
    getDemandShiftingUsageF();
    getComparisonF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar, getEnergyComparison]);


  const handleMinusDay = () => {
    if (calendar.startDate) {
      setCalendar({
        startDate: adjustDate(calendar.startDate, -1),
        endDate: adjustDate(calendar.startDate, -1),
      });
    }
  };

  const getDemandShiftingUsageF = async () => {
    if (calendar.startDate && calendar.endDate) {
      // generateRandomDataForDate(calendar.startDate);
      const start = new Date(calendar.startDate);
      start.setHours(0, 0, 0, 0);
      const end = new Date(calendar.endDate);
      end.setHours(23, 0, 0, 0);
      await dashboardStore.getDemandShiftingUsage(
        start.toISOString(),
        end.toISOString()
      );
    }
  };

  const getComparisonF = async () => {
    if (calendar.startDate && calendar.endDate) {
      // generateRandomDataForDate(calendar.startDate);
      const start = new Date(calendar.startDate);
      start.setHours(0, 0, 0, 0);
      const end = new Date(calendar.endDate);
      end.setHours(23, 0, 0, 0);
      await dashboardStore.getEnergyComparison(
        start.toISOString(),
        end.toISOString()
      );
    }
  };

  const handlePlusDay = () => {
    if (calendar.startDate) {
      setCalendar({
        startDate: adjustDate(calendar.startDate, 1),
        endDate: adjustDate(calendar.startDate, 1),
      });
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4 items-stretch h-full custom-scrollbar">
        <ContentHeader group="Dashboard" subgroup="Overview">
          <div className="flex gap-5 items-center">
            <p className="text-[16px] font-semibold text-darkGreen whitespace-nowrap">
              Select Date
            </p>
            <div className="relative w-[240px]">
              <DateButton
                showDateModal={showDateModal}
                setShowDateModal={setShowDateModal}
                startDate={calendar.startDate ? convertUTCDateTimeToLocale(calendar.startDate) : null}
                endDate={calendar.endDate ? convertUTCDateTimeToLocale(calendar.endDate) : null}
                demandShift={true}
                handleMinusDay={handleMinusDay}
                handlePlusDay={handlePlusDay}
                dashboard
              />

              {showDateModal && (
                <DemandDateSelect
                  showCalendar={setShowDateModal}
                  start={calendar.startDate}
                  end={calendar.endDate}
                  setSelected={handleSelectedDate}
                  demandShift={true}
                  dashboard
                />
              )}
            </div>
          </div>
        </ContentHeader>
        <div className="flex-1 flex flex-col h-full w-full last:gap-0">
          <div className="bg-white rounded-[10px] pt-5 pb-3 px-6 items-start w-full">
            <DashboardEnergy
              xValues={formattedPriceData.map((x: any) => x.dateHour)}
              yRight={energyComparison.price.map((x) => x.value)}
              yLeft1={energyComparison.realEnergyConsumed.map((x) => x.value)}
              yLeft2={energyComparison.estimatedEnergyConsumed.map(
                (x) => x.value
              )}
            />
          </div>
          <div className="bg-white rounded-t-[10px] pt-5 pb-3 px-6 mt-3 ">
            <DashboardDemandChart
              xValues={formattedDemandShiftingChart.map((x: any) => x.date)}
              yValues={formattedDemandShiftingChart.map(
                (x) => x.totalSessionTime ? Number(roundOnFixedDecimals((x.totalDemandShiftingTime / x.totalSessionTime * 100), 1)) : 0
              )}
              y1Values={formattedDemandShiftingChart.map(
                (x) => 100
              )}
            />
          </div>
        </div>
      </div>
      <PreloadMap />
    </>
  );
});

export default DashboardOverview;
