import { useEffect, useState } from "react";
import Modal from "../../../../../components/Modal/Modal";
import TextInput from "../../../../../components/TextInput/TextInput";
import AddressInput from "../../../../../components/AddressInput/AddressInput";
import MyMap from "../../../../../components/Map/MyMap";
import { fetchCoordinates } from "../../../../../utils/map/map";
import { StringMappingType } from "typescript";
import { parseAddress } from "../../../../../utils/address/address";
import { useStore } from "../../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Slider } from "@nextui-org/slider";
import { CreateChargerDto } from "../../../../../app/models/charger";

interface Props {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  setConfirm: (newCharger: CreateChargerDto) => void;
}

const AddLocationModal = observer(
  ({ modalOpen, setModalOpen, setConfirm }: Props) => {
    const { commonStore } = useStore();
    const { countriesForDropdown } = commonStore;
    const [isActive, setIsActive] = useState<boolean>(false);
    const [newLocation, setNewLocation] = useState({
      chargerIdentificator: "",
      address: "",
      longitude_latitude: "",
      maxCurrent: 16,
      numOfPhases: 1,
    });

    const handleConnect = () => {
      const [longitude, latitude] = newLocation.longitude_latitude.split(",");
      const { street, postalNumber, city } = parseAddress(newLocation.address);

      if (isActive) {
        const newCharger = {
          chargerIdentificator: newLocation.chargerIdentificator,
          password: "Password123",
          longitude: Number(longitude),
          latitude: Number(latitude),
          street: street,
          city: city,
          postalNumber: postalNumber,
          numberOfPhases: newLocation.numOfPhases,
          maxCurrent: newLocation.maxCurrent,
          countryId: countriesForDropdown ? countriesForDropdown[0].id : 1,
        };
        setConfirm(newCharger);
      }
      setModalOpen(false);
    };

    const handleAddressChange = async (value: string) => {
      setNewLocation((prev) => ({
        ...prev,
        address: value,
      }));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setNewLocation((prevInfo: any) => ({
        ...prevInfo,
        [name]: value,
      }));
    };

    useEffect(() => {
      const isValid =
        Boolean(newLocation.chargerIdentificator !== "" &&
          newLocation.address.trim() !== "" &&
          newLocation.longitude_latitude.trim() !== "" &&
          newLocation.numOfPhases && newLocation.maxCurrent);

      setIsActive(isValid);
    }, [newLocation]);

    useEffect(() => {
      if (!modalOpen) {
        setNewLocation({
          chargerIdentificator: "",
          address: "",
          longitude_latitude: "",
          maxCurrent: 16,
          numOfPhases: 1,
        });
      }
    }, [modalOpen]);

    return (
      <Modal
        isVisible={modalOpen}
        onClose={() => setModalOpen(false)}
        modalWidth="w-[740px]"
      >
        <div className="flex flex-col gap-5 px-8 py-8 flex-grow h-[91vh] overflow-auto">
          <p className="text-[20px] font-semibold text-darkGreen mb-3">
            Add new charger
          </p>
          <div className="flex-1 flex flex-col gap-5 ">
            <div className="flex gap-5">
              <div className="flex flex-col gap-2 flex-1">
                <label className="text-[14px] font-bold text-darkGreen">
                  Charger ID
                </label>
                <TextInput
                  placeholder="e.g. TAC00000000"
                  value={newLocation.chargerIdentificator}
                  name="chargerIdentificator"
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col gap-2 flex-1">
                <label className="text-[14px] font-bold text-darkGreen">
                  Address
                </label>
                <AddressInput
                  value={newLocation.address || ""}
                  setValue={handleAddressChange}
                  placeholder="Charger Address"
                  setCoordinates={(coordinates) => {
                    if (coordinates) {
                      const longitude_latitude = `${coordinates.lon.toFixed(
                        5
                      )}, ${coordinates.lat.toFixed(5)}`;
                      setNewLocation((prev) => ({
                        ...prev,
                        longitude_latitude,
                      }));
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex gap-5">
              <div className="flex flex-col gap-2 flex-1">
                <label className="text-[14px] font-bold text-darkGreen">
                  Longitude & Latitude
                </label>
                <TextInput
                  placeholder="Longitude & Latitude"
                  value={`(${newLocation.longitude_latitude})`}
                  name="longitude_latitude"
                  readonly={true}
                />
              </div>
              <div className="flex flex-col gap-2 flex-1">
                <label className="text-[14px] font-bold text-darkGreen">
                  Charger Max Current
                </label>
                <Slider
                  step={1}
                  color="success"
                  maxValue={32}
                  minValue={6}
                  value={newLocation.maxCurrent}
                  aria-label="Max Current"
                  label="Amperes"
                  onChange={(value) => setNewLocation((prevInfo: any) => ({
                    ...prevInfo,
                    maxCurrent: value
                  }))}
                  classNames={{
                    base: "max-w-md",
                    filler: "bg-gradient-to-r from-green-500 to-light-green",
                    labelWrapper: "mb-2",
                    label: "text-default-700 text-medium",
                    thumb: [
                      "transition-size",
                      "bg-gradient-to-r from-green-500 to-light-green",
                      "data-[dragging=true]:shadow-lg data-[dragging=true]:shadow-black/20",
                      "data-[dragging=true]:w-7 data-[dragging=true]:h-7 data-[dragging=true]:after:h-6 data-[dragging=true]:after:w-6"
                    ],
                    step: "data-[in-range=true]:bg-black/30 dark:data-[in-range=true]:bg-white/50",
                  }}
                />
              </div>
            </div>
            <div className="flex gap-5">
              <div className="flex flex-col gap-2 flex-1">
                <Slider
                  color="success"
                  step={1}
                  maxValue={3}
                  minValue={1}
                  value={newLocation.numOfPhases}
                  label="Number of Phases"
                  showSteps={true}
                  classNames={{
                    base: "max-w-md",
                    filler: "bg-gradient-to-r from-green-500 to-light-green",
                    labelWrapper: "mb-2",
                    label: "text-[14px] font-bold text-darkGreen mb-3",
                    thumb: [
                      "transition-size",
                      "bg-gradient-to-r from-green-500 to-light-green",
                      "data-[dragging=true]:shadow-lg data-[dragging=true]:shadow-black/20",
                      "data-[dragging=true]:w-7 data-[dragging=true]:h-7 data-[dragging=true]:after:h-6 data-[dragging=true]:after:w-6"
                    ],
                    step: "data-[in-range=true]:bg-black/30 dark:data-[in-range=true]:bg-white/50",
                  }}
                  onChange={(value) => setNewLocation((prevInfo: any) => ({
                    ...prevInfo,
                    numOfPhases: value
                  }))}
                />
              </div>
              <div className="flex flex-1"></div>
              <div className="flex flex-1 items-end">
                <button
                  onClick={handleConnect}
                  disabled={!isActive}
                  className={`flex h-[51px] flex-1 py-[15px] justify-center text-[16px] font-semibold items-center gap-[10px] rounded-[10px] ${isActive
                    ? "bg-light-green text-white"
                    : "bg-gray-100 text-[#515151]"
                    }`}
                >
                  Connect
                </button>
              </div>
            </div>

            <div className="flex-1 overflow-hidden h-full">
              <MyMap
                newLocation={newLocation}
                addModal={true}
                setNewLocation={setNewLocation}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);
export default AddLocationModal;
