import { transliterate } from "transliteration";

export const fetchAddressSuggestions = async (
  query: string,
  accessToken: string
) => {
  if (query.length <= 3) return [];

  try {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${accessToken}&autocomplete=true&proximity=19.04,47.50&bbox=16.11,45.74,22.57,48.11`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    const options = data.features.slice(0, 3).map((feature: any) => {
      const label = transliterate(feature.place_name ?? "");

      return {
        value: feature.place_name,
        label: label,
      };
    });

    return options;
  } catch (error) {
    console.error("Fetching suggestions failed:", error);
    return [];
  }
};

export const parseAddress = (address: string) => {
  const postalRegex = /^\d{4,5}$/;
  const streetRegex = /^[A-Za-z\s]+\d*/;
  const country = "Hungary";

  const cleanAddress = address
    .replace(new RegExp(`\\b${country}\\b`, "i"), "")
    .trim();

  const addressComponents = cleanAddress
    .split(",")
    .map((part) => part.trim())
    .filter(Boolean);

  let street = "";
  let postalNumber = "";
  let city = "";

  addressComponents.forEach((component, index) => {
    const cleanComponent = transliterate(component);

    if (index === 0) {
      city = cleanComponent;
    } else if (postalRegex.test(cleanComponent)) {
      postalNumber = cleanComponent;
    } else if (streetRegex.test(cleanComponent)) {
      street = cleanComponent;
    }
  });

  if (!street && addressComponents.length >= 2) {
    street = transliterate(addressComponents[1]);
  }

  return { street, postalNumber, city };
};
