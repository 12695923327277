import L from "leaflet";
import { useEffect, useRef, useState } from "react";
import { useMap } from "react-leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { ILocation } from "../../app/models/location";
import {
  CustomMarker,
  CustomMarkerOptions,
} from "../../utils/map/customMarker";
import { CustomMarkerIcon } from "../../utils/map/map";
import { ChargerDataDto, ChargerDataRaw } from "../../app/models/charger";
import ChargerModal from "../../pages/Dashboard/Network/NetworkOverview/ChargerModal";

interface Props {
  locations?: ChargerDataRaw[];
  setSelectedCharger: (charger: ChargerDataDto | null) => void;
  //   newLocation: ILocation;
}

const MarkerCluster: React.FC<Props> = ({ locations, setSelectedCharger }) => {
  const map = useMap();
  const [selectedMarker, setSelectedMarker] = useState<any>(null);
  const [markerLocations, setMarkerLocations] = useState<ILocation[]>([]);
  const [zoomLevel, setZoomLevel] = useState(map.getZoom());

  const markersRef = useRef<{ [key: number]: L.Marker }>({});

  const mcg = useRef(
    L.markerClusterGroup({
      iconCreateFunction: (cluster: any) => {
        const sum = cluster
          .getAllChildMarkers()
          .reduce((acc: any, marker: any) => {
            return (
              acc +
              (marker.options as CustomMarkerOptions).numberOfConnectors! || 0
            );
          }, 0);

        return CustomMarkerIcon(sum, zoomLevel);
      },
    })
  ).current;

  useEffect(() => {
    const handleZoom = () => {
      setZoomLevel(map.getZoom());
    };

    map.on("zoom", handleZoom);

    return () => {
      map.off("zoom", handleZoom);
    };
  }, [map]);

  useEffect(() => {
    const fetchLocations = async () => {
      //   const locations = await GetLocations();
      if (locations) {
        setMarkerLocations(locations);
      }
    };

    fetchLocations();
  }, [locations]);

  // const deleteMarker = (locationId: number) => {
  //   const marker = markersRef.current[locationId];

  //   if (marker) {
  //     mcg.removeLayer(marker);
  //     map.removeLayer(marker);
  //   }

  //   if (locations) {
  //     const updatedLocations = locations.filter(
  //       (location) => location.id !== locationId
  //     );
  //     setMarkerLocations(updatedLocations);
  //   }
  // };

  useEffect(() => {
    map.invalidateSize(false);
    mcg.clearLayers();

    if (locations && locations.length > 0) {
      locations.forEach((location: ILocation) => {
        const chargerData: ChargerDataDto = {
          id: location.id,
          ocppChargerId: location.ocppChargerId,
          numberOfConnectors: location.numberOfConnectors,
          longitude_latitude: `${location.longitude.toFixed(5)},${location.latitude.toFixed(5)}`,
          chargerInformation: location.chargerInformation || "",
          street: location.street || "",
          maxCurrent: location.maxCurrent,
          numberOfPhases: location.numberOfPhases
        };
        const marker = new CustomMarker(
          new L.LatLng(location.latitude, location.longitude),
          {
            icon: CustomMarkerIcon(location.numberOfConnectors, zoomLevel),
            numberOfConnectors: location.numberOfConnectors,
          }
        ).on("click", () => {
          setSelectedCharger(chargerData);
        });
        markersRef.current[location.id] = marker;
        mcg.addLayer(marker);
      });
    }
    map.addLayer(mcg);
  }, [map, setSelectedCharger, locations, mcg, zoomLevel]);

  // useEffect(() => {
  //   if (selectedMarker !== 0) {
  //     map.doubleClickZoom.disable();
  //     map.touchZoom.disable();
  //     map.boxZoom.disable();
  //     map.keyboard.disable();
  //   } else {
  //     map.dragging.enable();
  //     map.doubleClickZoom.enable();
  //     map.touchZoom.enable();
  //     map.boxZoom.enable();
  //     map.keyboard.enable();
  //   }
  // }, [selectedMarker, map]);

  return null;
};

export default MarkerCluster;
