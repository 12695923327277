import React, { useState, useRef, useEffect, ReactNode } from "react";

interface PopupProps {
  mainText: ReactNode;
  confirmText?: ReactNode;
  closeText?: ReactNode;
  confirmFunction: () => void;
  closeFunction: () => void;
  iconPositive?: boolean;
  demandShifting?: boolean;
}

const Popup = (props: PopupProps) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
        props.closeFunction();
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [props]);

  return (
    <>
      <div
        className="z-50 fixed top-0 left-0 w-full h-full"
        style={{
          background: "rgba(0, 0, 0, 0.60)",
          opacity: 0.3,
        }}
      />

      <div
        className="z-50 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-[440px] min-w-[400px] bg-white rounded-[10px] shadow-lg"
        ref={popupRef}
      >
        <div className="flex flex-col h-full">
          <div className="py-12 px-10 flex flex-col items-center justify-center gap-6">
            {/* Optional icon */}
            {/* Uncomment and replace paths if using icons */}
            {/* {props.iconPositive ? (
              <img src="/assets/svg/check/positive.svg" alt="Positive Icon" />
            ) : (
              <img src="/assets/svg/check/negative.svg" alt="Negative Icon" />
            )} */}

            <div className="text-center">{props.mainText}</div>
          </div>
          <div className="mt-auto">
            {props.confirmText ? (
              <div className="flex">
                <button
                  className={`flex-1 p-4 cursor-pointer text-center text-white font-medium text-[16px] leading-[20px] ${
                    props.demandShifting === true
                      ? "bg-[#00A991]"
                      : props.demandShifting === false
                      ? "bg-[#DA3333]"
                      : "bg-[#004C41]"
                  } ${
                    props.closeText ? "rounded-bl-[10px]" : "rounded-b-[10px]"
                  }`}
                  onClick={props.confirmFunction}
                >
                  {props.confirmText}
                </button>

                {props.closeText && (
                  <button
                    className="flex-1 p-4 cursor-pointer text-center bg-[#F7F7F7] text-[#515151] font-medium text-[16px] leading-[20px] rounded-br-[10px]"
                    onClick={props.closeFunction}
                  >
                    {props.closeText}
                  </button>
                )}
              </div>
            ) : (
              props.closeText && (
                <button
                  className="w-full p-4 cursor-pointer text-center bg-[#F7F7F7] text-[#515151] font-medium text-[16px] leading-[20px] rounded-b-[10px]"
                  onClick={props.closeFunction}
                >
                  {props.closeText}
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
