import { useState } from "react";
import { RemoteInstruction } from "./RemoteInstructions";

interface Props {
  selected: number | null;
  handleReset: () => void;
  handleEmergyencyReset: () => void;
  handleAvailability: () => void;
  handleUnlock: () => void;
}

const RemoteButtonGroup = ({
  selected,
  handleReset,
  handleEmergyencyReset,
  handleAvailability,
  handleUnlock,
}: Props) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div className="flex gap-2 justify-end items-center">
      <button
        disabled={!selected}
        onClick={handleReset}
        className={`w-[140px] h-[40px] px-1 flex gap-2 justify-center items-center text-[14px] font-medium border-2 rounded-10 ${selected
          ? "border-light-green bg-light-green text-white"
          : "text-lightGray border-lightGray"
          }`}
      >
        Reset
      </button>
      <button
        disabled={!selected}
        onClick={handleEmergyencyReset}
        className={`w-[140px] h-[40px] px-1 flex gap-2 justify-center items-center text-[14px] font-medium border-2 rounded-10 ${selected
          ? "border-light-green bg-light-green text-white"
          : "text-lightGray border-lightGray"
          }`}
      >
        Emergency Reset
      </button>
      <button
        disabled={!selected}
        onClick={handleAvailability}
        className={`w-[140px] h-[40px] px-1 flex gap-2 justify-center items-center text-[14px] font-medium border-2 rounded-10 ${selected
          ? "border-light-green bg-light-green text-white"
          : "text-lightGray border-lightGray"
          }`}
      >
        Change Availability
      </button>
      <button
        disabled={!selected}
        onClick={handleUnlock}
        className={`w-[140px] h-[40px] px-1 flex gap-2 justify-center items-center text-[14px] font-medium border-2 rounded-10 ${selected
          ? "border-light-green bg-light-green text-white"
          : "text-lightGray border-lightGray"
          }`}
      >
        Unlock
      </button>
      <div
        onMouseLeave={() => setShowInfo(false)}
        onMouseEnter={() => setShowInfo(true)}
        className="relative w-[40px] h-[40px] rounded-full bg-transparent border-2 border-[#676767] hover:border-light-green text-[#676767] hover:text-light-green text-[18px] font-bold flex items-center justify-center"
      >
        i{showInfo && <RemoteInstruction />}
      </div>
    </div>
  );
};

export default RemoteButtonGroup;
