import React, { useState, useEffect } from "react";

interface PagingProps {
  tableSize: number;
  pageChangeFunc: (page: number, perPage: number) => void;
}

const Paging = ({ tableSize, pageChangeFunc }: PagingProps) => {
  const [pagination, setPagination] = useState({ page: 1, perPage: 10 });
  const numOfPages = Math.ceil(tableSize / pagination.perPage) || 1;

  useEffect(() => {
    // Call the page change function whenever pagination changes
    pageChangeFunc(pagination.page, pagination.perPage);
  }, [pagination, pageChangeFunc]);

  const handlePageChange = (delta: number) => {
    setPagination((prev) => ({
      ...prev,
      page: Math.min(Math.max(prev.page + delta, 1), numOfPages),
    }));
  };

  const handlePerPageChange = (newPerPage: number) => {
    setPagination({ page: 1, perPage: newPerPage });
  };

  return (
    <div className="flex justify-between items-center p-5 bg-white shadow-[0_-1px_3px_rgba(0,0,0,0.05)]">
      <div className="flex items-center space-x-3">
        <span className="text-[14px] font-medium text-[#2D4317]">
          Items per page
        </span>
        {[10, 20, 50].map((size) => (
          <button
            key={size}
            className={`px-[6px] py-[2px] rounded text-[14px] font-medium ${
              pagination.perPage === size
                ? "bg-[#007F6D] text-white"
                : "bg-[#F7F7F7] text-[#2D4317]"
            }`}
            onClick={() => handlePerPageChange(size)}
          >
            {size}
          </button>
        ))}
      </div>
      <div className="flex">
        <button
          className={`py-[5px] rounded-tl-[5px] rounded-bl-[5px] ${
            pagination.page === 1
              ? "text-gray-400 bg-[#F7F7F7]"
              : "text-blue-500 bg-[#007F6D]"
          }`}
          onClick={() => handlePageChange(-1)}
          disabled={pagination.page === 1}
        >
          <img
            src={
              pagination.page === 1
                ? "/assets/svg/paging/arrowLeftEnd.svg"
                : "/assets/svg/paging/arrowLeft.svg"
            }
            alt="Previous"
          />
        </button>
        <span className="border-t border-b border-input-border py-[5px] px-3 text-[14px] font-medium text-[#2D4317]">
          {pagination.page} of {numOfPages}
        </span>
        <button
          className={`py-[5px] rounded-tr-[5px] rounded-br-[5px] ${
            pagination.page === numOfPages
              ? "text-gray-400 bg-[#F7F7F7]"
              : "text-blue-500 bg-[#007F6D]"
          }`}
          onClick={() => handlePageChange(1)}
          disabled={pagination.page === numOfPages}
        >
          <img
            src={
              pagination.page === numOfPages
                ? "/assets/svg/paging/arrowRightEnd.svg"
                : "/assets/svg/paging/arrowRight.svg"
            }
            alt="Next"
          />
        </button>
      </div>
    </div>
  );
};

export default Paging;
