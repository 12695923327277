export const SelectDateInstruction = () => {
  return (
    <div
      style={{ boxShadow: "0px 14px 29.6px 0px rgba(0, 0, 0, 0.05)" }}
      className="mx-auto w-[220px] absolute  top-10 right-0 py-2 px-1 rounded-[5px] bg-white flex items-center justify-center"
    >
      <div className="rounded-[5px] bg-[#F7F7F7] py-1 px-2">
        <p className="text-[10px] font-medium text-[#515151]">
          Select the date for which you want to see/edit max power curve
        </p>
      </div>
    </div>
  );
};

export const SelectPowerInstruction = () => {
  return (
    <div
      style={{ boxShadow: "0px 14px 29.6px 0px rgba(0, 0, 0, 0.05)" }}
      className="mx-auto w-[300px] absolute  top-10 left-0 py-2 px-1 rounded-[5px] bg-white flex items-center justify-center"
    >
      <div className="rounded-[5px] bg-[#F7F7F7] py-1 px-2">
        <p className="text-[10px] font-medium text-[#515151]">
          Each bar height represents % of max power user can get from its
          charger for specific hour in time
        </p>
      </div>
    </div>
  );
};
