import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useStore } from "../stores/store";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const { userStore } = useStore();
  const { logout } = userStore;
  const [selected, setSelected] = React.useState("");

  const handleSelected = (newSelected: any) => {
    setSelected(newSelected === selected ? "" : newSelected);
  };

  const handleLogout = () => {
    if (logout()) navigate("/auth/login");
  };

  return (
    <div className="h-screen w-full border-r-2 border-r-[#E6E6E6] bg-white shadow-md pt-7 pb-8 px-6 flex flex-col justify-between">
      <div className="flex flex-col gap-8 items-left">
        <div className="flex flex-col items-center justify-between gap-6">
          <div className="flex items-center gap-2 flex-1">
            <img
              width={50}
              height={40}
              src="//images.squarespace-cdn.com/content/v1/65def2dbfe0b3f13ad579ae1/3941bdf0-980a-42d2-9d60-f77e335462a0/EnergAI+logo+with+border.png?format=1500w"
              alt="logo"
            />
            <p className="audiowide-regular text-[36px] text-darkGreen p-0 m-0">
              EnergAI
            </p>
          </div>

          <hr className="w-full border-t-2 border-input-border" />
        </div>

        <Link
          to="/dashboard/overview"
          className="flex flex-row gap-2.5 relative items-left"
          onClick={() => handleSelected("")}
        >
          <img
            src={`/assets/svg/sidebar/dashboard${pathname === "/dashboard/overview" ? "Active" : ""
              }.svg`}
            alt="Dashboard"
          />

          <span
            className={`text-[18px] font-semibold sidebar-main-text ${pathname === "/dashboard/overview"
              ? "text-light-green"
              : "text-darkGreen"
              }`}
          >
            Dashboard
          </span>
        </Link>

        {/* SESSIONS */}
        <div className="flex flex-col gap-2">
          <div
            className="flex flex-row justify-between items-center relative cursor-pointer"
            onClick={() => handleSelected("Sessions")}
          >
            <div className="flex items-center gap-2.5">
              <img
                src={`/assets/svg/sidebar/sessions${pathname.startsWith("/dashboard/sessions/") ? "Active" : ""
                  }.svg`}
                alt="sessions"
              />{" "}
              <span
                className={`text-[18px] font-semibold ${pathname.startsWith("/dashboard/sessions/")
                  ? "text-light-green"
                  : "text-darkGreen"
                  }`}
              >
                Sessions
              </span>
            </div>
            <img
              src={
                selected === "Sessions"
                  ? "/assets/svg/arrow/arrowUp.svg"
                  : "/assets/svg/arrow/arrowDown.svg"
              }
              alt="Arrow Icon"
            />
          </div>
          {selected === "Sessions" && (
            <>
              {[
                { path: "/dashboard/sessions/active", label: "Active" },
                { path: "/dashboard/sessions/history", label: "History" },
              ].map(({ path, label }) => (
                <Link
                  key={path}
                  to={path}
                  className={`flex flex-row gap-2 items-center py-[7px] ${pathname === path ? "rounded-[10px] bg-active-grayish" : ""
                    }`}
                >
                  <img
                    src={
                      pathname === path
                        ? "/assets/svg/dot/active.svg"
                        : "/assets/svg/dot/inactive.svg"
                    }
                    alt={`${label} Dot Icon`}
                    className="pl-[2rem]"
                  />
                  <div className="text-[12px] font-bold text-darkGreen">
                    {label}
                  </div>
                </Link>
              ))}
            </>
          )}
        </div>

        {/* NETWORK */}
        <div className="flex flex-col gap-2">
          <div
            className="flex flex-row justify-between items-center relative cursor-pointer"
            onClick={() => handleSelected("Network")}
          >
            <div className="flex items-center gap-2.5">
              <img
                src={`/assets/svg/sidebar/network${pathname.startsWith("/dashboard/network/") ? "Active" : ""
                  }.svg`}
                alt="sessions"
              />{" "}
              <span
                className={`text-[18px] font-semibold ${pathname.startsWith("/dashboard/network/")
                  ? "text-light-green"
                  : "text-darkGreen"
                  }`}
              >
                Network
              </span>
            </div>
            <img
              src={
                selected === "Network"
                  ? "/assets/svg/arrow/arrowUp.svg"
                  : "/assets/svg/arrow/arrowDown.svg"
              }
              alt="Arrow Icon"
            />
          </div>
          {selected === "Network" && (
            <>
              {[
                { path: "/dashboard/network/overview", label: "Overview" },
                {
                  path: "/dashboard/network/onboarding",
                  label: "Chargers onboarding",
                },
              ].map(({ path, label }) => (
                <Link
                  key={path}
                  to={path}
                  className={`flex flex-row gap-2 items-center py-[7px] ${pathname.startsWith(path) ? "rounded-[10px] bg-active-grayish" : ""
                    }`}
                >
                  <img
                    src={
                      pathname.startsWith(path)
                        ? "/assets/svg/dot/active.svg"
                        : "/assets/svg/dot/inactive.svg"
                    }
                    alt={`${label} Dot Icon`}
                    className="pl-[2rem]"
                  />
                  <div className="text-[12px] font-bold text-darkGreen">
                    {label}
                  </div>
                </Link>
              ))}
            </>
          )}
        </div>

        {/* OPERATIONS */}
        <div className="flex flex-col gap-2">
          <div
            className="flex flex-row justify-between items-center relative cursor-pointer"
            onClick={() => handleSelected("Operations")}
          >
            <div className="flex items-center gap-2.5">
              <img
                src={`/assets/svg/sidebar/operations${pathname.startsWith("/dashboard/operations/") ? "Active" : ""
                  }.svg`}
                alt="sessions"
              />{" "}
              <span
                className={`text-[18px] font-semibold ${pathname.startsWith("/dashboard/operations/")
                  ? "text-light-green"
                  : "text-darkGreen"
                  }`}
              >
                Operations
              </span>
            </div>
            <img
              src={
                selected === "Operations"
                  ? "/assets/svg/arrow/arrowUp.svg"
                  : "/assets/svg/arrow/arrowDown.svg"
              }
              alt="Arrow Icon"
            />
          </div>
          {selected === "Operations" && (
            <>
              {[
                {
                  path: "/dashboard/operations/shifting",
                  label: "Demand shifting",
                },
                {
                  path: "/dashboard/operations/remote",
                  label: "Remote management",
                },
              ].map(({ path, label }) => (
                <Link
                  key={path}
                  to={path}
                  className={`flex flex-row gap-2 items-center py-[7px] ${pathname === path ? "rounded-[10px] bg-active-grayish" : ""
                    }`}
                >
                  <img
                    src={
                      pathname === path
                        ? "/assets/svg/dot/active.svg"
                        : "/assets/svg/dot/inactive.svg"
                    }
                    alt={`${label} Dot Icon`}
                    className="pl-[2rem]"
                  />
                  <div className="text-[12px] font-bold text-darkGreen">
                    {label}
                  </div>
                </Link>
              ))}
            </>
          )}
        </div>
        {/* PROFILE */}
        <Link
          to="/dashboard/profile"
          className="flex flex-row gap-2.5 relative items-left"
          onClick={() => handleSelected("")}
        >
          <img
            src={`/assets/svg/sidebar/profile${pathname === "/dashboard/profile" ? "Active" : ""
              }.svg`}
            alt="Dashboard"
          />

          <span
            className={`text-[18px] font-semibold sidebar-main-text ${pathname === "/dashboard/profile"
              ? "text-light-green"
              : "text-darkGreen"
              }`}
          >
            Profile
          </span>
        </Link>

        <div>
          <hr className="w-full border-t-2 border-input-border" />
          <div className="flex flex-col gap-3 mt-[20px]">
            <p className="text-[18px] font-semibold text-darkGreen">Support Email</p>
            <a
              className="text-[16px] font-medium text-darkGreen whitespace-nowrap"
              href="https://mail.google.com/mail/?view=cm&fs=1&to=aron.sass@energ-ai.com&su=Operator%20portal%20question&body=Dear%20EnergAI%20support,%0A%0A"
              target="_blank"
              rel="noopener noreferrer"
            >
              aron.sass@energ-ai.com
            </a>
          </div>
        </div>
      </div>
      <div
        className="flex flex-row gap-4 bg-light-green rounded-[10px] items-center justify-center py-[14px] cursor-pointer"
        onClick={handleLogout}
      >
        <img src="/assets/svg/sidebar/signout.svg" alt="" />
        <p className="text-[18px] font-bold text-white">Sign Out</p>
      </div>
    </div>
  );
};

export default Sidebar;
