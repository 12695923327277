import { ChargerDataRaw } from "../../../app/models/charger";

export const dummyChargers = {
  data: [
    {
      id: 1,
      ocppChargerId: "CHG-12345",
      numberOfConnectors: 2,
      longitude: -122.4194,
      latitude: 37.7749,
      chargerInformation: "Fast charger, 120kW",
      street: "123 Main St, San Francisco, CA",
    },
    {
      id: 2,
      ocppChargerId: "CHG-67890",
      numberOfConnectors: 4,
      longitude: -73.935242,
      latitude: 40.73061,
      chargerInformation: "Standard charger, 50kW",
      street: "456 Broadway, New York, NY",
    },
    {
      id: 3,
      ocppChargerId: "CHG-54321",
      numberOfConnectors: 1,
      longitude: -0.1276,
      latitude: 51.5074,
      chargerInformation: "Fast charger, 150kW",
      street: "789 King St, London, UK",
    },
    {
      id: 4,
      ocppChargerId: "CHG-98765",
      numberOfConnectors: 3,
      longitude: 139.6917,
      latitude: 35.6895,
      chargerInformation: "Ultra-fast charger, 300kW",
      street: "101 Tokyo St, Tokyo, Japan",
    },
    {
      id: 5,
      ocppChargerId: "CHG-19283",
      numberOfConnectors: 2,
      longitude: 151.2093,
      latitude: -33.8688,
      chargerInformation: "Standard charger, 75kW",
      street: "555 Sydney Rd, Sydney, Australia",
    },
    {
      id: 6,
      ocppChargerId: "CHG-45678",
      numberOfConnectors: 2,
      longitude: 2.3522,
      latitude: 48.8566,
      chargerInformation: "Fast charger, 100kW",
      street: "200 Champs-Élysées, Paris, France",
    },
    {
      id: 7,
      ocppChargerId: "CHG-78901",
      numberOfConnectors: 3,
      longitude: 13.405,
      latitude: 52.52,
      chargerInformation: "Standard charger, 60kW",
      street: "150 Alexanderplatz, Berlin, Germany",
    },
    {
      id: 8,
      ocppChargerId: "CHG-23456",
      numberOfConnectors: 1,
      longitude: -58.3816,
      latitude: -34.6037,
      chargerInformation: "Ultra-fast charger, 200kW",
      street: "300 Avenida Corrientes, Buenos Aires, Argentina",
    },
  ],
  totalCount: 5,
};

export const networkOverviewColumns = [
  { header: "Charger ID", accessor: "ocppChargerId" },
  { header: "Connectors", accessor: "numberOfConnectors" },
  { header: "Address", accessor: "street" },
  { header: "Longitude & Latitude", accessor: "longitude_latitude" },
  { header: "Charger Information", accessor: "chargerInformation" },
];

export const networkOverviewColumnOrder = [
  "ocppChargerId",
  "numberOfConnectors",
  "street",
  "longitude_latitude",
  "chargerInformation",
];

export const connectorColumns = [
  { header: "Connector ID", accessor: "connectorName" },
  { header: "Connector Status", accessor: "connectorStatus" },
  { header: "Connector Type", accessor: "connectorType" },
  { header: "Electric Current", accessor: "electricCurrent" },
];

export const networkOnboardingColumns = [
  { header: "Charger ID", accessor: "ocppChargerId" },
  { header: "Latitude", accessor: "latitude" },
  { header: "Longitude", accessor: "longitude" },
  { header: "Address", accessor: "street" },
  { header: "Status", accessor: "onboardingStatus" },
  { header: "Instructions", accessor: "instructions" },
];

export const networkOnboardingColumnOrder = [
  "ocppChargerId",
  "latitude",
  "longitude",
  "street",
  "onboardingStatus",
  "instructions",
];

export const conenctorColumnOrder = [
  "connectorName",
  "connectorStatus",
  "connectorType",
  "electricCurrent",
];

// const combineLongitudeLatitude = (data: ChargerDataRaw[]) => {
//   return data.map((charger) => ({
//     ...charger,
//     longitude_latitude: `{${charger.longitude}, ${charger.latitude}}`, // Combine longitude & latitude into a new prop
//   }));
// };

// export const processedChargers = combineLongitudeLatitude(dummyChargers.data);
