import { useEffect, useState, useCallback } from "react";
import ContentHeader from "../../../components/ContentHeader/ContentHeader";
import Table from "../../../components/Table/Table";
import {
  sessionHistoryColumnOrder,
  sessionHistoryColumns,
  dummyDataSession,
} from "./sessionData";
import DateButton from "../../../components/DateButton/DateButton";
import DateSelect from "../../../components/Calendar/DateSelect";
import { Size, ThreeDots } from "../../../components/OptionDots/OptionDots";
import { downloadCSV } from "../../../utils/csv/downloadCsv";
import Paging from "../../../components/Paging/Paging";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";

import { CSVdownload } from "../../../components/CSVdownload/CSVdownload";
import MySpinner from "../../../components/Spinner/MySpinner";
import { convertUTCDateTimeToLocale } from "../../../utils/time/formatDateLocale";

type Calendar = {
  startDate: string | null;
  endDate: string | null;
};

const SessionHistory = observer(() => {
  const { sessionStore } = useStore();
  const [showDateModal, setShowDateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, perPage: 10 });
  const [csvPopup, setCsvPopup] = useState(false);

  const [selected, setSelected] = useState<number | null>(null);
  const [keyword, setKeyword] = useState<string>("");
  const [calendar, setCalendar] = useState<Calendar>({
    startDate: null,
    endDate: null,
  });
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending";
  } | null>({ key: "timeStart", direction: "descending" });

  const handlePageChange = useCallback(
    (newPage: number, newPerPage: number) => {
      setPagination({ page: newPage, perPage: newPerPage });
    },
    []
  );

  useEffect(() => {
    const sortingField = sortConfig?.key || "";
    const sortingDescending = sortConfig?.direction === "descending";

    sessionStore.getSessionsHistory(
      pagination.page,
      pagination.perPage,
      calendar.startDate || "",
      calendar.endDate || "",
      keyword,
      sortingField,
      sortingDescending
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar, keyword, pagination, sortConfig]);

  const handleDownloadCSV = async () => {
    if (selected) {
      setLoading(true);
      downloadCSV(await sessionStore.getMeterValues(selected), selected + "_session_logs");
      setLoading(false);
    } else
      downloadCSV(sessionStore.sessionsHistory, "SessionHistory");
  };

  const handleSelection = (newMarket: number) => {
    setSelected(selected === newMarket ? null : newMarket);
  };

  const onEnteringKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setKeyword(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  const handleSort = (
    config: { key: string; direction: "ascending" | "descending" } | null
  ) => {
    setSortConfig(config);
  };

  return (
    <div className="flex flex-col gap-4 items-stretch h-full">
      <ContentHeader group="Charging Sessions" subgroup="History">
        <div className="flex gap-5 items-center">
          <div className="flex h-10 gap-2 border-2 border-input-border py-2 px-2 rounded-10">
            <img src="/assets/svg/search/search.svg" alt="" />
            <input
              className="text-[14px] focus:outline-none"
              type="text"
              placeholder="Search"
              onChange={onEnteringKeyword}
            />
          </div>
          <div className="relative w-[235px]">
            <DateButton
              showDateModal={showDateModal}
              setShowDateModal={setShowDateModal}
              startDate={calendar.startDate ? convertUTCDateTimeToLocale(calendar.startDate) : null}
              endDate={calendar.endDate ? convertUTCDateTimeToLocale(calendar.endDate) : null}
              demandShift={false}
            />
            {showDateModal && (
              <div className="absolute fixed top-10 right-0">
                <DateSelect
                  showCalendar={setShowDateModal}
                  start={calendar.startDate}
                  end={calendar.endDate}
                  handleApply={(start: any, end: any) =>
                    setCalendar({ startDate: start, endDate: end })
                  }
                  demandShift={false}
                />
              </div>
            )}
          </div>
          <div className="relative cursor-pointer">
            <img
              src={
                csvPopup
                  ? "/assets/svg/dots/dotsActive.svg"
                  : "/assets/svg/dots/dots.svg"
              }
              alt=""
              className="size-9"
              onClick={() => setCsvPopup((prev) => !prev)}
            />
            {csvPopup && (
              <div
                style={{ boxShadow: "0px 14px 29.6px 0px rgba(0, 0, 0, 0.07)" }}
                className="absolute top-10 right-0"
              >
                <CSVdownload
                  handleDownloadCSV={handleDownloadCSV}
                />
              </div>
            )}
          </div>
        </div>
      </ContentHeader>
      <div className="flex flex-col flex-grow">
        <div className="flex-grow overflow-y-auto bg-white rounded-t-[10px] pt-8 pb-5 px-6">
          <Table
            data={sessionStore.sessionsHistory || []}
            // data={dummyDataSession}
            columns={sessionHistoryColumns}
            columnOrder={sessionHistoryColumnOrder}
            isSessionHistory={true}
            setSortConfig={handleSort}
            sortConfig={sortConfig}
            selected={selected!}
            handleSelection={handleSelection}
          />
        </div>
        <Paging
          tableSize={sessionStore.sessionHistoriesCount}
          // tableSize={dummyDataSession.length}
          pageChangeFunc={handlePageChange}
        />
      </div>
      {loading && <MySpinner />}
    </div>
  );
});

export default SessionHistory;
