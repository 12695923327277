export const operationRemoteColumns = [
  { header: "Charger ID", accessor: "ocppChargerId" },
  { header: "Connectors", accessor: "numberOfConnectors" },
  { header: "Longitude", accessor: "longitude" },
  { header: "Latitude", accessor: "latitude" },
  { header: "Address", accessor: "street" },
  { header: "Charger Information", accessor: "chargerInformation" },
];

export const operationRemoteColumnOrder = [
  "ocppChargerId",
  "numberOfConnectors",
  "longitude",
  "latitude",
  "street",
  "chargerInformation",
];

export const connectorRemoteColumns = [
  { header: "Connector ID", accessor: "connectorName" },
  { header: "Connector Status", accessor: "connectorStatus" },
  { header: "Connector Type", accessor: "connectorType" },
  { header: "Electric Current", accessor: "electricCurrent" },
];

export const conenctorRemoteColumnOrder = [
  "connectorName",
  "connectorStatus",
  "connectorType",
  "electricCurrent",
];
