import { useState } from "react";
import LoginLayout from "../../../app/layout/LoginLayout";
import Popup from "../../../components/Popup/Popup";
import ConfirmButton from "../../../components/ConfirmButton/ConfirmButton";
import { useStore } from "../../../app/stores/store";
import MySpinner from "../../../components/Spinner/MySpinner";

const ForgotPassword = () => {
  const { userStore } = useStore();
  const [correctEmail, setCorrectEmail] = useState(false);
  const [incorrectEmail, setIncorrectEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailSend = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    if (email) {
      const res: boolean = await userStore.forgotPassword(email);
      setLoading(false);
      res ? setCorrectEmail(true) : setIncorrectEmail(true);
    }
  };

  const correctEmailPopup = () => {
    return (
      <>
        <div className="flex flex-row justify-center items-center mb-3">
          <img src="/assets/svg/correctX/correct.svg" alt="" className="w-[81px] text-center" />
        </div>
        <div className="text-darkGreen text-center font-semibold text-[24px]">
          Check your e-mail
        </div>
        <div className="text-lightGray text-center font-normal text-[16px]">
          We have sent you an email to {email} with instructions how to reset your
          password
        </div>
      </>
    );
  };

  const incorrectEmailPopup = () => {
    return (
      <>
        <div className="flex flex-row justify-center items-center mb-3">
          <img src="/assets/svg/incorrectX/incorrect.svg" alt="" className="w-[81px] text-center" />
        </div>
        <div className="text-darkGreen text-center font-semibold text-[24px]">
          Incorrect e-mail address
        </div>
        <div className="text-lightGray text-center font-normal text-[16px]">
          Please try again
        </div>
      </>
    );
  };

  return (
    <LoginLayout>
      <div className="flex flex-col gap-8 items-center flex-1 self-center">
        <div className="flex flex-col items-center gap-3">
          <h1 className="text-darkGreen text-left font-bold text-[32px]">
            Forgot your password?
          </h1>
          <p className="text-lightGray text-center font-normal text-[16px] leading-[130.7%] mb-4 md:w-[36rem]">
            Enter the username associated with your account and we'll send an
            email with instructions to reset your password
          </p>
        </div>
        <form className="flex flex-col gap-10 md:w-[34rem]" onSubmit={handleEmailSend}>
          <div className="flex flex-col gap-1">
            <label
              htmlFor="email"
              className="text-[20px] font-normal text-darkGreen"
            >
              E-mail
            </label>
            <input
              name="email"
              required
              id="email"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className="text-gray-700 text-left font-normal text-lg outline-none py-2 px-2 border-b-2 border-input-border focus:border-input-focus w-full"
            />
          </div>
          <div className="flex w-[394px] flex-row mx-auto mt-[24px] justify-center items-center">
            <ConfirmButton
              weight="semibold"
              textSize="16"
              isActive={true}
              content="Submit"
            />
          </div>
        </form>
        {correctEmail && (
          <Popup
            mainText={correctEmailPopup()}
            closeFunction={() => setCorrectEmail(false)}
            confirmFunction={() => undefined}
            closeText="Close"
            iconPositive
          />
        )}
        {incorrectEmail && (
          <Popup
            mainText={incorrectEmailPopup()}
            closeFunction={() => setIncorrectEmail(false)}
            confirmFunction={() => undefined}
            closeText="Close"
          />
        )}
        {loading && <MySpinner />}
      </div>
    </LoginLayout>
  );
};

export default ForgotPassword;
