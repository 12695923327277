import React from "react";

interface Props {
  data: { connectorName: string; id: number }[];
  selected: number;
  handleSelection?: (id: number) => void;
}

const AvailabilityTable = ({ data, selected, handleSelection }: Props) => {
  return (
    <table className="w-full table-fixed border-collapse">
      <thead>
        <tr>
          <th
            className="w-[50px] bg-[rgba(0,59,51,0.05)]"
            style={{
              borderBottom: "1.5px solid rgba(0,59,51,0.10)",
              borderTop: "1.5px solid rgba(0,59,51,0.10)",
            }}
          ></th>
          <th className="text-[14px] font-bold text-darkGreen text-left py-3 px-2.5 bg-[rgba(0,59,51,0.05)] border-t border-b border-[1.5px] border-[rgba(0,59,51,0.10)]">
            Connector ID
          </th>
          <th className="w-[120px] bg-[rgba(0,59,51,0.05)] border-t border-b border-[1.5px] border-[rgba(0,59,51,0.10)]"></th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr
            key={row.id}
            onClick={() => handleSelection && handleSelection(row.id)}
            className={`${row.id === selected
              ? "bg-[#E6F6F4]"
              : "bg-[#FDFDFD] hover:bg-[#FBFBFB]"
              } ${handleSelection ? "cursor-pointer" : ""}`}
          >
            <td className="py-2 px-2.5">
              <label className="inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" />
                <div className="flex items-center justify-center w-[15px] h-[15px] rounded-full border border-darkGreen">
                  {row.id === selected && (
                    <div className="w-[11px] h-[11px] rounded-full bg-darkGreen"></div>
                  )}
                </div>
              </label>
            </td>
            <td
              style={{
                borderRight: "1.5px solid rgba(0,59,51,0.10)",
                borderLeft: "1.5px solid rgba(0,59,51,0.10)",
              }}
              className="py-2 px-2.5 text-[14px] font-medium text-darkGreen truncate text-left"
            >
              {row.connectorName}
            </td>
            <td className="w-[120px] py-2 px-2.5 bg-transparent "></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AvailabilityTable;
