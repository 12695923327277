import { makeAutoObservable, runInAction } from "mobx";
import {
  ChangeAdminPassword,
  Company,
  SetAdminPassword,
  User,
  UserLoginValues,
} from "../models/user";
import agent from "../api/agent";
import { store } from "./store";

export default class UserStore {
  user: User | null = null;
  company: Company | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoggedIn() {
    return !!this.user;
  }

  login = async (creds: UserLoginValues) => {
    const result = await agent.Account.login(creds);
    store.commonStore.setToken(result);
    creds.rememberMe && store.commonStore.setRememberMeJwt(result);
  };

  forgotPassword = async (email: string) => {
    try {
      await agent.Account.forgotPassword({ email: email });
      return true;
    } catch (error) {
      console.log("Error while sending forgot password email: ", error);
      return false;
    }
  };

  setNewPassword = async (data: SetAdminPassword) => {
    try {
      await agent.Account.setAdminPassword(data);
      return true;
    } catch (error) {
      console.log("Error while setting new password: ", error);
      return false;
    }
  };

  changePassword = async (data: ChangeAdminPassword) => {
    try {
      await agent.Account.changeAdminPassword(data);
    } catch (err) {
      console.log("Error while changing password: ", err);
    }
  };

  getAdminInfo = async () => {
    try {
      const user = await agent.Admin.userInfo();
      runInAction(() => (this.user = user));
    } catch (error) { }
  };

  getCompanyInfo = async () => {
    try {
      const company = await agent.Admin.companyInfo();
      runInAction(() => (this.company = company));
      return company as Company;
    } catch (error) {
      console.log("Error while fetching company info: ", error);
      return {} as Company;
    }
  };

  updateCompanyInfo = async (companyInfo: Company) => {
    try {
      await agent.Admin.updateCompanyInfo(companyInfo);
    } catch (err) {
      console.log("Error while updating company info: ", err);
    }
  }

  clearCompanyInfo = async () => {
    runInAction(() => (this.company = null));
  }

  logout = () => {
    store.commonStore.setToDefault();
    this.user = null;
    return true;
  };
}
