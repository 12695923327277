export const getJwtFromStorage = () => {
    try {
        let jwt = sessionStorage.getItem("jwt");
        if (jwt) return jwt;
        jwt = localStorage.getItem("jwt");
        if (jwt) {
            let jwtStringParsed = JSON.parse(jwt);
            if (!checkIsJwtExpired(jwtStringParsed.expiryDate)) {
                sessionStorage.setItem("jwt", jwtStringParsed.id);
                return jwtStringParsed.id;
            }
            localStorage.removeItem("jwt");
        }
        return null;
    } catch (e) {
        console.log("Error while getting jwt: " + e);
        return null;
    }
};

const checkIsJwtExpired = (time: number) => {
    let currentDate = new Date();
    if (currentDate.getTime() - time > 561600000) {
        return true;
    } else {
        return false;
    }
};