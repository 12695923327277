export const RemoteInstruction = () => {
  return (
    <div
      style={{ boxShadow: "0px 14px 29.6px 0px rgba(0, 0, 0, 0.05)" }}
      className="flex flex-col gap-2 mx-auto w-[300px] absolute top-11 right-0 py-2 px-3 rounded-[5px] bg-white flex items-center justify-center"
    >
      <div className="flex flex-col gap-1 flex-start">
        <p className="text-[10px] font-medium text-darkGreen">Reset</p>
        <div className="rounded-[5px] bg-[#F7F7F7] py-2 px-[6px]">
          <p className="text-[10px] font-medium text-[#ADADAD]">
            Attempts to safely stop any active charge session and other running
            commands, allowing the charger to recover from any issues you may be
            facing.
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-1 flex-start">
        <p className="text-[10px] font-medium text-darkGreen">
          Emergency reset
        </p>
        <div className="rounded-[5px] bg-[#F7F7F7] py-2 px-[6px]">
          <p className="text-[10px] font-medium text-[#ADADAD]">
            Aggressively stops any ongoing charge sessions before rebooting the
            charger's internal software.
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-1 flex-start">
        <p className="text-[10px] font-medium text-darkGreen">
          Change availability
        </p>
        <div className="rounded-[5px] bg-[#F7F7F7] py-2 px-[6px]">
          <p className="text-[10px] font-medium text-[#ADADAD]">
            Changes the connector status to ‘available’ or ‘unavailable,’ with
            the status updating after the vehicle finishes charging if it is
            currently connected.
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-1 flex-start">
        <p className="text-[10px] font-medium text-darkGreen">Unlock</p>
        <div className="rounded-[5px] bg-[#F7F7F7] py-2 px-[6px]">
          <p className="text-[10px] font-medium text-[#ADADAD]">
            When pressed, unlocks the connector plug connected to the charger.
          </p>
        </div>
      </div>
    </div>
  );
};
