import { useState } from "react";
import "react-calendar/dist/Calendar.css";
import "./DateSelect.css";
import Calendar from "react-calendar";
import CalendarButton from "./CalendarButton";
import { Value } from "react-calendar/dist/cjs/shared/types";

interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

interface Props {
  showCalendar: Function;
  start: string | null;
  end: string | null;
  handleApply: Function;
  demandShift?: boolean;
}

const DateSelect: React.FC<Props> = ({
  showCalendar,
  start,
  end,
  handleApply,
  demandShift,
}) => {
  const today = new Date();

  const initialDate = demandShift ? today : start ? new Date(start) : today;

  const [selectionRange, setSelectionRange] = useState<DateRange>({
    startDate: initialDate,
    endDate: end ? new Date(end) : today,
  });
  const [activeButton, setActiveButton] = useState<string>("Today");

  const handleApplyClickDemandShift = (date: any) => {
    handleApply(date.toISOString(), date.toISOString());
    showCalendar(false);
  };

  const handleDateChange = (
    value: Value,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (!value) {
      setSelectionRange({ startDate: null, endDate: null });
      return;
    }

    if (demandShift && !Array.isArray(value)) {
      const selectedDate = value as Date;

      selectedDate.setMinutes(0, 0, 0);
      setSelectionRange({ startDate: selectedDate, endDate: selectedDate });
      handleApplyClickDemandShift(selectedDate);
    } else if (!demandShift && Array.isArray(value)) {
      const [startDate, endDate] = value as [Value, Value];
      setSelectionRange({
        startDate: startDate as Date,
        endDate: endDate as Date,
      });
    }
  };

  const handleTodayClick = () => {
    const today = new Date();
    setSelectionRange({ startDate: today, endDate: today });
    setActiveButton("Today");
    if (demandShift) handleApplyClickDemandShift(today);
  };

  const handleTomorrowClick = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setSelectionRange({ startDate: tomorrow, endDate: tomorrow });
    setActiveButton("Tomorrow");
    if (demandShift) handleApplyClickDemandShift(tomorrow);
  };

  const handleYesterdayClick = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    setSelectionRange({ startDate: yesterday, endDate: yesterday });
    setActiveButton("Yesterday");
    if (demandShift) handleApplyClickDemandShift(yesterday);
  };
  const handleThisMonthClick = () => {
    const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const end = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    setSelectionRange({ startDate: start, endDate: end });
    setActiveButton("This month");
  };

  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    const { startDate, endDate } = selectionRange;

    if (view === "month" && startDate && endDate) {
      const startTime = new Date(startDate).setHours(0, 0, 0, 0);
      const endTime = new Date(endDate).setHours(0, 0, 0, 0);
      const currentTime = new Date(date).setHours(0, 0, 0, 0);

      if (currentTime >= startTime && currentTime <= endTime) {
        if (currentTime === startTime || currentTime === endTime) {
          return "react-calendar__tile--active";
        }
        return "react-calendar__tile--in-range";
      }
    }

    return null;
  };

  const handleResetClick = () => {
    setSelectionRange({ startDate: null, endDate: null });
    handleApply(null, null);
    setActiveButton("");
  };

  const handleApplyClick = () => {
    const { startDate, endDate } = selectionRange;
    if (startDate && endDate) {
      let formattedStartDate = startDate.toISOString();
      let formattedEndDate = endDate.toISOString();

      showCalendar(false);
      handleApply(formattedStartDate, formattedEndDate);
    } else if (startDate === null && endDate === null) {
      showCalendar(false);
    }
  };

  const buttons = [
    { label: "Today", onClick: handleTodayClick },
    { label: "Yesterday", onClick: handleYesterdayClick },
    { label: "This month", onClick: handleThisMonthClick },
  ];

  const buttonsDemandShifting = [
    { label: "Yesterday", onClick: handleYesterdayClick },
    { label: "Today", onClick: handleTodayClick },
    { label: "Tomorrow", onClick: handleTomorrowClick },
  ];

  return (
    <>
      <div className="fixed inset-0 z-40" onClick={() => showCalendar(false)} />
      <div
        className={`absolute right-0 shadow-calendar-shadow w-[340px] px-5 py-4 overflow-auto rounded-[5px] bg-white z-50 ${
          demandShift ? "top-10" : "top-2"
        }`}
        role="dialog"
        aria-modal="true"
      >
        <div>
          <Calendar
            locale="en-UK"
            className="calendar"
            tileClassName={tileClassName}
            next2Label={null}
            prev2Label={null}
            selectRange={!demandShift}
            showNeighboringMonth={false}
            onChange={handleDateChange}
            value={
              demandShift
                ? selectionRange.startDate
                : [selectionRange.startDate, selectionRange.endDate]
            }
          />
        </div>

        <div className="border-t-2 border-[#F7F7F7] W-[100%] my-[5px]" />
        <div className="flex justify-around items-center py-[10px]">
          {(demandShift ? buttonsDemandShifting : buttons).map(
            ({ label, onClick }) => (
              <CalendarButton
                key={label}
                label={label}
                isActive={activeButton === label}
                onClick={onClick}
              />
            )
          )}
        </div>
        <div className="border-t-2 border-[#F7F7F7] W-[100%] my-[5px]" />
        {!demandShift && (
          <div className="flex justify-end gap-2 items-center w-[100%] p-2">
            <button
              className="h-[38px] w-[90px] rounded-[10px] bg-[#F7F7F7] text-[#515151] text-[12px] font-medium"
              onClick={handleResetClick}
            >
              Reset
            </button>
            <button
              className="h-[38px] w-[90px] rounded-[10px] bg-light-green text-white text-[12px] font-medium"
              onClick={handleApplyClick}
            >
              Save
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default DateSelect;
