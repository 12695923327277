import { useEffect, useState } from "react";
import ContentHeader from "../../../../components/ContentHeader/ContentHeader";
import DateButton from "../../../../components/DateButton/DateButton";
import EnergyPercentageChart from "./EnergyPercentage";
import {
  adjustDate,
  convertUTCDateTimeToLocale,
  formatDemandShiftingTime,
} from "../../../../utils/time/formatDateLocale";
import DateSelect from "../../../../components/Calendar/DateSelect";
import {
  SelectDateInstruction,
  SelectPowerInstruction,
} from "./DemandShiftInstructions";
import { CSVdownload } from "../../../../components/CSVdownload/CSVdownload";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import {
  AddOrUpdateDemandShiftingDto,
  DemandShiftingHistory,
} from "../../../../app/models/demandShifting";
import Popup from "../../../../components/Popup/Popup";
import MySpinner from "../../../../components/Spinner/MySpinner";
import { downloadCSV } from "../../../../utils/csv/downloadCsv";
import DemandDateSelect from "../../../../components/Calendar/DemandDateSelect";

type Calendar = {
  startDate: string | null;
  endDate: string | null;
};

const DemandShifting = observer(() => {
  const { demandShiftingStore } = useStore();
  const today = new Date();
  today.setHours(today.getHours(), 0, 0, 0);
  const [calendar, setCalendar] = useState<Calendar>({
    startDate: today.toISOString(),
    endDate: today.toISOString(),
  });
  const [editedData, setEditedData] = useState<AddOrUpdateDemandShiftingDto[]>(
    []
  );

  const [showDateModal, setShowDateModal] = useState(false);
  const [confirmChangesPopup, setConfirmChangesPopup] = useState(false);
  const [optionsHovered, setOptionsHovered] = useState(false);
  const [showDateInstruction, setShowDateInstruction] = useState(false);
  const [showPowerInstruction, setShowPowerInstruction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const handleSelectedDate = (value: any) => {
    const start = new Date(value);
    start.setHours(0, 0, 0, 0);
    setCalendar({ startDate: start.toISOString(), endDate: start.toISOString() });
  };

  useEffect(() => {
    getDemandShiftingHistories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar]);

  const handleEdit = (xValue: string, newYValue: number) => {
    setEditedData((prev) => {
      let existing = prev.find((el) => el.timeOfTheDay === xValue);
      if (existing) {
        let idx = prev.indexOf(existing);
        prev.splice(idx, 1);
      }
      prev.push({ timeOfTheDay: xValue, relativeMaxPower: newYValue });
      return prev;
    });
    setIsEdited(true);
  };

  const handleMinusDay = () => {
    if (calendar.startDate) {
      setCalendar({
        startDate: adjustDate(calendar.startDate, -1),
        endDate: adjustDate(calendar.startDate, -1),
      });
    }
  };

  const handlePlusDay = () => {
    if (calendar.startDate) {
      setCalendar({
        startDate: adjustDate(calendar.startDate, 1),
        endDate: adjustDate(calendar.startDate, 1),
      });
    }
  };

  const getDemandShiftingHistories = async () => {
    if (calendar.startDate && calendar.endDate) {
      // generateRandomDataForDate(calendar.startDate);
      const start = new Date(calendar.startDate);
      start.setHours(0, 0, 0, 0);
      const end = new Date(calendar.endDate);
      end.setHours(23, 0, 0, 0);
      await demandShiftingStore.getDemandShiftingHistory(
        start.toISOString(),
        end.toISOString()
      );
    }
  };

  const filterAndFormatData = (startHour: number, endHour: number) => {
    const getAllXValues = () => {
      let returnArr = [];
      for (let h = startHour; h <= endHour; h++) {
        let curr = demandShiftingStore.demandShiftingHistory?.find(
          (x) => new Date(x.timeOfTheDay + "Z").getHours() === h
        );
        if (curr) {
          let currCopy: DemandShiftingHistory = { relativeMaxPower: curr.relativeMaxPower, timeOfTheDay: curr.timeOfTheDay };
          if (currCopy.relativeMaxPower === 0) currCopy.relativeMaxPower = 2;
          returnArr.push(currCopy);
        } else {
          if (calendar.startDate) {
            let date = new Date(calendar.startDate);
            date.setHours(h, 0, 0, 0);
            let newXVal: DemandShiftingHistory = {
              timeOfTheDay: date.toISOString().slice(0, -1),
              relativeMaxPower: 100,
            };
            returnArr.push(newXVal);
          }
        }
      }
      return returnArr;
    };
    const filtered = getAllXValues();

    return {
      xValues: filtered.map((x) => x.timeOfTheDay),
      xValuesFiltered: filtered.map((x) =>
        formatDemandShiftingTime(x.timeOfTheDay)
      ),
      yValues: filtered.map((x) => {
        let changedRelMaxPow = editedData.find(
          (edited) => (new Date(edited.timeOfTheDay)).getTime() === (new Date(x.timeOfTheDay)).getTime()
        );
        return changedRelMaxPow ? (changedRelMaxPow.relativeMaxPower === 0 ? 2 : changedRelMaxPow.relativeMaxPower) : x.relativeMaxPower;
      }),
    };
  };

  const firstChart = filterAndFormatData(0, 11);
  const secondChart = filterAndFormatData(12, 23);

  const handleDownloadCSV = async () => {
    downloadCSV(
      demandShiftingStore.demandShiftingHistory?.map((el) => ({
        datetime: el.timeOfTheDay,
        relativeMaxPowerPerc: el.relativeMaxPower,
      })),
      "DemandShiftingHistory"
    );
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    setConfirmChangesPopup(false);
    setIsEdited(false);
    await demandShiftingStore.addOrUpdateDemandShiftingSchedule(editedData);
    setEditedData([]);
    await getDemandShiftingHistories();
    setLoading(false);
  };

  const saveChangesPopupText = () => {
    return (
      <div className="text-darkGreen font-semibold text-[24px]">
        Are you sure you want to save the changes?
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4 items-stretch h-full custom-scrollbar">
      <ContentHeader group="Operations" subgroup="Demand Shifting">
        <button
          onClick={() => setConfirmChangesPopup(true)}
          disabled={!isEdited}
          className={`w-[185px] h-[40px] text-[14px] font-medium border-2 rounded-10 ${isEdited
            ? "border-light-green bg-light-green text-white"
            : "text-lightGray border-lightGray"
            }`}
        >
          Save changes
        </button>
      </ContentHeader>
      <div className="flex-1 flex flex-col gap-8 bg-white rounded-[10px] py-6 px-5">
        <div className="flex justify-between w-full border-b-2 border-input-border pb-8 pt-2">
          <div className="flex items-start gap-5">
            <div
              onMouseLeave={() => setShowPowerInstruction(false)}
              onMouseEnter={() => setShowPowerInstruction(true)}
              className="relative w-[30px] h-[30px] rounded-full bg-transparent border-2 border-[#676767] hover:border-light-green text-[#676767] hover:text-light-green text-[14px] font-bold flex items-center justify-center"
            >
              i{showPowerInstruction && <SelectPowerInstruction />}
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-[20px] font-semibold text-darkGreen">
                Max power curve
              </p>
              <p className="text-[14px] text-darkGreen">
                As % of total power available for each charger
              </p>
            </div>
          </div>
          <div className="flex gap-5 items-center">
            <p className="text-[16px] font-semibold text-darkGreen whitespace-nowrap">
              Select Date
            </p>
            <div className="relative w-[240px]">
              <DateButton
                showDateModal={showDateModal}
                setShowDateModal={setShowDateModal}
                startDate={calendar.startDate ? convertUTCDateTimeToLocale(calendar.startDate) : null}
                endDate={calendar.endDate ? convertUTCDateTimeToLocale(calendar.endDate) : null}
                demandShift={true}
                handleMinusDay={handleMinusDay}
                handlePlusDay={handlePlusDay}
              />

              {showDateModal && (
                <DemandDateSelect
                  showCalendar={setShowDateModal}
                  start={calendar.startDate}
                  end={calendar.endDate}
                  setSelected={handleSelectedDate}
                  demandShift={true}
                />
              )}
            </div>
            <div className="flex gap-3.5 items-center">
              <div
                onMouseLeave={() => setShowDateInstruction(false)}
                onMouseEnter={() => setShowDateInstruction(true)}
                className="relative w-[30px] h-[30px] rounded-full bg-transparent border-2 border-[#676767] hover:border-light-green text-[#676767] hover:text-light-green text-[14px] font-bold flex items-center justify-center"
              >
                i{showDateInstruction && <SelectDateInstruction />}
              </div>

              <div
                onClick={() => setOptionsHovered((prev) => !prev)}
                className={`relative cursor-pointer w-[30px] h-[30px] rounded-full bg-transparent border-2 border-[#676767] text-[#676767] text-[14px] font-bold flex items-center justify-center ${optionsHovered ? "border-light-green" : ""
                  }`}
              >
                <img
                  alt=""
                  src={
                    optionsHovered
                      ? "/assets/svg/dots/dotsActive.svg"
                      : "/assets/svg/dots/grayDots.svg"
                  }
                />
                {optionsHovered && (
                  <div
                    style={{
                      boxShadow: "0px 14px 29.6px 0px rgba(0, 0, 0, 0.07)",
                    }}
                    className="absolute top-9 right-0"
                  >
                    <CSVdownload handleDownloadCSV={handleDownloadCSV} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-2 mb-2">
          <div className="flex text-[12px] text-[#515151]">
            <img alt="" src="/assets/svg/chart-dots/gray.svg" />
            Historical data (not editable)
          </div>
          <div className="flex text-[12px] text-[#515151]">
            <img alt="" src="/assets/svg/chart-dots/orange.svg" />
            Up to 24 hours in the future (editable)
          </div>
          <div className="flex text-[12px] text-[#515151]">
            <img alt="" src="/assets/svg/chart-dots/green.svg" />
            Beyond 24 hours in the future (editable)
          </div>
        </div>
        <div className="flex flex-col gap-20">
          <EnergyPercentageChart
            xValues={firstChart.xValues}
            xValuesFiltered={firstChart.xValuesFiltered}
            yValues={firstChart.yValues}
            onEdit={handleEdit}
          />
          <EnergyPercentageChart
            xValues={secondChart.xValues}
            xValuesFiltered={secondChart.xValuesFiltered}
            yValues={secondChart.yValues}
            onEdit={handleEdit}
          />
        </div>
      </div>
      {confirmChangesPopup && (
        <Popup
          mainText={saveChangesPopupText()}
          confirmText="Confirm"
          closeText="Close"
          confirmFunction={handleSaveChanges}
          closeFunction={() => setConfirmChangesPopup(false)}
        />
      )}
      {loading && <MySpinner />}
    </div>
  );
});

export default DemandShifting;
