import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoginLayout from "../../../app/layout/LoginLayout";
import { useStore } from "../../../app/stores/store";
import { SetAdminPassword } from "../../../app/models/user";

const ResetPassword = () => {
  const { userStore } = useStore();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmedPassword, setVisibleConfirmedPassword] = useState(false);
  const [equalPasswords, setEqualPasswords] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [userId, setUserId] = useState(0);
  const [activationCode, setActivationCode] = useState("");
  const [name, setName] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userIdParam = Number(searchParams.get("userId"));
    const activationCodeParam = searchParams.get("activationCode");
    const nameParam = searchParams.get("name");
    if (userIdParam && activationCodeParam && nameParam) {
      setUserId(userIdParam);
      setActivationCode(activationCodeParam);
      setName(nameParam);
    } else {
      navigate("/auth/login");
    }
  }, [location.search, navigate]);

  const handlePasswordInput = (name: any, event: any) => {
    const value = event.target.value;
    if (name === "password1") {
      setPassword1(value);
    } else if (name === "password2") {
      setPassword2(value);
    }
  };

  useEffect(() => {
    if (
      password1 === password2 &&
      password1.length >= 8 &&
      /[A-Z]/.test(password1) &&
      /[a-z]/.test(password1)
    ) {
      setEqualPasswords(true);
    } else {
      setEqualPasswords(false);
    }
  }, [password1, password2]);

  const handleConfirm = async (event: any) => {
    event.preventDefault();
    if (equalPasswords) {
      const data: SetAdminPassword = {
        userId: userId,
        activationCode: activationCode,
        newPassword: password1,
        newPasswordRepeated: password2,
      };
      const res = await userStore.setNewPassword(data);
      navigate("/auth/login");
    }
  };

  return (
    <LoginLayout>
      <div className="flex flex-col gap-12 items-center flex-1 self-center w-[30rem]">
        <div className="flex flex-col items-center">
          <h1 className="text-darkGreen text-left font-bold text-[32px]">Welcome {name}</h1>
          <p className="text-lightGray text-center font-normal text-[16px]">
            Please type your new password
          </p>
        </div>
        <form className="flex flex-col gap-12 md:w-[30rem]" onSubmit={handleConfirm}>
          <div className="flex flex-col gap-1">
            <label htmlFor="newPassword" className="text-darkGreen text-left font-normal text-[20px]">
              New Password
            </label>
            <div className="relative">
              <input
                name="newPassword"
                required
                id="newPassword"
                type={visiblePassword ? "text" : "password"}
                className="w-full text-lightGray text-left font-normal text-[20px] outline-none py-[10px] px-[8px] border-b-2 border-footer-paging-border-color focus:border-input-focus"
                value={password1}
                onChange={(e) => handlePasswordInput("password1", e)}
              />
              <img
                src={
                  visiblePassword
                    ? "/assets/svg/eye/eye-open.svg"
                    : "/assets/svg/eye/eye-closed.svg"
                }
                alt=""
                className="w-[20px] absolute right-[10px] top-[15px] cursor-pointer"
                onClick={() => setVisiblePassword(!visiblePassword)}
              />
            </div>
            <p className="text-lightGray text-left font-normal text-[12px]">
              Password must be 8-16 characters long, and contain one uppercase
              and one lowercase character
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="confirmedPassword" className="text-darkGreen text-left font-normal text-[20px]">
              Confirm Password
            </label>
            <div className="relative">
              <input
                name="confirmedPassword"
                required
                id="confirmedPassword"
                type={visibleConfirmedPassword ? "text" : "password"}
                className="w-full text-lightGray text-left font-normal text-[20px] outline-none py-[10px] px-[8px] border-b-2 border-footer-paging-border-color focus:border-input-focus"
                value={password2}
                onChange={(e) => handlePasswordInput("password2", e)}
              />
              <img
                src={
                  visibleConfirmedPassword
                    ? "/assets/svg/eye/eye-open.svg"
                    : "/assets/svg/eye/eye-closed.svg"
                }
                alt=""
                className="w-[20px] absolute right-[10px] top-[15px] cursor-pointer"
                onClick={() => setVisibleConfirmedPassword(!visibleConfirmedPassword)}
              />
            </div>
            <p className="text-lightGray text-left font-normal text-[12px]">
              Passwords must match
            </p>
          </div>
          <div className="flex flex-row justify-center items-center">
            <button
              type="submit"
              className={`${equalPasswords
                ? "bg-[#008774] text-white cursor-pointer"
                : "bg-[#E6E6E6] text-[#515151] cursor-default border border-table-hovered-row"
                } rounded-[10px] py-[15px] px-[140px] w-[394px] h-[56px] text-[18px] font-semibold mt-4`}
              disabled={!equalPasswords}
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </LoginLayout>
  );
};

export default ResetPassword;
