import { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "./DemandDateSelect.css";
import Calendar from "react-calendar";
import CalendarButton from "./CalendarButton";
import { Value } from "react-calendar/dist/cjs/shared/types";

interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

interface Props {
  showCalendar: Function;
  start: string | null;
  end: string | null;
  setSelected: (value: any) => void;
  demandShift?: boolean;
  dashboard?: true;
}

const DemandDateSelect: React.FC<Props> = ({
  showCalendar,
  start,
  end,
  setSelected,
  demandShift,
  dashboard,
}) => {
  const yesterday = ((d) => new Date(d.setDate(d.getDate() - 1)))(new Date());

  const initialDate = demandShift
    ? yesterday
    : start
    ? new Date(start)
    : yesterday;

  const [selectionRange, setSelectionRange] = useState<DateRange>({
    startDate: initialDate,
    endDate: end ? new Date(end) : yesterday,
  });
  const [activeButton, setActiveButton] = useState<string>("");

  useEffect(() => {
    const now = new Date().setHours(0, 0, 0, 0);
    const startDate = start ? new Date(start).setHours(0, 0, 0, 0) : null;

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayTime = yesterday.setHours(0, 0, 0, 0);

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowTime = tomorrow.setHours(0, 0, 0, 0);

    if (startDate === now) {
      setActiveButton("Today");
    } else if (startDate === yesterdayTime) {
      setActiveButton("Yesterday");
    } else if (startDate === tomorrowTime) {
      setActiveButton("Tomorrow");
    }
  }, [start]);

  const handleDateChange = (value: Value) => {
    const selected = value as Date;
    showCalendar(false);
    setSelected(selected);
  };

  const handleTodayClick = () => {
    const today = new Date();
    setActiveButton("Today");
    setSelected(today);
    showCalendar(false);
  };

  const handleTomorrowClick = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    setActiveButton("Tomorrow");
    setSelected(tomorrow);
    showCalendar(false);
  };

  const handleYesterdayClick = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    setActiveButton("Yesterday");
    setSelected(yesterday);
    showCalendar(false);
  };

  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    const { startDate } = selectionRange;

    const today = new Date().setHours(0, 0, 0, 0);
    const currentTime = new Date(date).setHours(0, 0, 0, 0);
    const selectedTime = startDate
      ? new Date(startDate).setHours(0, 0, 0, 0)
      : null;

    if (view === "month" && selectedTime && currentTime === selectedTime) {
      return "react-calendar__tile--activee";
    }
    if (currentTime === today) {
      return "react-calendar__tile--today";
    }

    return null;
  };
  const tileDisabled = ({ date, view }: { date: Date; view: string }) => {
    if (dashboard && view === "month") {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return date > yesterday;
    }
    return false;
  };

  const buttonsDemandShifting = [
    { label: "Yesterday", onClick: handleYesterdayClick },
    { label: "Today", onClick: handleTodayClick },
    { label: "Tomorrow", onClick: handleTomorrowClick },
  ];

  return (
    <>
      <div className="fixed inset-0 z-40" onClick={() => showCalendar(false)} />
      <div
        className={`absolute right-0 shadow-calendar-shadow w-[340px] px-5 py-4 overflow-auto rounded-[5px] bg-white z-50 ${
          demandShift ? "top-10" : "top-2"
        }`}
        role="dialog"
        aria-modal="true"
      >
        <div>
          <Calendar
            locale="en-UK"
            className="calendar"
            tileClassName={tileClassName}
            tileDisabled={tileDisabled}
            next2Label={null}
            prev2Label={null}
            selectRange={false}
            defaultValue={yesterday}
            showNeighboringMonth={false}
            onChange={handleDateChange}
            value={start}
          />
        </div>

        {dashboard ? null : (
          <>
            <div className="border-t-2 border-[#F7F7F7] W-[100%] my-[5px]" />
            <div className="flex justify-around items-center py-[10px]">
              {buttonsDemandShifting.map(({ label, onClick }) => (
                <CalendarButton
                  key={label}
                  label={label}
                  isActive={activeButton === label}
                  onClick={onClick}
                />
              ))}
            </div>
          </>
        )}
        <div className="border-t-2 border-[#F7F7F7] W-[100%] my-[5px]" />
      </div>
    </>
  );
};

export default DemandDateSelect;
