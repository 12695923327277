import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  ActiveSessions,
  ActiveSessionsResponse,
  MeterValue,
  SessionHistoryResponse,
  SessionsHistory,
} from "../models/session";
import {
  convertUTCDateTimeToLocale,
  formatDateLocale,
  formatTime,
} from "../../utils/time/formatDateLocale";
import { roundOnFixedDecimals } from "../../utils/numbers/numbersHelper";
import moment from "moment";
import { store } from "./store";

export default class SessionStore {
  sessionsHistory: SessionsHistory[] | undefined;
  sessionHistoriesCount: number = 0;

  activeSessions: ActiveSessions[] | undefined;
  activeSessionsCount: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  getSessionsHistory = async (
    pageIdx: number,
    pageSize: number,
    startDate: string = "",
    endDate: string = "",
    keyword: string = "",
    sortingField: string = "",
    sortingDescending: boolean = true
  ) => {
    try {
      const result: SessionHistoryResponse =
        await agent.Session.getSessionHistory(
          pageIdx,
          pageSize,
          startDate,
          endDate,
          keyword,
          sortingField,
          sortingDescending
        );
      const mappedData: SessionsHistory[] = result.data.map((el) => ({
        ...el,
        timeStart:
          formatDateLocale(convertUTCDateTimeToLocale(el.timeStart)) +
          " " +
          formatTime(convertUTCDateTimeToLocale(el.timeStart).split("T")[1], true),
        timeStop:
          formatDateLocale(convertUTCDateTimeToLocale(el.timeStop)) +
          " " +
          formatTime(convertUTCDateTimeToLocale(el.timeStop).split("T")[1], true),
        duration: formatTime(el.duration, true),
        electricityConsumed: roundOnFixedDecimals(el.electricityConsumed, 1) + " kWh",
      }));
      runInAction(() => {
        this.sessionsHistory = mappedData;
        this.sessionHistoriesCount = result.totalCount;
      });
    } catch (ex) {
      console.log("Error fetching session history: " + ex);
    }
  };

  getActiveSessions = async (
    pageIdx: number,
    pageSize: number,
    startDate: string = "",
    endDate: string = "",
    keyword: string = "",
    sortingField: string = "",
    sortingDescending: boolean = true
  ) => {
    try {
      const result: ActiveSessionsResponse =
        await agent.Session.getActiveSessions(
          pageIdx,
          pageSize,
          startDate,
          endDate,
          keyword,
          sortingField,
          sortingDescending
        );
      const mappedData: ActiveSessions[] = result.data.map((el) => ({
        ...el,
        timeStart:
          formatDateLocale(convertUTCDateTimeToLocale(el.timeStart)) +
          " " +
          formatTime(convertUTCDateTimeToLocale(el.timeStart).split("T")[1], true),
        duration: formatTime(el.duration, true),
        currentPower: roundOnFixedDecimals(el.currentPower, 1) + " kW",
        electricityConsumed:
          roundOnFixedDecimals(el.electricityConsumed, 1) + " kWh",
        carBattery: el.carBattery === -1 ? "n/a" : String(Math.round(el.carBattery)) + "%",
      }));
      runInAction(() => {
        this.activeSessions = mappedData;
        this.activeSessionsCount = result.totalCount;
      });
    } catch (ex) {
      console.log("Error fetching active sessions: " + ex);
    }
  };

  setActiveSessions = (newActiveSessions: ActiveSessions[]) => {
    runInAction(() => this.activeSessions = newActiveSessions)
  };

  durationClockTick = () => {
    let newData = this.activeSessions?.map((el) => {
      const dur = moment.duration(el.duration).add(1, "second");
      return {
        ...el,
        duration: moment.utc(dur.asMilliseconds()).format("HH:mm:ss"),
      };
    });
    runInAction(() => {
      this.activeSessions = newData;
    });
  };

  getMeterValues = async (sessionId: number) => {
    try {
      const res: MeterValue[] = await agent.Session.getMeterValues(sessionId);
      return res;
    } catch (err) {
      console.log("Error while fetching meter values: ", err);
      return undefined;
    }
  };

  connectOnSignalR = async (reloadData: () => void) => {
    await store.signalRStore.startConnection("/hubs/sessions");
    this.activeSessions?.forEach(
      async (session) =>
        await store.signalRStore.subscribeOnRemoteAction(
          "SubscribeB2B",
          session.id,
          () => console.log("Subscribed sesion id: ", session.id),
          (err: any) => console.error("Subscribe B2B error: ", err)
        )
    );
    store.signalRStore.listenRemoteAction(
      "updateSession",
      (updateSessionSignalRDto: any) => {
        const { sessionId, currentSoC, energyConsumed, currentPower } =
          updateSessionSignalRDto;
        console.log("Session update received:", updateSessionSignalRDto);
        const newSessions: ActiveSessions[] | undefined =
          this.activeSessions?.map((session) =>
            session.id === sessionId
              ? {
                ...session,
                carBattery: currentSoC === -1 ? "n/a" : `${String(Math.round(currentSoC))}%`,
                electricityConsumed: `${energyConsumed.toFixed(1)} kWh`,
                currentPower: `${currentPower.toFixed(1)} kW`,
              }
              : session
          );
        runInAction(() => this.activeSessions = newSessions);
      }
    );
    store.signalRStore.subscribeOnAddingAndRemovingSessions(
      "SubscribeActiveSessions",
      (err: any) => console.log("Error while subscribing on adding and removing session: ", err)
    );
    store.signalRStore.listenRemoteAction("newSessionAdded", async () => {
      await store.signalRStore.closeConnection();
      reloadData();
    });
    store.signalRStore.listenRemoteAction("sessionRemoved", async () => {
      await store.signalRStore.closeConnection();
      reloadData();
    });
  }
}