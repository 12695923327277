import { useEffect, useState } from "react";
import ContentHeader from "../../../../components/ContentHeader/ContentHeader";
import MyMap from "../../../../components/Map/MyMap";
import { ICreateLocation } from "../../../../app/models/location";
import Table from "../../../../components/Table/Table";
import {
  networkOverviewColumnOrder,
  networkOverviewColumns,
} from "../networkData";
import ChargerModal from "./ChargerModal";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/stores/store";
import ChargerStore from "../../../../app/stores/chargerStore";
import { ChargerDataDto } from "../../../../app/models/charger";
import Toast from "../../../../components/Toast/Toast";
import { toJS } from "mobx";

const NetworkOverview = observer(() => {
  const { chargerStore } = useStore();
  const [activeButton, setActiveButton] = useState("map");
  const [selectedCharger, setSelectedCharger] = useState<ChargerDataDto | null>(
    null
  );
  const [chargerModal, setChargerModal] = useState(false);

  const [toastVisible, setToastVisible] = useState(false);

  const closeToast = () => {
    setToastVisible(false);
  };

  useEffect(() => {
    const getData = async () => {
      await chargerStore.getMapChargers();
      await chargerStore.getChargers();
    };
    getData();
  }, [chargerStore]);

  const closeChargerModal = () => {
    setChargerModal(false);
    setSelectedCharger(null);
  };

  const handleButtonClick = (buttonType: string) => {
    setActiveButton(buttonType);
  };

  const handleSelection = (id: number) => {
    const selected: ChargerDataDto | null = toJS(chargerStore.chargers)?.find((ch) => ch.id === id) || null;
    setSelectedCharger(selected);
  };

  return (
    <div className="flex flex-col gap-4 items-stretch h-full">
      <ContentHeader group="Network" subgroup="Overview"></ContentHeader>
      <div className="flex-1 flex flex-col gap-9 bg-white rounded-t-[10px] py-5 px-5">
        <div className="flex border-b-[3px] border-[#BBBBBB]">
          <button
            className={`relative w-[160px] text-left text-[14px] font-semibold whitespace-nowrap p-3 ${activeButton === "map"
              ? "text-darkGreen border-b-[3px] border-light-green -mb-[3px] z-10"
              : "text-[#B8B8B8]"
              }`}
            onClick={() => handleButtonClick("map")}
          >
            Chargers Map
          </button>
          <button
            className={`relative w-[160px] text-left text-[14px] font-semibold whitespace-nowrap p-3 ${activeButton === "list"
              ? "text-darkGreen border-b-[3px] border-light-green -mb-[3px] z-10"
              : "text-[#B8B8B8]"
              }`}
            onClick={() => handleButtonClick("list")}
          >
            Chargers List
          </button>{" "}
        </div>
        {activeButton === "map" ? (
          <MyMap
            locations={chargerStore.mapChargers || []}
            setSelectedCharger={(selected) => {
              setSelectedCharger(selected);
              setChargerModal(true);
            }}
            addModal={false}
          />
        ) : (
          <>
            <Table
              data={chargerStore.chargers || []}
              columns={networkOverviewColumns}
              columnOrder={networkOverviewColumnOrder}
              selected={selectedCharger?.id}
              handleSelection={(id: number) => {
                handleSelection(id);
                setChargerModal(true);
              }}
            />
          </>
        )}
        {chargerModal &&
          <ChargerModal
            selectedCharger={
              selectedCharger !== null ? selectedCharger : ({} as ChargerDataDto)
            }
            open={chargerModal}
            setOpen={closeChargerModal}
            setOpenToast={() => setToastVisible(true)}
          />}

      </div>
      <Toast
        text="Charger information updated successfully"
        visible={toastVisible}
        onClose={closeToast}
        positive={true}
      />
    </div>
  );
});

export default NetworkOverview;
