import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import LoginLayout from "../../app/layout/LoginLayout";
import ConfirmButton from "../../components/ConfirmButton/ConfirmButton";
import Popup from "../../components/Popup/Popup";
import MySpinner from "../../components/Spinner/MySpinner";
import { store, useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";

const Login = observer(() => {
  const { userStore } = useStore();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const loginRenderPopup = () => {
    return (
      <div className="flex flex-col justify-center items-center gap-5">
        <img
          src="/assets/svg/incorrectX/incorrect.svg"
          alt=""
          className="w-[81px]"
        />
        <div className="flex flex-col gap-2">
          <div className="text-darkGreen font-semibold text-[24px]">
            Wrong password or e-mail
          </div>
          <div className="text-lightGray font-normal text-[16px]">
            Please try again
          </div>
        </div>
      </div>
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setLoginFailed(false);

    try {
      await userStore.login({
        email: email,
        password: password,
        rememberMe: rememberMe,
      });
      if (store.commonStore.isLoggedIn()) navigate("/dashboard/overview"); // Redirect after successful login
    } catch (error) {
      console.error("Login failed", error);
      setLoginFailed(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginLayout>
      <div className="flex flex-col gap-8 items-center self-center w-[65%] mx-auto">
        <p className="text-[32px] text-darkGreen font-bold">Log in</p>
        <form className="flex flex-col gap-6 w-full" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-1">
            <label
              htmlFor="email"
              className="text-[20px] font-normal text-darkGreen"
            >
              E-mail
            </label>
            <input
              name="email"
              required
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="text-gray-700 text-left font-normal text-lg outline-none py-2 px-2 border-b-2 border-input-border focus:border-input-focus w-full"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor="password"
              className="text-[20px] font-normal text-darkGreen"
            >
              Password
            </label>
            <div className="relative">
              <input
                name="password"
                required
                id="password"
                type={visiblePassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="text-gray-700 text-left font-normal text-lg outline-none py-2 px-2 border-b-2 border-input-border focus:border-input-focus w-full"
              />
              <img
                src={
                  visiblePassword
                    ? "/assets/svg/eye/eye-open.svg"
                    : "/assets/svg/eye/eye-closed.svg"
                }
                alt=""
                className="absolute cursor-pointer top-[15px] right-[10px]"
                onClick={() => setVisiblePassword(!visiblePassword)}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <label className="flex justify-center items-center gap-[10px] text-[16px] text-darkGreen font-medium">
              <input
                type="checkbox"
                name="rememberMe"
                className="w-[18px] h-[18px] cursor-pointer focus:outline-none relative appearance-none border border-darkGreen rounded-[5px] checked:bg-light-green checked:border-none"
                onChange={() => setRememberMe(!rememberMe)}
              />
              Remember me
            </label>
            <p className="text-[16px] text-darkGreen font-medium">
              Forgot Password?{" "}
              <a className="text-light-green" href="/auth/forgotPassword">
                Click here
              </a>
            </p>
          </div>
          <div className="flex w-[394px] items-center justify-center mx-auto mt-[24px] justify-center">
            <ConfirmButton
              weight="semibold"
              textSize="16"
              isActive={true}
              content="Continue"
            />
          </div>
        </form>
        {loginFailed && (
          <Popup
            mainText={loginRenderPopup()}
            closeText="Close"
            confirmFunction={() => undefined}
            closeFunction={() => setLoginFailed(false)}
          />
        )}
        {loading && <MySpinner />}
      </div>
    </LoginLayout>
  );
});

export default Login;
