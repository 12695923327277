import { useEffect, useState } from "react";
import ContentHeader from "../../../../../components/ContentHeader/ContentHeader";
import Table from "../../../../../components/Table/Table";
import {
  networkOnboardingColumnOrder,
  networkOnboardingColumns,
} from "../../networkData";
import ButtonGroup from "./ButtonGroup";
import { useStore } from "../../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import AddLocationModal from "./AddLocationModal";
import Popup from "../../../../../components/Popup/Popup";
import { useNavigate } from "react-router-dom";
import { ChargerInstructions, CreateChargerDto } from "../../../../../app/models/charger";

const NetworkOnboarding = observer(() => {
  const { chargerStore } = useStore();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<number | null>(null);
  const [addLocation, setAddLocation] = useState(false);
  const [activeButton, setActiveButton] = useState("pending");
  const [confirmCharger, setConfirmCharger] = useState(false);
  const [chargerInstructions, setChargerInstructions] = useState<ChargerInstructions | undefined>(undefined);

  useEffect(() => {
    chargerStore.getChargersByOnboardingStatus(activeButton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeButton, chargerStore]);

  const handleButtonClick = (buttonType: string) => {
    setActiveButton(buttonType);
  };

  const handleSelection = (id: number) => {
    setSelected(id);
  };

  const handleFinishOnboarding = (id: number | null) => {
    selected && navigate(`/dashboard/network/onboarding/finishOnboarding/${id}`);
  };

  const mainText = () => {
    return (
      <div className="bg-white rounded-[5px] flex flex-col items-center justify-center px-2">
        <p className="text-[14px] font-medium text-darkGreen mb-4">
          Onboard physical charger to Power Up backend using below instructions
          and parameters
        </p>
        <div className="flex flex-col gap-2 whitespace-nowrap w-full">
          <div className="flex items-center justify-between border-b-[1.5px] border-input-border py-2">
            <p className="text-[12px] text-darkGreem">OCPP proxy url</p>
            <p className="text-[12px] text-lightGray">
              {chargerInstructions?.ocppProxyUrl}
            </p>
          </div>
          <div className="flex items-center justify-between border-b-[1.5px] border-input-border py-2">
            <p className="text-[12px] text-darkGreem">WSS/WS</p>
            <p className="text-[12px] text-lightGray">{chargerInstructions?.encryption}</p>
          </div>
          <div className="flex items-center justify-between border-b-[1.5px] border-input-border py-2">
            <p className="text-[12px] text-darkGreem">Port</p>
            <p className="text-[12px] text-lightGray">{chargerInstructions?.port}</p>
          </div>
          <div className="flex items-center justify-between border-b-[1.5px] border-input-border py-2">
            <p className="text-[12px] text-darkGreem">Available protocols</p>
            <p className="text-[12px] text-lightGray">{chargerInstructions?.availableProtocols}</p>
          </div>
          <div className="flex items-center justify-between border-b-[1.5px] border-input-border py-2">
            <p className="text-[12px] text-darkGreem">Password</p>
            <p className="text-[12px] text-lightGray">{chargerInstructions?.password}</p>
          </div>
        </div>
      </div>
    );
  };

  const handleConfirmChargerCreate = async (newCharger: CreateChargerDto) => {
    try {
      const res = await chargerStore.createCharger(newCharger);
      if (res) {
        setChargerInstructions(await chargerStore.getChargerInstructions(newCharger.chargerIdentificator));
        setConfirmCharger(true);
        await chargerStore.getChargersByOnboardingStatus("pending");
        await chargerStore.getChargersOnboardingStatusCount();
      }
    } catch (error) {
      console.error("Error creating new charger", error);
    }
  };

  return (
    <div className="flex flex-col gap-4 items-stretch h-full">
      <ContentHeader group="Network" subgroup="Chargers Onboarding">
        <div className="flex justify-end gap-2">
          {activeButton === "connected" && (
            <button
              disabled={!selected}
              onClick={() => handleFinishOnboarding(selected)}
              className={`w-[185px] h-[40px] text-[14px] font-medium border-2 rounded-10 ${selected
                ? "border-light-green bg-light-green text-white"
                : "text-lightGray border-lightGray"
                }`}
            >
              Finish Onboarding
            </button>
          )}
          <button
            onClick={() => setAddLocation(true)}
            className="flex items-center w-[185px] h-[40px] bg-light-green py-2 px-3 rounded-10 gap-3 text-[14px] font-medium text-white"
          >
            <img src="/assets/svg/plus/plus.svg" alt="" />
            Add new charger
          </button>
        </div>
      </ContentHeader>

      <div className="flex-1 flex flex-col gap-9 bg-white rounded-t-[10px] py-5 px-5">
        <ButtonGroup
          activeButton={activeButton}
          handleButtonClick={handleButtonClick}
        />

        {activeButton === "pending" ? (
          <Table
            data={chargerStore.chargersByOnboardingStatus || []}
            columns={networkOnboardingColumns}
            columnOrder={networkOnboardingColumnOrder}
          />
        ) : (
          <Table
            data={chargerStore.chargersByOnboardingStatus || []}
            columns={networkOnboardingColumns}
            columnOrder={networkOnboardingColumnOrder}
            selected={selected!}
            handleSelection={(id: number) => handleSelection(id)}
          />
        )}
      </div>

      <AddLocationModal
        modalOpen={addLocation}
        setModalOpen={setAddLocation}
        setConfirm={handleConfirmChargerCreate}
      />
      {confirmCharger && (
        <Popup
          mainText={mainText()}
          confirmText="Ok"
          confirmFunction={() => setConfirmCharger(false)}
          closeFunction={() => setConfirmCharger(false)}
        />
      )}
    </div>
  );
});

export default NetworkOnboarding;
