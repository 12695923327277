import { useState, useCallback, useEffect } from "react";
import ContentHeader from "../../../components/ContentHeader/ContentHeader";
import Table from "../../../components/Table/Table";
import {
  sessionActiveColumnOrder,
  sessionActiveColumns,
  dummyDataSession,
} from "./sessionData";
import Paging from "../../../components/Paging/Paging";
import Popup from "../../../components/Popup/Popup";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import Toast from "../../../components/Toast/Toast";
import MySpinner from "../../../components/Spinner/MySpinner";
import { ActiveSessions } from "../../../app/models/session";

const SessionActive = observer(() => {
  const { sessionStore, demandShiftingStore, signalRStore } = useStore();
  const [selected, setSelected] = useState<number | null>(null);
  const [keyword, setKeyword] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 1, perPage: 10 });
  const [popupVisible, setPopupVisible] = useState(false);
  const [stopChargingPopup, setStopChargingPopup] = useState(false);
  const [pendingToggleRow, setPendingToggleRow] = useState<{
    id: number;
    newState: boolean;
  } | null>(null);
  const [toggleStates, setToggleStates] = useState<boolean[]>(
    dummyDataSession.map((row) => row.demandShifting)
  );
  var clockTicking: NodeJS.Timer | undefined = undefined;

  const [toastVisible, setToastVisible] = useState(false);
  const [positiveToast, setPositiveToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reloadSwitch, setReloadSwitch] = useState(false);

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending";
  } | null>({ key: "timeStart", direction: "descending" });

  const fetchSessions = async () => {
    await sessionStore.getActiveSessions(
      pagination.page,
      pagination.perPage,
      "",
      "",
      keyword,
      sortConfig?.key || "",
      sortConfig?.direction === "descending"
    );
  };

  const handleSignalRConnection = async () => {
    await sessionStore.connectOnSignalR(() => setReloadSwitch(prev => !prev));
  };

  useEffect(() => {
    fetchSessions();
    startClock();
    handleSignalRConnection();
    return (() => {
      clearInterval(clockTicking);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, keyword, sortConfig, reloadSwitch]);

  const startClock = () => {
    clockTicking = setInterval(() => {
      sessionStore.durationClockTick();
    }, 1000);
  };

  const handleSelection = (sessionId: number) => {
    setSelected(selected === sessionId ? null : sessionId);
  };

  const handlePageChange = useCallback(
    (newPage: number, newPerPage: number) => {
      setPagination({ page: newPage, perPage: newPerPage });
    },
    []
  );

  const handleToggleAttempt = (id: number, newState: boolean) => {
    setPendingToggleRow({
      id: id,
      newState: newState,
    });
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPendingToggleRow(null);
    setPopupVisible(false);
    setStopChargingPopup(false);
  };

  const confirmToggleChange = async () => {
    if (pendingToggleRow) {
      await demandShiftingStore.changeDemandShiftingState(
        pendingToggleRow.id,
        pendingToggleRow.newState
      );
      fetchSessions();
      setPendingToggleRow(null);
    }
    setPopupVisible(false);
  };

  const handleConfirmStopCharging = async () => {
    setStopChargingPopup(false);
    setLoading(true);
    !signalRStore.connection &&
      (await signalRStore.startConnection("/hubs/sessions"));
    await signalRStore.subscribeOnRemoteAction(
      "SubscribeToStopSession",
      selected!,
      () => console.log("Subscribing to stop sessionId " + selected),
      (err: any) => console.error("Subscription error:", err)
    );
    signalRStore.listenRemoteAction("stopSession", async () => {
      console.log("Session stopped");
      setLoading(false);
      setPositiveToast(true);
      setToastVisible(true);
      await signalRStore.closeConnection();
    });
  };

  const onEnteringKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setKeyword(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  const handleSort = (
    config: { key: string; direction: "ascending" | "descending" } | null
  ) => {
    setSortConfig(config);
  };

  const mainText = () => {
    if (pendingToggleRow) {
      return (
        <p className="text-[24px] font-semibold text-darkGreen">
          Do you want to switch demand shifting offer{" "}
          <span
            className={
              !pendingToggleRow.newState ? "text-[#DA3333]" : "text-light-green"
            }
          >
            {!pendingToggleRow.newState ? "OFF" : "ON"}{" "}
          </span>
          for charging session ID: {pendingToggleRow.id}
        </p>
      );
    }
    return null;
  };

  const stopChargingText = () => {
    return (
      <div className="flex flex-col gap-2">
        <div className="text-darkGreen font-semibold text-[24px]">
          This action will forcefully stop the charging session on this
          connector ID:{" "}
          {
            sessionStore.activeSessions?.find((el) => el.id === selected)
              ?.connectorId
          }
        </div>
        <div className="text-lightGray text-[16px]">
          Do you want to stop the session?
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4 h-full">
      <ContentHeader group="Charging Sessions" subgroup="Active">
        <div className="flex gap-5">
          <div className="flex h-10 gap-2 border-2 border-input-border py-2 px-2 rounded-10">
            <img src="/assets/svg/search/search.svg" alt="" />
            <input
              className="text-[14px] focus:outline-none"
              type="text"
              placeholder="Search"
              onChange={onEnteringKeyword}
            />
          </div>
          <button
            onClick={() => setStopChargingPopup(true)}
            disabled={!selected}
            className={`w-[185px] h-[40px] text-[14px] font-medium rounded-10 ${selected !== null
              ? "bg-light-green text-white"
              : "bg-[#F7F7F7] text-[#515151]"
              }`}
          >
            Stop charging
          </button>
        </div>
      </ContentHeader>
      <div className="flex flex-col flex-grow">
        <div className="flex-grow overflow-y-auto bg-white rounded-t-[10px] pt-8 pb-5 px-6">
          <Table
            data={sessionStore.activeSessions || []}
            // data={dummyDataSession}
            columns={sessionActiveColumns}
            columnOrder={sessionActiveColumnOrder}
            selected={selected!}
            handleSelection={handleSelection}
            handleToggleAttempt={handleToggleAttempt}
            toggleStates={toggleStates}
            setSortConfig={handleSort}
            sortConfig={sortConfig}
          />
        </div>

        <Paging
          tableSize={sessionStore.activeSessionsCount}
          pageChangeFunc={handlePageChange}
        />
      </div>
      {popupVisible && (
        <Popup
          mainText={mainText()}
          confirmText="Yes"
          closeText="No"
          confirmFunction={confirmToggleChange}
          closeFunction={closePopup}
          demandShifting={pendingToggleRow?.newState || undefined}
        />
      )}
      {stopChargingPopup && (
        <Popup
          mainText={stopChargingText()}
          confirmText="Yes"
          closeText="No"
          confirmFunction={handleConfirmStopCharging}
          closeFunction={closePopup}
        />
      )}
      <Toast
        text={
          positiveToast
            ? "Charging session successfully stopped"
            : "We didnt manage to stop charging session. Please try again"
        }
        visible={toastVisible}
        onClose={() => setToastVisible(false)}
        positive={positiveToast}
      />
      {loading && <MySpinner />}
    </div>
  );
});

export default SessionActive;
