import { useEffect, useState } from "react";
import TextInput from "../../../../../components/TextInput/TextInput";
import MySelect from "../../../../../components/MySelect/MySelect";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../app/stores/store";
import { OnboardingConnector } from "../../../../../app/models/connector";
import { useNavigate, useParams } from "react-router-dom";
import ContentHeader from "../../../../../components/ContentHeader/ContentHeader";

const FinishOnboarding = observer(() => {
  const { chargerId } = useParams();
  const navigate = useNavigate();
  const { connectorTypeStore, connectorStore, chargerStore } = useStore();
  const [unchangedConnectors, setUnchangedConnectors] = useState<OnboardingConnector[]>([]);
  const [connectors, setConnectors] = useState<OnboardingConnector[]>([]);
  const [confirmApproved, setConfirmApproved] = useState(false);

  useEffect(() => {
    connectorTypeStore.getConnectorTypesForDropdown();
  }, [connectorTypeStore]);

  useEffect(() => {
    const getConnectors = async () => {
      const res = await connectorStore.getOnboardingConnectorByChargerId(Number(chargerId));
      setUnchangedConnectors(res);
      setConnectors(res);
    }
    chargerId && getConnectors();
  }, [chargerId, connectorStore])

  useEffect(() => {
    let canConfirm = true;
    for (let connector of connectors) {
      if (!(connector.connectorId && connector.connectorTypeId && connector.connectorName)) {
        canConfirm = false;
        break;
      }
    }
    canConfirm ? setConfirmApproved(true) : setConfirmApproved(false);
  }, [connectors])

  const handleConfirm = async () => {
    // console.log(connectors)
    for (let connector of connectors) {
      await connectorStore.updateConnectorOnboarding(connector);
    }
    await chargerStore.finishOnboardingCharger(Number(chargerId));
    navigate("/dashboard/network/onboarding");
  }

  return (
    <div className="flex flex-col gap-4 items-stretch h-full">
      <ContentHeader group="Network" subgroup="Chargers Onboarding">
        <div className="flex justify-end gap-2">
          <button
            onClick={handleConfirm}
            disabled={!confirmApproved}
            className={`w-[185px] h-[40px] text-[14px] font-medium border-2 rounded-10 ${confirmApproved
              ? "border-light-green bg-light-green text-white"
              : "text-lightGray border-lightGray"
              }`}
          >
            Confirm
          </button>
        </div>
      </ContentHeader>
      <div className="flex-1 flex flex-col md:flex-row gap-5 pb-4">
        {connectors.map((connector, index) => (
          <div className="flex flex-1 flex-col gap-9 items-center justify-center bg-white rounded-10 p-5" key={connector.connectorId}>
            <div className="flex flex-col max-w-[260px] lg:gap-8 xl:gap-10">
              <div className="flex items-center justify-center rounded-10 md:h-[52px] lg:h-12 text-[14px] font-semibold text-[#E6F0FE] bg-[#004C41]">
                Connector #{index + 1}
              </div>
              <div className="flex flex-col gap-7">
                <div className="flex flex-col gap-2.5">
                  <div className="flex items-center justify-start px-3 rounded-10 md:h-[52px] lg:h-12 text-[14px] font-semibold text-[#004C41] bg-[#F2F5F5]">
                    Connector ID
                  </div>
                  <TextInput
                    placeholder="Connector ID"
                    value={connector.connectorName}
                    name="conenctorId"
                    readonly={true}
                  />
                </div>
                <div className="flex flex-col gap-2.5">
                  <div className="flex items-center justify-start px-3 rounded-10 md:h-[52px] lg:h-12 text-[14px] font-semibold text-[#004C41] bg-[#F2F5F5]">
                    Connector Type
                  </div>
                  <MySelect
                    value={connector.connectorType}
                    placeholder="Select.."
                    readonly={Boolean(unchangedConnectors[index].connectorTypeId)}
                    data={connectorTypeStore.connectorTypes?.map(el => ({ value: el.id, label: el.name })) || []}
                    handleSelect={(selectedOption: { value: string; label: string } | null) => {
                      let arr = [...connectors];
                      arr[index] = { ...connector, connectorTypeId: Number(selectedOption?.value), connectorType: selectedOption?.label || "" };
                      setConnectors(arr);
                    }}
                  />
                </div>
                {/* <div className="flex flex-col gap-2.5">
                  <div className="flex items-center justify-start px-3 rounded-10 md:h-[52px] lg:h-12 text-[14px] font-semibold text-[#004C41] bg-[#F2F5F5]">
                    Max Power (kW)
                  </div>
                  <input
                    className={`rounded-[10px] appearance-none bg-white text-[14px] font-medium focus:outline-none border-[2px] border-solid border-[#E6E6E6]  py-[14px] px-[10px] ${!Boolean(unchangedConnectors[index].maxPower)
                      ? "focus:border-light-green bg-white"
                      : "bg-[#f0f0f0] text-[#515151]"
                      }`}
                    value={connector.maxPower}
                    readOnly={Boolean(unchangedConnectors[index].maxPower)}
                    onChange={e => {
                      let arr = [...connectors];
                      arr[index] = { ...connector, maxPower: Number(e.target.value) };
                      setConnectors(arr);
                    }}
                    type="number"
                    min={0.0}
                    step={0.1}
                  />
                </div> */}
              </div>
            </div>
          </div>
        ))}

        {[1, 2, 3].map(num => (
          connectors.length < num && (
            <div className="flex flex-1 flex-col gap-9 items-center justify-center bg-white rounded-10 p-5">
            </div>
          )))}
      </div>
    </div>
  );
});

export default FinishOnboarding;
