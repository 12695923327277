import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { IMapChargers } from "../../app/models/charger";

const FitBounds = ({
  locations,
}: {
  locations: IMapChargers[] | undefined;
}) => {
  const map = useMap();

  useEffect(() => {
    if (locations && locations.length > 0) {
      const bounds = L.latLngBounds(
        locations.map((loc) => [loc.latitude, loc.longitude])
      );
      map.fitBounds(bounds, { padding: [0, 10], maxZoom: 12 });
    }
  }, [locations, map]);

  return null;
};

export default FitBounds;
